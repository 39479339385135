import CustomButton from "@components/common/customButton"
import Input from "@components/common/form/input"
import React, { FunctionComponent } from "react"

interface SendFriendProps {
    dropDownBT?: (value: boolean) => void
}

const SendFriend: FunctionComponent<SendFriendProps> = () => {
    return (
        <div className={"d-flex flex-column gap-3"}>
            <p>Here you can enter your car entry code if you want your friend to take your car.</p>
            <Input
                placeHolder={"Enter code here"}
                onChange={()=>{}}
            />
            <Input
                placeHolder={"Enter your friend email"}
                onChange={()=>{}}
            />
            <div className={"w-100 d-flex justify-content-center gap-2 mt-2"}>
                <CustomButton
                    text={"Pay/ 18 $"}
                    theme="primary"
                />
                <CustomButton
                    text={"Cancel"}
                />
            </div>
        </div>
    )
}

export default SendFriend