import {httpDelete, httpGet, httpPost} from "@webservices/index";
import {API_URL} from "../../../config";
import {Message} from "@webservices/Message";

export interface FavoriteParking {
    id:string
    parkingId: string
    addTimeStamp: string
    parkingName?:string
}

export const getFavoritesParking = async (userId?:string):Promise<FavoriteParking[] | undefined> => {
    const favorites = await httpGet<Message<FavoriteParking[]>>(`${API_URL}favoriteParkings/${userId || localStorage.getItem("sub")}`);
    if(favorites) return favorites.data;
}

export const getFavoritesParkingWithDetails = async (userId?:string):Promise<FavoriteParking[] | undefined> => {
    const favorites = await httpGet<Message<FavoriteParking[]>>(
        `${API_URL}favoriteParkings/withDetails/${userId || localStorage.getItem("sub")}`
    );
    if(favorites) return favorites.data;
}

export const addFavoritesParking = async (parkingId:string):Promise<FavoriteParking | undefined> => {
    const favorite = await httpPost<{parkingID:string}, Message<FavoriteParking>>(
        `${API_URL}favoriteParkings/${localStorage.getItem("sub")}`,
    {parkingID:parkingId}
    );
    if(favorite) return favorite.data;
}

export const deleteFavoritesParking = async (id:string):Promise<void> => {
    await httpDelete(`${API_URL}favoriteParkings/${localStorage.getItem("sub")}/${id}`);
}