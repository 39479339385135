import {httpGet, httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import { API_URL } from "config";

export interface Promotion {
    discountPercent: number
    distanceToParking: number
    promoDetails: string
    isActive: boolean
    picture: string
    companyRefID: string
    companyContractNumber: number
    discountCode: string
    websiteURL: string
    promoTitle: string
    locationCoordinate: {
        latitude: number
        longitude: number
    },
    location: string
    endPromotionDateTime: string
    addTimeStamp: string
    CompanyName: string
}

export const getNearByPromotions = async (parkingId:string):Promise<Promotion[]> => {
    const promotions = await httpPost<{parkingId:string, distanceH:number, distanceV:number}, Message<Promotion[]>>(
        `${API_URL}promotionLocator`,
        {
            parkingId,
            distanceH: 500,
            distanceV: 500
        }
    );
    return promotions?.data || [];
}
export const getPromotionById = async (id:string):Promise<Promotion | undefined> => {
    const promotion = await httpGet<Message<Promotion>>(
        `${API_URL}promotions/${id}`
    );
    return promotion?.data;
}