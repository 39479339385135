import React, {FunctionComponent, useState} from 'react';
import styles from "./scc.module.scss";
import Input from "@components/common/form/input";
import Divider from "@components/common/divider";

interface SelectCountryCodeProps {
    onSelect:(key:string)=>void
    list:{[key:string]:any}[]
    index:string
    value:string

}

const SelectSheet:FunctionComponent<SelectCountryCodeProps> = (props) => {

    const [list, setList] = useState<{[key:string]:any}[]>(props.list);

    const search = (value?:string) => {
        if(!value) {
            setList(props.list);
            return
        }
        const newList = props.list.filter(row=>row[props.value]?.toLowerCase().includes(value.toLowerCase()));
        setList(newList);
    }

    return (
        <>
            <Input
                placeHolder={"find"}
                onChange={(id, value)=>{search(value)}}
            />
            <Divider/>
            <div className={styles.wrapper}>
                {
                    list.map((c, index)=>{
                        return <div
                            className={styles.row}
                            key={index}
                            onClick={()=>{
                                props.onSelect(c[props.index]);
                            }}
                        >
                            <p className={"text-capitalize"}>{c[props.value]}</p>
                        </div>
                    })
                }
            </div>
        </>
    );
};

export default SelectSheet;