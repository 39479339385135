import React, {FunctionComponent, useEffect, useState} from 'react';
import IconHeader from "@components/common/iconHeader";
import {Add, Car} from "@components/common/svgIcons";
import Radio from "@components/common/form/radio";
import {getVehicles, Vehicle} from "@webservices/account/vehicle";
import BottomSheet from "@components/common/bottomSheet";
import AddVehicle from "@components/bottomSheets/addVehicle";
import RadioLoading from "@components/app/loadings/radioLoading";
import Icon from "@components/common/icon";
import CustomButton from "@components/common/customButton";

interface VehiclesProps {
    id:string
    dropDownBT?:(value:boolean)=>void
    onChange:(id?:string, value?:any, plate?:string)=>void
    selected?:string
}

const Vehicles:FunctionComponent<VehiclesProps> = (props) => {

    const [showAddVehicleBT, setShowAddVehicleBT] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);

    useEffect(()=>{
        loadVehicles().catch();
    }, [])

    const loadVehicles = async () => {
        setLoading(true);
        const result = await getVehicles()
        if(!result || !result[0]) {
            setLoading(false);
            return;
        }
        let plate;
        if(props.selected){
            plate = result.find(r=>r.id === props.selected)?.plateNumber
        } else {
            plate = result[0].plateNumber
        }
        props.onChange("vehicleId", props.selected || result[0].id, plate)
        setVehicles(result);
        setLoading(false);
    }

    const handleChange = (id?:string, value?:any) => {
        if(!value) return;
        const plate = vehicles.find(r=>r.id === value)?.plateNumber;
        props.onChange(props.id, value, plate);
    }

    return (
        <>
            <div className={"d-flex align-items-center justify-content-between"}>
                <IconHeader
                    icon={<Car/>}
                    text={"vehicles"}
                />
                <CustomButton
                    theme={"no-border"}
                    icon={<Icon color={"primary"} size={20} icon={<Add/>}/>}
                    onClick={()=>{
                        setShowAddVehicleBT(true);
                        props.dropDownBT && props.dropDownBT(true);
                    }}
                    className={"p-0"}
                />
            </div>
            {
                loading ? <div className={"mt-2"}>
                    <RadioLoading/>
                </div> : <>
                    {
                        vehicles[0] && <Radio
                            id={props.id}
                            options={vehicles.map(v=>{
                                return {
                                    title: <div className={"w-100 d-flex justify-content-between"}>
                                        <p>{v.vehicleName}</p>
                                        <span className={"opacity-75"}>Plate number: {v.plateNumber}</span>
                                    </div>,
                                    value:v.id
                                }
                            })}
                            onChange={handleChange}
                            default={props.selected || vehicles[0].id}
                            align={"column"}
                        />
                    }
                </>
            }
            {
                showAddVehicleBT && <BottomSheet
                    onClose={()=>{
                        setShowAddVehicleBT(false);
                        props.dropDownBT && props.dropDownBT(false);
                    }}
                    fullWidth
                >
                    <AddVehicle
                        onClose={()=>{
                            loadVehicles().catch();
                            setShowAddVehicleBT(false);
                            props.dropDownBT && props.dropDownBT(false);
                        }}
                    />
                </BottomSheet>
            }
        </>
    );
};

export default Vehicles;