import React, {useEffect} from 'react';
import Routes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/globals.scss';
import LanguageProvider from "@components/layouts/languageProvider";
import {initiateFirebase} from "./services/firebaseLoader";
import {RecaptchaVerifier} from "firebase/auth";
import {useHistory} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {ApiToken} from "@types.d/ApiToken";
import GlobalProvider from "./providers/globalProvider";

declare global {
    interface Window {
        recaptchaVerifier: RecaptchaVerifier
        cancelTokens:ApiToken[]
    }
}
window.recaptchaVerifier = window.recaptchaVerifier || {};
window.cancelTokens = window.cancelTokens || [];

const App = () => {

    const history = useHistory();

    initiateFirebase();

    useEffect(()=>{
        let theme = localStorage.getItem('theme');
        if(!theme) theme = 'light-theme';
        const root = document.getElementById('root');
        if(!root) return;
        root.className = theme;
        if(localStorage.getItem('onBoard') !== "1"){
            history.push("/language")
        }
    }, [])

    return (
        <GlobalProvider>
            <LanguageProvider>
                <div id={"main"}>
                    <ToastContainer style={{padding:"0.5rem"}}/>
                    <div id={"selectSheet"} className={"selectSheet bottomSheets"}></div>
                    <div id={"bottomSheets"} className={"bottomSheets"}></div>
                    <div id={"portals"} className={"portals"}/>
                    <Routes />
                </div>
            </LanguageProvider>
        </GlobalProvider>
    );
};

export default App;