import React, {useEffect, useState} from 'react';
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import BottomSheet from "@components/common/bottomSheet";
import styles from "./managePayment.module.scss";
import Icon from "@components/common/icon";
import {Add, Card, Trash} from "@components/common/svgIcons";
import AddPayment from "@components/bottomSheets/addPayment";
import {deletePayment, getPayments, Payment} from "@webservices/account/payment";
import moment from "moment";
import apple from "@assets/icons/apple.svg";
import google from "@assets/icons/google.svg";
import ManagePaymentOnLoad from "@pages/account/managePayments/managePaymentOnLoad";
import {successToast} from "@toast/index";
import Text from "@components/common/text";
import ConfirmModal from "@components/modals/confirmModal";
import CustomButton from "@components/common/customButton";

const ManagePayments = () => {

    const [showAddEditBT, setShowAddEditBt] = useState<boolean>(false);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [removeLoading, setRemoveLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [selected, setSelected] = useState<number>();

    useEffect(()=>{
        loadPayments().catch();
    }, [])

    const loadPayments = async () => {
        setLoading(true);
        const allPayments = await getPayments();
        if(allPayments) setPayments(allPayments);
        setLoading(false);
    }

    const oneSelect = (index:number) => {
        setShowAddEditBt(true);
        setSelected(index);
    }

    const onDeletePayment = async () => {
        if(selected === undefined) return;
        setRemoveLoading(true);
        await deletePayment(payments[selected].id);
        successToast(<Text value={"the card has been deleted successfully."}/>);
        loadPayments().catch();
        setRemoveLoading(false);
    }

    return (
        <SimpleLayout
            header={<SimpleHeader
                title={"payment method"}
                backControl
            />}
        >
            <div className={"d-flex flex-column gap-3"}>
                {
                    loading ? <ManagePaymentOnLoad/> :
                        payments[0] && payments.map((p, index)=>{
                            return <div
                                className={styles.paymentRow}
                                key={index}
                                onClick={()=>oneSelect(index)}
                            >
                                <div className={"d-flex justify-content-start gap-3 align-items-center"}>
                                    <Icon size={24} color={"primary"} icon={<Card/>}/>
                                    <div>
                                        <h2>{p.cardNumber}</h2>
                                        <p><Text value={"exp"}/>:{moment(p.expireDate).format("MM/YY")}</p>
                                    </div>
                                </div>
                                <CustomButton
                                    theme={"no-border"}
                                    icon={<Icon size={20} color={"red"} icon={<Trash/>}/>}
                                    onClick={()=>{
                                        setShowRemoveConfirm(true);
                                        setSelected(index);
                                    }}
                                    className={"p-0"}
                                />
                            </div>
                        })
                }
                <div
                    className={`${styles.paymentRow} justify-content-start gap-3`}
                    onClick={()=>{}}
                >
                    <img className={styles.avatar} src={apple} alt={"apple-logo"}/>
                    <div>
                        <h2>apple pay</h2>
                    </div>
                </div>
                <div
                    className={`${styles.paymentRow} justify-content-start gap-3`}
                    onClick={()=>{}}
                >
                    <img className={styles.avatar} src={google} alt={"google-logo"}/>
                    <div>
                        <h2>google pay</h2>
                    </div>
                </div>
            </div>
            <div
                className={styles.addBtn}
                onClick={()=>{
                    setSelected(undefined);
                    setShowAddEditBt(true);
                }}
            >
                <Icon size={25} color={"white"} icon={<Add/>}/>
            </div>
            {
                showAddEditBT && <BottomSheet
                    onClose={()=>setShowAddEditBt(false)}
                    fullWidth
                >
                    <AddPayment
                        onClose={()=>{
                            setShowAddEditBt(false);
                            loadPayments().catch();
                            setSelected(undefined);

                        }}
                        payment={selected !== undefined ? payments[selected] : undefined}
                    />
                </BottomSheet>
            }
            {
                showRemoveConfirm && <BottomSheet onClose={()=>{
                    setShowRemoveConfirm(false);
                    setSelected(undefined);
                }}>
                    <ConfirmModal
                        title={"do you want to remove your card?"}
                        onConfirm={async () => {
                            await onDeletePayment();
                            setShowRemoveConfirm(false);
                        }}
                        onCancel={()=>{
                            setShowRemoveConfirm(false);
                            setSelected(undefined);
                        }}
                        inProgress={removeLoading}
                    />
                </BottomSheet>
            }
        </SimpleLayout>
    );
};

export default ManagePayments;