import {httpGet} from "@webservices/index";
import {API_URL} from "../../../config";
import {Message} from "@webservices/Message";

export interface Floor {
    floorPlanPic: string[]
    floorTotalSpace: number
    id: string
    floorName: string
    addTimeStamp: {
        _seconds: number
        _nanoseconds: number
    }
    mainZones:{
        mainZoneName:string
        parkZones:{
            parkZoneName: string
            parkZoneId: string
            parkZoneSketchType: number
            parkZoneFeatures: {
                disabled: boolean
                ePark: boolean
            }
            parkZoneParameters: {
                freeSpace: number
                alpha: number
                paddingRight: number
                shift: number
                isInitialized: boolean
                paddingLeft: number
                N_Parking: number
            }
            parkZoneSpaces: number
            parkZoneCoordinates: {
                lng: number
                lat: number
            }[]
            parkSpot:{
                justUId:string
                isFree:boolean
                parkSpotCoordinates:{
                    lng: number
                    lat: number
                }[]
                parkSpotDimension:number
                type:string[]
            }[]
        }[]
        mainZoneCoordinates:{
            east: -111.28177308802348,
            south: 39.025602918753854,
            north: 38.763738983896886,
            west: -112.33234071497661
        }
    }[]
}

export const getFloors = async (parkingId:string):Promise<Floor[]> => {
    const floors = await httpGet<Message<Floor[]>>(`${API_URL}floors/${parkingId}`);
    return floors?.data || [];
}