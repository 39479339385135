import React from 'react';
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import TabBar from "@components/common/tabBar";
import Invite from "@pages/account/personalInfo/InviteFriends/invite";
import History from "@pages/account/personalInfo/InviteFriends/history";

const InviteFriends = () => {
    return (
        <SimpleLayout
            header={<SimpleHeader
                title={"invite friends"}
                backControl
            />}
        >
            <TabBar
                contentsNoPadding
                tabs={[
                    {
                        title:"invite",
                        content:<Invite/>
                    },
                    {
                        title:"history",
                        content:<History/>
                    },
                ]}
            />
        </SimpleLayout>
    );
};

export default InviteFriends;