export const parseQueryString = (value:string):{[key:string]:string} => {
    let query = {};
    value.substring(1).split('&').map(s=>{
        const a = s.split('=');
        query = {...query, [a[0]]:a[1]}
    });
    return query
}

export const convertToQueryString = (object:{[key:string]:string}, lowerKey=true):string => {
    const query:string[] = Object.keys(object).map(key => {
        return `${lowerKey ? key.toLowerCase() : key}=${String(object[key])}`
    });
    return `?${query.join('&')}`
}