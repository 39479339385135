import TabBar from '@components/common/tabBar';
import React, {FunctionComponent, useEffect, useState} from 'react';
import Details from './details';
import WorkingHours from './workingHours';
import Reviews from './reviews';
import Promotions from './promotions';
import {getParking, Parking} from "@webservices/parking/Parking";

interface ParkingDetailsProps {
    parkingId?:string
    parking?: Parking
}

const ParkingDetails: FunctionComponent<ParkingDetailsProps> = (props) => {

    const [info, setInfo] = useState<Parking|undefined>(props.parking)

    useEffect(()=>{
        (async ()=>{
            if(props.parking || !props.parkingId) return;
            const res = await getParking(props.parkingId);
            if(!res) return;
            setInfo(res);
        })()
    }, [])

    return (
        <>
            {
                info && <TabBar
                    tabs={[
                        { title: "details", content: <Details parking={info} /> },
                        { title: "working hours", content: <WorkingHours days={info.openings} /> },
                        { title: "reviews", content: <Reviews parkingId={info.id} /> },
                        { title: "promotions", content: <Promotions  parkingId={info.id} /> }

                    ]}
                />
            }
        </>
    );
};

export default ParkingDetails;
