import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import styles from './addressSearch.module.scss';
import {MapContext} from "@components/common/map/googleMap/provider/mapContext";
import {Locator} from "@components/common/svgIcons";
import Icon from "@components/common/icon";
import Input from '@components/common/form/input';

interface AddressSearchProps {
    goToLocation?:boolean
    onSelect?:(res:google.maps.places.PlaceResult)=>void;
}

const AddressSearch:FunctionComponent<AddressSearchProps> = (props) => {

    const [searchResult, setSearchResult] = useState<google.maps.places.PlaceResult[]>()
    const [text, setText] = useState<string>("")

    const service = useRef<google.maps.places.PlacesService|null>(null);

    const { map, googleObject } = useContext(MapContext);

    useEffect(()=>{
        (async ()=>{
            const node = document.getElementById("result") as HTMLDivElement;
            if(!googleObject || !node) return;
            service.current = new googleObject.maps.places.PlacesService(node);
        })()
    }, [googleObject])

    const handleChanges = (keyword:string) => {
        setText(keyword);
        if(keyword === "" || !keyword){
            setSearchResult(undefined);
            return;
        }
        const request = {
            query: keyword,
            fields: ["name", "geometry"],
        };
        service.current && service.current.findPlaceFromQuery(
            request,
            (
                results: google.maps.places.PlaceResult[] | null,
                status: google.maps.places.PlacesServiceStatus
            ) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                    setSearchResult(results);
                }
            }
        );
    }

    const handleSelectResult = (location:google.maps.places.PlaceResult) => {
        if(props.onSelect) {
            props.onSelect(location);
            setText(location.name||"");
        }
        setSearchResult(undefined);
        if(!map || !location.geometry?.location || !props.goToLocation) return;
        map.setCenter(location.geometry.location);
    }

    return (
        <div className={styles.container}>
            <Input
                onChange={(id, value)=>{value && handleChanges(value)}}
                placeHolder={"Search Location"}
                default={text}
            />
            <div id={"result"}/>
            {
                searchResult && <div className={styles.result}>
                    <ul>
                        {
                            searchResult!.map((res, index) => {
                                return <li
                                    key={index}
                                    onClick={()=>handleSelectResult(res)}
                                    className={"d-flex align-items-center"}
                                >
                                    <Icon size={16} color={'secondary'} icon={<Locator/>} />
                                    <span className={"mx-1"}/>
                                    <p>{res.name}</p>
                                </li>
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    );
};

export default AddressSearch;