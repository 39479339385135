import {Coordinate} from "@types.d/Coordinate";

export const isInBound = (lat:number, lng:number, bound:google.maps.LatLngBounds):boolean => {
    return bound.getSouthWest().lat() <= lat &&
        bound.getSouthWest().lng() <= lng &&
        bound.getNorthEast().lat() >= lat &&
        bound.getNorthEast().lng() >= lng;
}

export const getDistance = (s:{lat:number, lng:number}, e:{lat:number, lng:number}):number => {
    const R = 6378137;
    const rLat1 = s.lat * (Math.PI/180);
    const rLat2 = e.lat * (Math.PI/180);
    const diffLat = rLat2-rLat1;
    const diffLon = (e.lng-s.lng) * (Math.PI/180);
    return  2 * R * Math.asin(Math.sqrt(Math.sin(diffLat/2)*Math.sin(diffLat/2)+Math.cos(rLat1)*Math.cos(rLat2)*Math.sin(diffLon/2)*Math.sin(diffLon/2)));
}

export const metersToLatitude = (meters:number):number => {
    const earthRadius = 6378137;
    const degreeConversion = 180 / (Math.PI * earthRadius);
    return meters * degreeConversion;
}

export const metersToLongitude = (meters:number, latitude:number) => {
    const earthRadius = 6378137; // Earth's radius in meters
    const longitudeConversion = 180 / (Math.PI * earthRadius * Math.cos(latitude * Math.PI / 180));
    return meters * longitudeConversion;
}

export const toRadians = (angle:number):number => {
    return angle * (Math.PI / 180);
}

export const toDegrees = (angle:number):number => {
    return angle * (180 / Math.PI);
}

const getAngle = (current:number):number => {
    const a = current - 90;
    if(a < 0) {
        return 360 + a;
    }
    if(a > 360) {
        return a - 360
    }
    return a;
}

export const getRecBounds = (start:Coordinate, angle:number, width:number, length:number):Coordinate[] => {
    const coords:Coordinate[] = [];
    let newAngle = angle;
    let lat = start.lat + (metersToLatitude(length/2) * Math.sin(toRadians(newAngle)));
    coords.push({
        lat:lat,
        lng:start.lng + (metersToLongitude(width, lat) * Math.cos(toRadians(newAngle)))
    })
    newAngle = getAngle(newAngle);
    lat = coords[0].lat + (metersToLatitude(width) * Math.sin(toRadians(newAngle)));
    coords.push({
        lat:lat,
        lng:coords[0].lng + (metersToLongitude(width, lat) * Math.cos(toRadians(newAngle)))
    })
    newAngle = getAngle(newAngle);
    lat = coords[1].lat + (metersToLatitude(length) * Math.sin(toRadians(newAngle)));
    coords.push({
        lat:lat,
        lng:coords[1].lng + (metersToLongitude(length, lat) * Math.cos(toRadians(newAngle)))
    })
    newAngle = getAngle(newAngle);
    lat = coords[2].lat + (metersToLatitude(width) * Math.sin(toRadians(newAngle)));
    coords.push({
        lat:lat,
        lng:coords[2].lng + (metersToLongitude(width, lat) * Math.cos(toRadians(newAngle)))
    })
    coords.push({
        lat:coords[0].lat,
        lng:coords[0].lng
    })
    return coords;
}

export const getNextCoordinate = (cur:Coordinate, angle:number, span:number):Coordinate => {
    const lat = cur.lat + (metersToLatitude(span) * Math.sin(toRadians(angle)));
    return ({
        lat:lat,
        lng:cur.lng + (metersToLongitude(span, lat) * Math.cos(toRadians(angle)))
    })
}

export const calculateAngle = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const x = getDistance({lat:lat1, lng:lon2}, {lat:lat1, lng:lon1});
    const y = getDistance({lat:lat2, lng:lon2}, {lat:lat1, lng:lon2});
    const xd = (lon2 - lon1) >= 0 ? 1 : -1;
    const yd = (lat2 - lat1) >= 0 ? 1 : -1;
    return toDegrees(Math.atan(y*yd/x*xd))
}