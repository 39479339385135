export const buildArray = (min:number, max:number, count:number, start:number, naturalNumbers?:boolean):number[] => {
    let s = start;
    if(s>max) s = min + Math.abs(max - Math.abs(start)) - 1
    if(s<min) s = max - Math.abs(min - Math.abs(start)) + 1;
    if(naturalNumbers && start < 0) s = s-2;
    let array:number[] = [s];
    let counter = 1;
    let index = 1;
    while(counter < count){
        if((s + index) <= max){
            array.push(s + index);
            index++;
        } else {
            array.push(min);
            s = min;
            index = 1;
        }
        counter++;
    }
    return array
}