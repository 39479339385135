import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import MainLayout from "../components/layouts/mainLayout";
import NavigationLayout from "@components/layouts/navigationLayout";
import Home from "@pages/home";
import ReservationDetail from "@pages/reservationDetail";
import Receipt from "@pages/receipt";
import Session from "@pages/session";
import ParkingDetailsPage from '@pages/parkingDetails';
import DirectionPage from "@pages/direction";
import Peer from "@pages/peer";
import Account from "@pages/account";
import OnBoard from "@pages/onBoard";
import ManageVehicles from "@pages/account/manageVehicles";
import PersonalInfo from "@pages/account/personalInfo";
import Favorites from "@pages/account/favorites";
import ManagePayments from "@pages/account/managePayments";
import Login from "@pages/auth/login";
import SetPassword from "@pages/auth/setPassword";
import Verify from "@pages/auth/verify";
import ResetPassword from "@pages/auth/resetPassword";
import Help from "@pages/account/help";
import FeedBack from "@pages/account/help/feedback";
import InviteFriends from "@pages/account/personalInfo/InviteFriends";
import ParkingSpots from "@pages/spots/parkingSpots";
import StreetSpots from "@pages/spots/streetSpots";
import LanguageSelection from "@pages/languageSelection";
import Settings from "@pages/account/settings";
import Privacy from "@pages/account/help/privacy";
import TermConditions from "@pages/account/help/termsAndConditions";

const Routes = () => {
    return (
        <>
            <Switch>
                <Route exact path={[
                    "/on-board",
                    "/language",
                    "/parking-details/:id",
                ]}>
                    {
                        <MainLayout>
                            <Switch>
                                <Route path={'/language'} exact component={LanguageSelection}/>
                                <Route path={'/on-board'} exact component={OnBoard}/>
                                <Route path={'/parking-details/:id'} exact component={ParkingDetailsPage}/>
                            </Switch>
                        </MainLayout>
                    }
                </Route>
                <Route exact path={[
                    "/auth/login",
                    "/auth/verify",
                    "/auth/set-password",
                    "/auth/reset-password",
                    "/auth/action",
                ]}>
                    {
                        !localStorage.getItem('token') ? <MainLayout>
                            <Switch>
                                <Route path={'/auth/login'} exact component={Login}/>
                                <Route path={'/auth/verify'} exact component={Verify}/>
                                <Route path={'/auth/set-password'} exact component={SetPassword}/>
                                <Route path={'/auth/reset-password'} exact component={ResetPassword}/>
                                <Route path={'/auth/action'} exact component={SetPassword}/>
                            </Switch>
                        </MainLayout> : <Redirect to={'/'}/>
                    }
                </Route>
                <Route exact path={[
                    "/",
                    "/street-spots/:id",
                    "/direction",
                ]}>
                    {
                       <NavigationLayout>
                            <Switch>
                                <Switch>
                                    <Route path={'/'} exact component={Home}/>
                                    <Route path={'/street-spots/:id'} exact component={StreetSpots}/>
                                    <Route path={'/direction'} exact component={DirectionPage}/>
                                </Switch>
                            </Switch>
                        </NavigationLayout>
                    }
                </Route>
                <Route exact path={[
                    "/session",
                    "/peer",
                    "/account",
                ]}>
                    {
                        localStorage.getItem('token') ? <NavigationLayout>
                            <Switch>
                                <Switch>
                                    <Route path={'/session'} exact component={Session}/>
                                    <Route path={'/peer'} exact component={Peer}/>
                                    <Route path={'/account'} exact component={Account}/>
                                </Switch>
                            </Switch>
                        </NavigationLayout> : <Redirect to={'/auth/login'}/>
                    }
                </Route>
                <Route exact path={[
                    "/account/manage-vehicles",
                    "/account/personal-info",
                    "/account/favorites",
                    "/account/settings",
                    "/account/help",
                    "/account/help/feedback",
                    "/account/help/terms-conditions",
                    "/account/help/policies",
                    "/account/manage-payments",
                    "/parking-spots/:id",
                    "/reservation-detail",
                    "/account/invite-friends",
                    "/receipt/:id",
                ]}>
                    {
                        localStorage.getItem('token') ? <MainLayout>
                            <Switch>
                                <Switch>
                                    <Route path={'/account/manage-vehicles'} exact component={ManageVehicles}/>
                                    <Route path={'/account/personal-info'} exact component={PersonalInfo}/>
                                    <Route path={'/account/favorites'} exact component={Favorites}/>
                                    <Route path={'/account/manage-payments'} exact component={ManagePayments}/>
                                    <Route path={'/account/help/policies'} exact component={Privacy}/>
                                    <Route path={'/account/help/terms-conditions'} exact component={TermConditions}/>
                                    <Route path={'/account/help/feedback'} exact component={FeedBack}/>
                                    <Route path={'/account/help'} exact component={Help}/>
                                    <Route path={'/account/settings'} exact component={Settings}/>
                                    <Route path={'/parking-spots/:id'} exact component={ParkingSpots}/>
                                    <Route path={'/reservation-detail'} exact component={ReservationDetail}/>
                                    <Route path={'/account/invite-friends'} exact component={InviteFriends}/>
                                    <Route path={'/receipt/:id'} exact component={Receipt}/>
                                </Switch>
                            </Switch>
                        </MainLayout> : <Redirect to={'/auth/login'}/>
                    }
                </Route>
            </Switch>
        </>

    );
};

export default Routes;
