import React, {FunctionComponent, useEffect, useRef, useState} from 'react';

interface TimerProps {
    seconds:number
    showZeroDay?:boolean
    showZeroHour?:boolean
    onExpired?:()=>void
    onChanged?:(seconds:number)=>void
    className?:string
}

const Timer:FunctionComponent<TimerProps> = (props) => {

    const time = useRef<number>(props.seconds)
    const expired= useRef<boolean>(false);

    const [seconds, setSeconds] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [days, setDays] = useState<number>(0);

    useEffect(() => {
        time.current = props.seconds;
        setTimeDivisions();
        const interval = setInterval(setTimeDivisions, 1000);
        return () => clearInterval(interval);
    }, [props.seconds])

    useEffect(()=>{
        props.onChanged && props.onChanged(time.current)
    }, [time.current])

    const setTimeDivisions = () => {
        time.current -= 1;
        if(time.current < 0) {
            if(props.onExpired && !expired.current){
                props.onExpired();
                expired.current = true;
            }
            return;
        }
        const t = time.current;
        switch (true){
            case t <= 60:
                setSeconds(t);
                setMinutes(0);
                break;

            case t <= 60*60:
                setSeconds(t % 60);
                setMinutes(Math.floor(t / 60));
                break;

            case t <= 24*3600:
                setSeconds(t % 60);
                setMinutes(Math.floor((t % 3600) / 60));
                setHours(Math.floor(t / 3600));
                break;

            case t > 24*3600:
                setSeconds(t % 60);
                setMinutes(Math.floor((t % 3600) / 60));
                setHours(Math.floor(t / 3600) % 24);
                setDays(Math.floor(t / (24*3600)));
                break;
        }
    }

    return (
        <div className={"d-flex"}>
            { (days === 0 && props.showZeroDay) && <p className={props.className}>{days} :</p> }
            { days > 0 && <p className={props.className}>{days} :</p> }
            { (hours === 0 && props.showZeroHour) && <p className={props.className}>{String(hours).padStart(2, "0")} :</p> }
            { hours > 0 && <p className={props.className}>{String(hours).padStart(2, "0")} :</p> }
            <p className={props.className}>{String(minutes).padStart(2, "0")} :</p>
            <p className={props.className}>{String(seconds).padStart(2, "0")}</p>
        </div>
    );
};

export default Timer;