import React, { FunctionComponent } from 'react';
import styles from "./review.module.scss";
import Icon from '../icon';
import {StarFill, StarHalf, StarOutline} from '../svgIcons';

interface ReviewProps {
    rate: number
}

const Review: FunctionComponent<ReviewProps> = (props) => {

    const getStars = ():number[] => {
        const n = Math.trunc(props.rate);
        if(n === 0) return [0, 0, 0, 0, 0];
        const r = props.rate % 1;
        let sa: number[] = [1, 1, 1, 1, 1];
        for (let i = 0; i < 5; i++) {
            if (i === n && r > 0) {
                sa[i] = 0.5;
            }
            if (i > n) {
                sa[i] = 0;
            }
        }
        return(sa);
    }

    return (
        <div className={styles.wrapper}>
            {
                getStars().map((star, index)=>{
                    return <div key={index}>
                        {star === 0 && <Icon size={15} color={'yellow'} icon={<StarOutline/>} />}
                        {star === 0.5 && <Icon size={15} color={'yellow'} icon={<StarHalf/>} />}
                        {star === 1 && <Icon size={15} color={'yellow'} icon={<StarFill/>} />}
                    </div>
                })
            }
        </div>
    );
};

export default Review;