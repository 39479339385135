import axios, {AxiosRequestHeaders, AxiosResponse, CancelToken} from "axios";
import {handleError} from "./errorHandler";

interface HeaderOptions {
    extraHeaders?:{[key:string]:string}
    upload?:boolean
    noAuth?:boolean
}

const getHeaders = (options: HeaderOptions):AxiosRequestHeaders => {

    // set default headers
    let headers:AxiosRequestHeaders = {
        Accept: options.upload ? "multipart/form-data" : "application/json",
        "Content-Type": options.upload ? "text/html" : "application/json"
    };
    // set additional headers
    if(options.extraHeaders) {
        Object.keys(options.extraHeaders).map(value => {
            if(options.extraHeaders) headers[value] = options.extraHeaders[value];
        })
    }
    // send jwt token if is not authentication needed
    if(!options.noAuth) {
        const token = localStorage.getItem('token');
        if(token) headers["Authorization"] = token;
    }
    return headers;
}

const cancelCall = (method:"GET"|"POST"|"PUT"|"PATCH"|"DELETE", url:string): CancelToken => {
    let cancelTokens = window.cancelTokens || [];
    const tokenIndex = cancelTokens.findIndex(ct=>(ct.method === method) && ct.url === url);
    if(tokenIndex !== -1) {
        cancelTokens[tokenIndex].token.cancel("Operation canceled due to new request.");
        cancelTokens.splice(tokenIndex, 1);
    }
    const token = axios.CancelToken.source();
    cancelTokens.push({
        token : token,
        method: method,
        url
    })
    window.cancelTokens = cancelTokens;
    return token.token;
}

const cancelSelectedCalls = (calls:{method:"GET"|"POST"|"PUT"|"PATCH"|"DELETE", url:string}[]) => {
    let cancelTokens = window.cancelTokens || [];
    calls.map(call=>{
        const tokenIndex = cancelTokens.findIndex(ct=>(ct.method === call.method) && ct.url === call.url);
        if(tokenIndex !== -1) {
            cancelTokens[tokenIndex].token.cancel("Operation canceled due to new request.");
            cancelTokens.splice(tokenIndex, 1);
        }
    })
    window.cancelTokens = cancelTokens;
}

export const httpGet = async <R>(
    url: string,
    headerOptions?: HeaderOptions,
    onError?: (error:any) => void,
    cancelable?:boolean,
    cancelCalls?:{method:"GET"|"POST"|"PUT"|"PATCH"|"DELETE", url:string}[]
): Promise<AxiosResponse<R>["data"]|undefined> => {
    let token:CancelToken | undefined;
    if(cancelCalls) cancelSelectedCalls(cancelCalls);
    if(cancelable) token = cancelCall("GET", url);
    return new Promise<R>(resolve => {
        axios.get(
            url,
            {
                headers:getHeaders(headerOptions ? headerOptions : {}),
                cancelToken:token
            }
        )
            .then(response => {resolve(response.data)})
            .catch(error => {
                handleError(error, onError)
            })
    });
};

export const httpPost = async <B, R>(
    url: string,
    body: B,
    headerOptions?: HeaderOptions,
    onError?: (error:any) => void,
    cancelable?:boolean,
    cancelCalls?:{method:"GET"|"POST"|"PUT"|"PATCH"|"DELETE", url:string}[]
): Promise<AxiosResponse<R>["data"]|undefined> => {
    return new Promise<R>(resolve => {
        let token:CancelToken | undefined;
        if(cancelCalls) cancelSelectedCalls(cancelCalls);
        if(cancelable) token = cancelCall("POST", url);
        axios.post(
            url,
            body,
            {
                headers:getHeaders(headerOptions ? headerOptions : {}),
                cancelToken:token
            }
        )
            .then(response => {resolve(response.data)})
            .catch(error => {
                handleError(error, onError)
            })
    });
};

export const httpPut = async <B, R>(
    url: string,
    body: B,
    headerOptions?: HeaderOptions,
    onError?: (error:any) => void,
    cancelable?:boolean,
    cancelCalls?:{method:"GET"|"POST"|"PUT"|"PATCH"|"DELETE", url:string}[]
): Promise<AxiosResponse<R>["data"]|undefined> => {
    let token:CancelToken | undefined;
    if(cancelCalls) cancelSelectedCalls(cancelCalls);
    if(cancelable) token = cancelCall("PUT", url);
    return new Promise<R>(resolve => {
        axios.put(
            url,
            body,
            {
                headers:getHeaders(headerOptions ? headerOptions : {}),
                cancelToken:token
            }
        )
            .then(response => {resolve(response.data)})
            .catch(error => {
                handleError(error, onError)
            })
    });
};

export const httpPatch = async <B, R>(
    url: string,
    body: B,
    headerOptions?: HeaderOptions,
    onError?: (error:any) => void,
    cancelable?:boolean,
    cancelCalls?:{method:"GET"|"POST"|"PUT"|"PATCH"|"DELETE", url:string}[]
): Promise<AxiosResponse<R>["data"]|undefined> => {
    let token:CancelToken | undefined;
    if(cancelCalls) cancelSelectedCalls(cancelCalls);
    if(cancelable) token = cancelCall("PATCH", url);
    return new Promise<R>(resolve => {
        axios.patch(
            url,
            body,
            {
                headers:getHeaders(headerOptions ? headerOptions : {}),
                cancelToken:token
            }
        )
            .then(response => {resolve(response.data)})
            .catch(error => {
                handleError(error, onError)
            })
    });
};

export const httpDelete = async <R>(
    url: string,
    headerOptions?: HeaderOptions,
    onError?: (error:any) => void,
    cancelable?:boolean,
    cancelCalls?:{method:"GET"|"POST"|"PUT"|"PATCH"|"DELETE", url:string}[]
): Promise<AxiosResponse<R>["data"]|undefined> => {
    let token:CancelToken | undefined;
    if(cancelCalls) cancelSelectedCalls(cancelCalls);
    if(cancelable) token = cancelCall("DELETE", url);
    return new Promise<R>(resolve => {
        axios.delete(
            url,
            {
                headers:getHeaders(headerOptions ? headerOptions : {}),
                cancelToken:token
            }
        )
            .then(response => {resolve(response.data)})
            .catch(error => {
                handleError(error, onError)
            })
    });
};

export const httpUpload =  async <R>(
    url:string,
    body:any,
    headerOptions?: HeaderOptions,
    onUploadProgress?:(e:any)=>void,
    setCancel?:any,
    onError?: (error:any) => void
): Promise<AxiosResponse<R>["data"]|undefined> => {
    return new Promise<R>(resolve => {
        axios(
            url,
            {
                method: 'post',
                data: body,
                headers: getHeaders(headerOptions ? headerOptions : {}),
                onUploadProgress: onUploadProgress ? onUploadProgress : ()=>{},
                cancelToken: new axios.CancelToken(function executor(c:any) {
                    setCancel(c);
                })
            }
        )
            .then(response => {resolve(response.data)})
            .catch(error => {
                handleError(error, onError)
            })
    });
}