import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import Text from "@components/common/text";
import styles from "./input.module.scss";
import {LanguageContext} from "../../../../contexts/languageContext";
import {makeFirstLetterUp, trim} from "@utilities/stringUtils";
import moment from "moment/moment";

interface InputProps {
    id?:string
    default?:any
    label?:string
    type?:"password"|"text"|"number"
    allowedCharacter?:"number"|"latin"|"dateTime"|"date"|"time"
    placeHolder?:string
    onChange:(id?:string, value?:string)=>void
    onClick?:()=>void
    icon?:{
        icon:JSX.Element
        action:()=>void
    }
    className?:string
    hint?:string
    lock?:boolean
    minValue?:string|number
}

const Input:FunctionComponent<InputProps> = (props) => {

    const {translate} = useContext(LanguageContext);
    const [value, setValue] = useState<string>("");

    useEffect(()=>{
        if(!props.default) {
            setValue(props.default);
            return;
        }
        setValue(props.allowedCharacter ? trim(props.allowedCharacter, props.default) : props.default || "")
    }, [props.default])

    const handleChange = (targetValue:string) => {
        let validValue = targetValue;
        if(props.allowedCharacter){
            validValue = trim(props.allowedCharacter, validValue);
        }
        if(props.minValue && props.allowedCharacter) {
            switch (true){
                case ["dateTime","date","time"].includes(props.allowedCharacter):
                    let date = moment(validValue, "YYYY-MM-DD", true);
                    if(date.isValid()){
                        const diff=moment(validValue).diff(moment(props.minValue).format("YYYY-MM-DD HH:mm:ss"), "seconds");
                        if(diff < 0) {
                            validValue = String(props.minValue);
                        }
                    }
                    break;

                case props.allowedCharacter === "number":
                    if(Number(validValue) < Number(props.minValue)) {
                        validValue = String(props.minValue);
                    }
                    break;
            }
        }
        if(value !== validValue){
            setValue(validValue);
            props.onChange(props.id, validValue);
        }
    }

    return (
        <div
            className={`${styles.container} ${props.className && props.className}`}
            onClick={props.onClick}
            id={String(props.id)}
        >
            {
                props.label && <label>
                    <Text value={props.label} class={"capitalize"}/>
                </label>
            }
            <input
                type={props.type}
                value={value}
                onChange={(e)=>{handleChange(e.target.value)}}
                placeholder={props.placeHolder && makeFirstLetterUp(translate(props.placeHolder?.toLowerCase()))}
                size={1}
                disabled={props.lock}
            />
            {
                props.hint && <p className={styles.hint}><Text value={props.hint}/></p>
            }
            {
                props.icon && <div
                    className={styles.icon}
                    onClick={()=>{props.icon!.action()}}
                >
                    {props.icon.icon}
                </div>
            }
        </div>
    );
};

export default Input;