import Chips from '@components/common/chips';
import Text from '@components/common/text';
import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./details.module.scss";
import CustomButton from '@components/common/customButton';
import Icon from '@components/common/icon';
import { Share } from '@components/common/svgIcons';
import HorizontalSlider from '@components/basic/horizontalSlider';
import Divider from '@components/common/divider';
import {Parking} from "@webservices/parking/Parking";
import {getParkingPictures} from "@webservices/parking/picture";

interface DetailsProps {
    parking:Parking
}
const Details: FunctionComponent<DetailsProps> = (props) => {

    const [pictures, setPictures] = useState<string[]>([]);

    useEffect(()=> {
        (async () => {
            const res = await getParkingPictures(props.parking.pictures);
            setPictures(res);
        })()
    }, [])

    return (
        <div className={'d-flex gap-2 flex-column'}>
            <div>
                <div className={"w-100 d-flex justify-content-between align-items-center"}>
                    <p className={styles.titles}><Text value='about parking' /></p>
                    <CustomButton
                        theme={"no-border"}
                        icon={<Icon size={20} color={'primary'} icon={<Share />} />}
                        className={'p-0'}
                    />
                </div>
                <p>{props.parking.parkingOverview}</p>
            </div>
            <div className={"d-flex flex-column gap-2 my-1"}>
                <p className={styles.titles}><Text value='features' /></p>
                <div className={"d-flex gap-2 flex-wrap"}>
                    {
                        props.parking.features.map((feature: any) => {
                            return <Chips title={feature} />
                        })
                    }
                </div>
            </div>
            <div className={"d-flex flex-column gap-2 my-1"}>
                <p className={styles.titles}><Text value='role & limitations' /></p>
                <p>{props.parking.rolesAndLimitations}</p>
            </div>
            <div className={"d-flex gap-2 mt-1"}>
                <p className={styles.titles}><Text value='vehicle type' />:</p>
                <p>{props.parking.parkingType}</p>
            </div>
            <Divider/>
            <div className={"w-100 d-flex justify-content-between"}>
                <div className={"d-flex"}>
                    <p className={styles.titles}><Text value='joined date' />:</p>
                    <p>{props.parking.parkingOpeningDate}</p>
                </div>
                <div className={"d-flex"}>
                    <p className={styles.titles}><Text value='booked' />:</p>
                    <p>120</p>
                </div>
            </div>
            <div className={"d-flex flex-column gap-2 mb-1"}>
                <p className={styles.titles}><Text value='maximum vehicle size' />:</p>
                <p>{""}</p>
            </div>
            <div className={"d-flex flex-column gap-2 my-1"}>
                <p className={styles.titles}><Text value='distance' />:</p>
                <p>1h  20m to parking</p>
            </div>
            <div className={"d-flex flex-column gap-2 my-1"}>
                <p className={styles.titles}><Text value='address' />:</p>
                <p>{props.parking.parkingAddress}</p>
            </div>
            <div className={"d-flex flex-column gap-2 my-1"}>
                <p className={styles.titles}><Text value='photos' />:</p>
                {
                    pictures[0] ? <HorizontalSlider
                        slides={
                            pictures.map((p: string, index: number) => {
                                return <img key={index} src={p} alt={""}/>
                            })
                        }
                        slideWidth={240}
                        slideHeight={150}
                    /> : <div style={{display:"flex",height:150, width:240}}/>
                }
            </div>
        </div>
    );
};

export default Details;