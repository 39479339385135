import React, { FunctionComponent } from 'react';
import styles from "./chips.module.scss";

interface ChipsProps {
    title:string
}

export const Chips:FunctionComponent<ChipsProps> = (props) => {
    return(<div className={styles.container}>
        <p>{props.title}</p>
    </div>);
};

export default Chips;