import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./bottomSheet.module.scss";
import useWidthChange from "@hooks/useWidthChange";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";

interface BottomSheetLayoutProps {
    header?:JSX.Element
    bottomSheet?:JSX.Element
    children?: React.ReactNode
    sideAlign?:"start"|"end"|"center"
    fullHeight?:boolean
    image?:string
    jsx?:JSX.Element
    overlap?:boolean
    white?:boolean
    boxShadow?:boolean
    minHeight?:number|"initial"
    paddingTop?:"large"|"small"
}

const BottomSheetLayout:FunctionComponent<BottomSheetLayoutProps> = (props) => {

    const [isMobile, setIsMobile] = useState<boolean>(false);

    const width = useWidthChange();

    useEffect(()=>{
        setIsMobile(width.isMobile)
    }, [width.isMobile])

    return (
        <div className={"d-flex flex-column h-100"}>
            {
                !props.header ? <SimpleHeader
                    backControl
                    hideBorder
                /> : props.header
            }
            {
                props.image && <div className={styles.image}>
                    <img src={props.image} alt={"slide"}/>
                </div>
            }
            {
                props.jsx && <div className={styles.jsx}>
                    {props.jsx}
                </div>
            }
            <div
                className={`${styles.card} ${props.overlap && styles.overlap} ${props.white && styles.white} ${props.boxShadow && styles.shadow}`}
                style={{
                    minHeight:props.minHeight ? props.minHeight : 250,
                    paddingTop: props.paddingTop === "small" ? "1.5rem" : "3rem"
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

export default BottomSheetLayout;