import React, {FunctionComponent} from 'react';
import styles from "./customButton.module.scss";
import {CornerStyle} from "@types.d/CornerStyles";
import CmsColors from "@types.d/CmsColors";

const theme = [
    "no-border",
    "primary",
    "primary-outline",
    "secondary",
    "secondary-outline",
    "third",
    "third-outline",
    "blue",
    "blue-outline",
    "red",
    "red-outline",
    "green",
    "green-outline",
    "white",
    "white-outline",
    "light-gray",
    "light-gray-outline",
    "confirm",
    "confirm-outline",
    "delete",
    "delete-outline",
    "disabled",
    "disabled-outline"
] as const;
type Theme = typeof theme[number];

interface BtnProps {
    id?:string
    href?: string
    disabled?: boolean
    loading?: boolean
    className?: string
    text?: string|JSX.Element
    target?: string;
    cornerStyle?: keyof typeof CornerStyle
    fullWidth?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    theme?: Theme;
    borderColor?:CmsColors
    textColor?:CmsColors
    textHOverColor?:CmsColors
    loadingTheme?: "black" | "white"
    style?: object
    title?: string
    icon?: JSX.Element
    loadingIcon?: JSX.Element
    iconAlign?:"start"|"end"|"center"
    verticalAlign?:boolean
}

const CustomButton : FunctionComponent<BtnProps> = (props) => {

    const createChildrenElement=()=> {
        return(
            !props.loading ?
                <>
                    {props.icon && <div>
                        <div
                            className={`d-flex justify-content-${props.iconAlign||"center"} align-items-center`}
                            style={{width:30, height:30}}
                        >
                            {props.icon}
                        </div>
                    </div>}
                    {props.text && props.text}
                </>:
                <div className={`d-flex justify-content-center align-items-center`}>
                    <p className="opacity-0">{props.text && props.text}</p>
                    <div className="position-absolute">{props.loadingIcon && props.loadingIcon}</div>
                </div>
        )
    }

    return (
        <>
            {
                !props.href ? <button
                    id={props.id}
                    className={`d-flex justify-content-center align-items-center 
                    ${styles.customBtn} 
                    ${props.theme ? styles["customBtn-"+props.theme] : styles["customBtn-primary-outline"]} 
                    ${props.borderColor ? "cms-border-"+props.borderColor : ""} 
                    ${props.cornerStyle ? "cornerStyle-"+CornerStyle[props.cornerStyle] : "cornerStyle-"+CornerStyle.Small} 
                    ${props.disabled ? styles["customBtn-disabled"] : "" } 
                    ${props.fullWidth ? "w-100" : "" }
                    ${props.textColor ? `cms-color-${props.textColor} cms-fill-${props.textColor}` : "" }
                    ${props.textHOverColor ? `cms-hover-${props.textHOverColor}` : "" }
                    ${props.verticalAlign ? `flex-column` : "" }
                    ${props.className}`
                    }
                    style={props.style}
                    title={props.title}
                    onClick={props.loading || props.disabled  ? ()=>{} : (e)=>{
                        props.onClick && props.onClick(e);
                        e.stopPropagation();
                    }}
                >
                    {createChildrenElement()}

                </button> : <a
                    className={`d-flex justify-content-center align-items-center
                        ${styles.customBtn} 
                        ${props.theme ? styles["customBtn-"+props.theme] : styles["customBtn-primary-outline"]} 
                        ${props.cornerStyle ? styles["cornerStyle-"+props.cornerStyle] : styles["cornerStyle-"+CornerStyle.Small]} 
                        ${props.disabled ? styles["customBtn-disabled"] : "" } 
                        ${props.fullWidth ? "w-100" : "" }
                        ${props.className}`
                    }
                    style={props.style}
                    href={props.href}
                    title={props.title}
                    target={props.target ? props.target : "_blank"}
                >
                    {createChildrenElement()}
                </a>
            }
        </>

    );
};

export default CustomButton;