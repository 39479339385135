import React from 'react';
import styles from "./managePayment.module.scss";

const ManagePaymentOnLoad = () => {
    return (
        <div>
            <div
                className={`${styles.paymentRow} ${styles.onload} gradient`}
            >
                <div className={"d-flex justify-content-start align-items-center gap-3 w-100"}>
                    <span/>
                    <div className={"d-flex flex-grow-1 flex-column"}>
                        <h2/>
                        <p/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManagePaymentOnLoad;