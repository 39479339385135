import React, {FunctionComponent} from 'react';
import CustomButton from "@components/common/customButton";
import {Apple, Facebook, Google, SMS} from "@components/common/svgIcons";
import {useHistory} from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {onUserLoggedIn} from "../../services/app/userServices";

interface AuthMethodsProps {
    gmail?:boolean
    sms?:boolean
    email?:boolean
    facebook?:boolean
    apple?:boolean
}

const AuthMethods:FunctionComponent<AuthMethodsProps> = (props) => {

    const history = useHistory();

    const auth = getAuth();

    const gmailLogin = async () => {
        try{
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            await GoogleAuthProvider.credentialFromResult(result);
            const user = auth.currentUser;
            if(user){
                const convertedResult = user as {[key:string]:any}
                await onUserLoggedIn({
                    token: convertedResult.accessToken,
                    uid: convertedResult.uid,
                    email:convertedResult.email
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div
            className={"d-flex align-items-center justify-content-center gap-3 bold"}
        >
            <div className={"d-flex gap-3"}>
                {
                    props.sms && <CustomButton
                        theme={"no-border"}
                        icon={<SMS/>}
                        className={"p-0"}
                        onClick={()=>history.push("/auth/login")}
                    />
                }
                {
                    props.gmail && <CustomButton
                        theme={"no-border"}
                        icon={<Google/>}
                        className={"p-0"}
                        onClick={gmailLogin}
                    />
                }
                {
                    props.apple && <CustomButton
                        theme={"no-border"}
                        icon={<Apple/>}
                        className={"p-0"}
                    />
                }
                {
                    props.facebook && <CustomButton
                        theme={"no-border"}
                        icon={<Facebook/>}
                        className={"p-0"}
                    />
                }
            </div>
        </div>
    );
};

export default AuthMethods;