import React from 'react';
import styles from "@pages/account/manageVehicles/manageVehicle.module.scss";

const ManageVehicleOnLoad = () => {
    return (
        <div>
            <div
                className={`${styles.vehicleRow} ${styles.onload} gradient`}
            >
                <div className={"w-100"}>
                    <h2/>
                    <p/>
                </div>
            </div>
        </div>
    );
};

export default ManageVehicleOnLoad;