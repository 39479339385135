import React, {useContext, useEffect, useState} from 'react';
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import Text from "@components/common/text";
import {LanguageContext} from "../../../contexts/languageContext";
import {languagesList} from "../../../constant/languages";
import styles from "@pages/languageSelection/language.module.scss";
import Switch from "@components/common/form/switch";
import Divider from "@components/common/divider";

const Settings = () => {

    const { onLanguageChange } = useContext(LanguageContext);

    const [selected, setSelected] = useState<number>(0);
    const [exp, setExp] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);

    useEffect(()=>{
        const lang = localStorage.getItem("lang");
        if(lang){
            const index = languagesList.findIndex(l=>l.value === lang);
            if(index !== -1) setSelected(index);
        }
    }, [])

    return (
        <SimpleLayout
            header={<SimpleHeader
                title={<Text value={"settings"} class={"capitalize"}/>}
                backControl
            />}
        >
            <div className={"d-flex flex-column gap-3 h-100"}>
                <div className={"w-100 d-flex justify-content-between align-items-center"}>
                    <h2><Text value={"parking confirmed"} class={"capitalize"}/></h2>
                    <Switch
                        default={confirm}
                        onChange={(id, value)=>{setConfirm(value)}}
                    />
                </div>
                <p><Text value={"a notification that confirming your book or in parking process."} class={"capitalize"}/></p>
                <Divider/>
                <div className={"w-100 d-flex justify-content-between align-items-center"}>
                    <h2><Text value={"expiry reminder"} class={"capitalize"}/></h2>
                    <Switch
                        default={exp}
                        onChange={(id, value)=>{setExp(value)}}
                    />
                </div>
                <p><Text value={"get reminder before your session end."} class={"capitalize"}/></p>
                <Divider/>
                <h2 className={"mb-3"}><Text value={"change language"} class={"capitalize"}/></h2>
                <div className={"d-flex gap-3"}>
                    {
                        languagesList.map((lng, index)=>{
                            return <div
                                className={`${styles.languageCard} ${index === selected && styles.active}`}
                                key={index}
                                onClick={()=>{
                                    setSelected(index);
                                    onLanguageChange(lng.value);
                                }}
                            >
                                <img src={lng.img} alt={lng.title}/>
                            </div>
                        })
                    }
                </div>
            </div>
        </SimpleLayout>
    );
};

export default Settings;