import React, {FunctionComponent} from 'react';
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
interface ConfirmModalProps {
    title:string
    confirmText?:string
    onConfirm:()=>void
}

const Alert:FunctionComponent<ConfirmModalProps> = (props) => {
    return (
        <div className={"p-4"}>
            <p className={"text-center mb-3"}>{<Text value={props.title}/>}</p>
            <div className={"d-flex justify-content-center gap-2"}>
                <CustomButton
                    text={<Text value={props.confirmText ? props.confirmText : "ok"} class={"capitalize"}/>}
                    theme={"primary"}
                    onClick={props.onConfirm}
                />
            </div>
        </div>
    );
};

export default Alert;