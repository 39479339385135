import React, {FunctionComponent, useState} from 'react';
import Input from "@components/common/form/input";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import {createPayment, Payment, updatePayment} from "@webservices/account/payment";
import BottomSheet from "@components/common/bottomSheet";
import SetTime from "@components/bottomSheets/setTime";
import Icon from "@components/common/icon";
import {Calender, Loading} from "@components/common/svgIcons";
import {failToast, successToast} from "@toast/index";
import moment from "moment";
import {validate} from "@utilities/validator";
import {creatPaymentSchema} from "@schemas/payment";
interface AddPaymentProps {
    payment?:Payment
    onClose:()=>void
}

const AddPayment:FunctionComponent<AddPaymentProps> = (props) => {

    const [showDatePickerBT, setShowDatePickerBt] = useState<boolean>(false);
    const [payment, setPayment] = useState<{[key:string]:any}>(props.payment||{});
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (id?:string, value?:string) => {
        if(!id) return;
        setPayment(prevState => ({...prevState, [id]:value}))
    }

    const savePayment = async () => {
        const paymentObj = {
            cardNumber: payment.cardNumber,
            expireDate: payment.expireDate
        }
        if(!validate(paymentObj, creatPaymentSchema)) {
            failToast(<Text value={"please enter your card details."} class={"capitalize"}/>);
            return;
        }
        setLoading(true);
        if(props.payment) {
            await updatePayment(props.payment.id, paymentObj);
            successToast(<Text value={"the card has been added successfully."} class={"capitalize"}/>);
        } else {
            await createPayment(paymentObj);
            successToast(<Text value={"the card has been updated successfully."} class={"capitalize"}/>);
        }
        props.onClose();
        setLoading(false);
    }

    return (
        <div className={"d-flex flex-column h-100 p-4"}>
            <Input
                id={"cardNumber"}
                allowedCharacter={"number"}
                placeHolder={"1234 5687 4587 4574"}
                label={"card number"}
                onChange={handleChange}
                className={"mb-4"}
                default={payment?.cardNumber}
            />
            <Input
                id={"expireDate"}
                placeHolder={"enter exp date"}
                allowedCharacter={"date"}
                label={"Exp date"}
                minValue={moment().format("YYYY-MM-DD")}
                onChange={handleChange}
                className={"mb-4"}
                default={payment?.expireDate}
                icon={{
                    icon:<Icon size={20} color={"primary"} icon={<Calender/>}/>,
                    action:()=>setShowDatePickerBt(true)
                }}
            />
            <div className={"d-flex gap-2 justify-content-center"}>
                <CustomButton
                    theme={"primary"}
                    text={<Text value={"Save Card"}/>}
                    onClick={savePayment}
                    loading={loading}
                    loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                />
                <CustomButton
                    theme={"primary-outline"}
                    text={<Text value={"Cancel"}/>}
                    onClick={()=>{props.onClose()}}
                />
            </div>
            {
                showDatePickerBT && <BottomSheet
                    onClose={()=>setShowDatePickerBt(false)}
                >
                    <SetTime
                        id={""}
                        format={"YYYY-MM-DD"}
                        default={payment?.expireDate}
                        minValue={moment().format("YYYY-MM-DD")}
                        onCancel={()=>setShowDatePickerBt(false)}
                        onSave={(id:string, value:string)=>{
                            handleChange("expireDate", value);
                            setShowDatePickerBt(false);
                        }}
                        pickerType={"date"}
                    />
                </BottomSheet>
            }
        </div>
    );
};

export default AddPayment;