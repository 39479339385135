import React, {FunctionComponent} from 'react';
import CustomButton from "@components/common/customButton";
import Icon from "@components/common/icon";
import {Back} from "@components/common/svgIcons";
import {useHistory} from "react-router-dom";

interface SimpleHeaderProps {
    backControl?:boolean
    back?:string
    title?:string|JSX.Element
    hideBorder?:boolean
    extra?:JSX.Element
}

const SimpleHeader:FunctionComponent<SimpleHeaderProps> = (props) => {

    const history = useHistory();

    return (
        <div className={`d-flex mx-4 py-3 align-items-center ${!props.hideBorder && "border-bottom"}`}>
            {
                (props.backControl || props.back) && <CustomButton
                    theme={"no-border"}
                    icon={<Icon size={25} color={"dark-gray"} icon={<Back/>}/>}
                    onClick={()=>{props.back ? history.push(props.back!) : history.goBack()}}
                    className={"p-0"}
                />
            }
            {
                props.title && <p className={"text-capitalize bold flex-grow-1 text-center"}>
                    {props.title}
                </p>
            }
            {
                props.extra && <div className={"d-flex"}>
                    {props.extra}
                </div>
            }
        </div>
    );
};

export default SimpleHeader;