import React, {FunctionComponent, useState} from 'react';
import Input from "@components/common/form/input";
import Text from "@components/common/text";
import CustomButton from "@components/common/customButton";
import Select from "@components/common/form/select";
import Radio from "@components/common/form/radio";
import {createVehicle, updateVehicle, Vehicle} from "@webservices/account/vehicle";
import Joi from "joi";
import {failToast, successToast} from "@toast/index";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";

interface AddVehicleProps {
    vehicle?:Vehicle
    onClose:()=>void
}

const AddVehicle:FunctionComponent<AddVehicleProps> = (props) => {

    const [vehicle, setVehicle] = useState<{[key:string]:any}>(props.vehicle || {});
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (id?:string, value?:string) => {
        if(id!==undefined){
            setVehicle(prevState => ({...prevState, [id]:value}));
        }
    }

    const validate = (obj:{[K:string]:any}):boolean => {
        const result = Joi.object({
            vehicleName: Joi.string().required(),
            make: Joi.string().required(),
            color: Joi.string().required(),
            plateNumber: Joi.string().required(),
            policyNumber: Joi.string().required(),
            vecType: Joi.string().required(),
            vecCategory: Joi.string().required(),
            isDefaultVehicle: Joi.boolean().required(),
        }).required().validate(obj);
        if(result.error !== undefined){
            failToast(<Text value={"please fill in the required information."} class={"capitalize"}/>);
            return false;
        }
        return true;
    }

    const saveVehicle = async () => {
        const vehicleObj = {
            color: vehicle.color,
            policyNumber: vehicle.policyNumber,
            make: vehicle.make,
            plateNumber: vehicle.plateNumber,
            vecCategory: vehicle.vecCategory,
            vecType: vehicle.vecType,
            vehicleName: vehicle.vehicleName,
            isDefaultVehicle: true
        }
        if(!validate(vehicleObj)) return;
        setLoading(true);
        if(!props.vehicle) {
            await createVehicle(vehicleObj, (err)=>{
                failToast(<Text value={err.customMessage || "unknown error"} class={"capitalize"}/>);
                setLoading(false);
            });
            successToast(<Text value={"vehicle created successfully"} class={"capitalize"}/>);
        } else {
            await updateVehicle(vehicle.id, vehicleObj, (err)=>{
                failToast(<Text value={err.customMessage || "unknown error"} class={"capitalize"}/>);
                setLoading(false);
            });
            successToast(<Text value={"the vehicle information has been saved."} class={"capitalize"}/>);
        }
        props.onClose();
        setLoading(false);
    }

    return (
        <div className={"d-flex flex-column h-100 p-4"}>
            <Input
                id={"vehicleName"}
                placeHolder={"enter your favorite name"}
                label={"vehicle name"}
                onChange={handleChange}
                className={"mb-3"}
                default={vehicle?.vehicleName}
            />
            <Input
                id={"plateNumber"}
                placeHolder={"enter your plate number"}
                label={"plate number"}
                allowedCharacter={"latin"}
                onChange={handleChange}
                className={"mb-3"}
                default={vehicle?.plateNumber}
            />
            <Input
                id={"policyNumber"}
                placeHolder={"9-13 digits code"}
                allowedCharacter={"number"}
                label={"policy number"}
                onChange={handleChange}
                className={"mb-3"}
                default={vehicle?.policyNumber}
            />
            <Select
                id={"color"}
                placeHolder={"select color"}
                label={"color"}
                onChange={handleChange}
                className={"mb-3"}
                default={vehicle?.color}
                options={[
                    {key:"black", value:"black"},
                    {key:"white", value:"white"},
                    {key:"red", value:"red"},
                    {key:"green", value:"green"},
                    {key:"silver", value:"silver"},
                ]}
            />
            <Select
                id={"make"}
                placeHolder={"select make"}
                label={"make"}
                onChange={handleChange}
                className={"mb-3"}
                default={vehicle?.make}
                options={[
                    {key:"honda", value:"honda"},
                    {key:"bmw", value:"bmw"},
                    {key:"mazda", value:"mada"},
                ]}
            />
            <Select
                id={"vecCategory"}
                placeHolder={"select category type"}
                label={"category"}
                onChange={handleChange}
                className={"mb-3"}
                default={vehicle?.vecCategory}
                options={[
                    {key:"category1", value:"category1"},
                    {key:"category2", value:"category2"},
                    {key:"category3", value:"category3"},
                ]}
            />
            <Radio
                id={"vecType"}
                label={"vehicle type"}
                options={[
                    {title:"Car", value:"type-1"},
                    {title:"Motorcycle", value:"type-2"}
                ]}
                onChange={handleChange}
                className={"mb-4"}
                default={vehicle?.vecType}
            />
            <div className={"d-flex gap-2 justify-content-center"}>
                <CustomButton
                    theme={"primary"}
                    text={<Text value={"save vehicle"} class={"capitalize"}/>}
                    onClick={saveVehicle}
                    loading={loading}
                    loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                />
                <CustomButton
                    theme={"primary-outline"}
                    text={<Text value={"cancel"} class={"capitalize"}/>}
                    onClick={props.onClose}
                />
            </div>
        </div>
    );
};

export default AddVehicle;