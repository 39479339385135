import {initializeApp} from "firebase/app";
import {getMessaging} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCnVZnyylHoAyZWEj3a1M1IP2deCml2v9Q",
    authDomain: "stage-justu.firebaseapp.com",
    projectId: "stage-justu",
    storageBucket: "stage-justu.appspot.com",
    messagingSenderId: "654100939032",
    appId: "1:654100939032:web:023eac98318b069dfcde6d",
    measurementId: "G-V57YW6VG5B"
};



export const initiateFirebase = () => {
    initializeApp(firebaseConfig);
}

export const messaging = getMessaging(initializeApp(firebaseConfig));
