import React, {useLayoutEffect, useState} from 'react';
import styles from './spots.module.scss';
import GoogleMap from "@components/common/map/googleMap";
import GoogleMapProvider from "@components/common/map/googleMap/provider";
import Polygon from "@components/common/map/googleMap/polygon";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Polygon as PolygonType} from "@types.d/Polygon";
import BottomSheetLayout from "@components/layouts/contentLayouts/bottomSheetLayout";
import CustomButton from "@components/common/customButton";
import {getStreetParkingByCode} from "@webservices/parking/inStreet";
import {Coordinate} from "@types.d/Coordinate";
import {getPoint, linearRegression} from "@utilities/linearRegression";
import {calculateAngle, getDistance, getNextCoordinate, getRecBounds} from "@utilities/coordinateTools";
import {letters} from "../../constant/letters";
import {failToast} from "@toast/index";
import Text from "@components/common/text";

const StreetSpots = () => {

    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { state } = useLocation<{
        id:string
        start:string
        end:string
        vehicleId:string
        paymentId:string
    }>();

    const [polygons, setPolygons] = useState<PolygonType[]>([]);
    const [selectedZone, setSelectedZone] = useState<string>();
    const [inStreetId, setInStreetId] = useState<string>();

    useLayoutEffect(()=>{
        (async ()=>{
            const result = await  getStreetParkingByCode(id, ()=>{
                failToast(<Text value={"spot not found!!!"} class={"capitalize"}/>);
                history.push("/");
            });
            setInStreetId(result!.id)
            const polys:PolygonType[] = [];
            const points:{x:number[], y:number[]} = {x:[], y:[]};
            const coordinates:Coordinate[] = [];
            result!.geoJson.geometry.coordinates[0].mainCoordinates.map((c, index)=>{
                if(index !== result!.geoJson.geometry.coordinates[0].mainCoordinates.length-1){
                    points.x.push(c.lng);
                    points.y.push(c.lat);
                }
                coordinates.push({lat:c.lat, lng:c.lng});
            })
            const lotsCount = result!.lots.length;
            if(lotsCount === 0) {
                setSelectedZone("na")
                polys.push({
                    id:"na",
                    coords: coordinates,
                    strokeColor: "#B7EB8F",
                    strokeOpacity: 1.0,
                    strokeWeight: 1,
                    fillColor: "#F6FFED",
                    fillOpacity: 1.0,
                })
            } else {
                const lr = linearRegression(points.x, points.y);
                const sortedX = points.x.sort();
                const line:Coordinate[] = [
                    {lng:sortedX[0], lat:getPoint(lr.a, lr.b, sortedX[0])},
                    {lng:sortedX[sortedX.length-1], lat:getPoint(lr.a, lr.b, sortedX[sortedX.length-1])},
                ];
                const span = getDistance(line[0], line[1])/lotsCount;
                const angle = calculateAngle(line[0].lat, line[0].lng, line[1].lat, line[1].lng);
                let startPoint = line[0];
                let w = span - 0.5;
                let l = 2.5;
                if(span < 5){
                    w = span-0.5;
                    l = 5;
                }
                result!.lots.map((lot, index)=>{
                    polys.push({
                        id:lot.justUId,
                        label:letters[index],
                        angle:angle,
                        coords: getRecBounds(startPoint, 90+angle, w, l),
                        strokeColor: "#B7EB8F",
                        strokeOpacity: 1.0,
                        strokeWeight: 1,
                        fillColor: "#F6FFED",
                        fillOpacity: 1.0,
                        onClick:()=>setSelectedZone(lot.justUId)
                    })
                    startPoint = getNextCoordinate(startPoint, 360+angle, span)
                })
            }
            setPolygons(polys);
            if(lotsCount === 1){
                setSelectedZone(result!.lots[0].justUId);
            }
        })()
    }, [id])

    const onConfirm = () => {
        if(selectedZone === undefined){
            failToast(<Text value={"select one spot."} class={"capitalize"}/>);
            return;
        }
        history.push({
            pathname: "/reservation-detail",
            state: {...state, parkType:"street", id:inStreetId, lotId:selectedZone}
        })
    }

    return (
        <GoogleMapProvider>
            <BottomSheetLayout
                header={
                    <SimpleHeader
                        title={"choose spot"}
                        backControl
                    />
                }
                white
                overlap
                boxShadow
                minHeight={"initial"}
                jsx={
                    <div className={"d-flex flex-column w-100 h-100 position-relative"}>
                        <GoogleMap
                            zoom={8}
                            center={{ lat: 39.004262473276306, lng: -112.2705426192734 }}
                            style={{ flex: 1 }}
                        />
                        <Polygon
                            polygons={polygons}
                            zoomExtents
                            clearOnUnLoad
                            onSelectOptions={{strokeWeight:5}}
                            selected={selectedZone}
                        />
                    </div>
                }
            >
                <>
                    {
                        (polygons[0] && polygons[0].id !== "na" ) && <>
                            <p>Which space are you in ?</p>
                            <div className={styles.smallButtons}>
                                {
                                    polygons.map((spot, index)=>{
                                        return <div
                                            key={index}
                                            onClick={()=>setSelectedZone(String(spot.id))}
                                            className={`${spot.id === selectedZone && styles.active}`}
                                        >
                                            <p>{spot.label}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </>
                    }
                    <div className={"w-100 d-flex justify-content-center"}>
                        <CustomButton
                            text={"checkout"}
                            theme={"primary"}
                            onClick={onConfirm}
                        />
                    </div>
                </>
            </BottomSheetLayout>
        </GoogleMapProvider>
    );
};

export default StreetSpots;