import React, {FunctionComponent, useRef, useState} from 'react';
import Input from "@components/common/form/input";
import Icon from "@components/common/icon";
import {Down} from "@components/common/svgIcons";
import BottomSheet from "@components/common/bottomSheet";
import SelectSheet from "@components/bottomSheets/selectSheet";

interface SelectProps {
    id?:string
    label?:string
    options:{key:string, value:string}[]
    default?:any
    placeHolder?:string
    onChange:(id?:string, value?:string)=>void
    className?:string
}

const Select:FunctionComponent<SelectProps> = (props) => {

    const selectRef = useRef<HTMLDivElement>(null);

    const [showList, setShowList] = useState<boolean>(false);
    const [active, setActive] = useState<string>(props.default);

    const onSelect = (key:string) => {
        props.onChange(props.id, key);
        setActive(key);
        setShowList(false);
    }

    return (
        <div
            ref={selectRef}
            className={`position-relative ${props.className && props.className}`}
        >
            <Input
                onChange={()=>{}}
                placeHolder={props.placeHolder}
                label={props.label}
                default={props.options.find(o=>o.key === active?.toLowerCase())?.value}
                icon={{
                    icon:<Icon size={12} color={'black'} icon={<Down/>}/>,
                    action:()=>{setShowList(true)}
                }}
                onClick={()=>{setShowList(true)}}
            />
            {
                showList && <BottomSheet
                    onClose={()=>setShowList(false)}
                    select
                >
                    <SelectSheet
                        onSelect={onSelect}
                        list={props.options}
                        index={"key"}
                        value={"value"}
                    />
                </BottomSheet>
            }
        </div>
    );
};

export default Select;