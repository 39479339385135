import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import Text from "@components/common/text";
import {Clock, Information, Loading, Receipt} from "@components/common/svgIcons";
import Divider from "@components/common/divider";
import IconHeader from "@components/common/iconHeader";
import CustomButton from "@components/common/customButton";
import PriceDetails from "@components/common/priceDetails";
import {bookParking, CreateBooking, finalizeBooking} from "@webservices/parking/booking";
import {failToast} from "@toast/index";
import Icon from "@components/common/icon";
import Vehicles from "@components/app/vehicles";
import TimeSpanGetter from "@components/app/timeSpanGetter";
import Payments from "@components/app/payments";
import {getParking} from "@webservices/parking/Parking";
import {validate} from "@utilities/validator";
import moment from "moment";
import {getStreetParkingById} from "@webservices/parking/inStreet";
import {sessionSchema} from "@schemas/session";
import {CreateInStreetSession, createInStreetSession, extendSessionTime, finalizeSession} from "@webservices/session";
import {bookingSchema} from "@schemas/booking";
import {formatSecondsToTime} from "@utilities/dateUtils";

interface PageStates {
    id?:string
    parkType:"private"|"street"
    end?:string
    duration?:number
    zoneId?:string
    lotId?:string
    extend?: boolean
    lastSessionId?:string
}

interface ParkData {
    id?:string
    start?:string
    end?:string
    zoneId?:string
    lotId?:string
    vehicleId?:string
    plateNumber?:string
    paymentId?:string
}

const ReservationDetail = () => {

    const history = useHistory();
    const { state } = useLocation<PageStates>();
    const [ data, setData] = useState<ParkData>(state);
    const [ loading, setLoading] = useState<boolean>(false);
    const [ cost, setCost] = useState<number>(0);
    const [ spotDetails, setSpotDetails] = useState<{
        name:string
        limitation:string
        tariffs:number
    }>()
    const [ submitDisable, setSubmitDisable] = useState(true);

    /**
     * set parking or on-street information
     */
    useEffect(()=>{
        (async () => {
            // redirect to home page if parking id is not exist
            if(!data.id){
                history.push("/");
                return;
            }
            if(state.parkType === "street") {
                // get on street spot data
                const foundParking = await getStreetParkingById(data.id);
                if(foundParking) setSpotDetails({
                    limitation: foundParking.rulesAndLimitaions,
                    name: "",
                    tariffs: foundParking.pricePerHour
                });
            } else {
                // get on parking data
                const foundParking = await getParking(data.id);
                if(foundParking) setSpotDetails({
                    limitation: foundParking.rolesAndLimitations,
                    name: foundParking.parkingName,
                    tariffs: foundParking.tariffs.pricePerHour
                });
            }

        })()
    }, [])

    /**
     * check that data is valid to submit form
     */
    useEffect(() => {
        try {
            dataValidation();
            setSubmitDisable(false);
        } catch (e) {
            setSubmitDisable(true);
        }
    }, [data]);

    /**
     * register or extend booking or on-street report
     * only works as switch
     */
    const register = async () => {
        if(state.extend){
            await extend();
            return;
        }

        if(state.parkType === "street") {
            await addSession();
        } else {
            await book();
        }
    }

    /**
     * add on-street parking session
     * for test finalizeBooking has been called here
     * for production this function must be change
     */
    const addSession = async () => {
        try {
            const obj = dataValidation();
            setLoading(true);
            const result = await createInStreetSession(obj as CreateInStreetSession, ()=>{
                failToast(<Text value={"Error in Booking Process"} class={"capitalize"}/>);
                setLoading(false);
            });
            setLoading(false);
            await finalizeSession(result!.id, data.paymentId!, "ADF-1254")
            history.push({
                pathname: "/receipt/rcp-456464",
                state:{
                    id:result!.id,
                    price:cost.toPrecision(2),
                    start:obj.reserveFrom,
                    end:obj.reserveTo,
                }
            })
        } catch (e:any){
            failToast(<Text value={e.message} class={"capitalize"}/>);
        }
    }

    /**
     * add private parking booking
     * for test finalizeBooking has been called here
     * for production this function must be change
     */
    const book = async () => {
        try {
            const obj = dataValidation();
            setLoading(true);
            const result = await bookParking(obj as CreateBooking, ()=>{
                failToast(<Text value={"Error in Booking Process"} class={"capitalize"}/>);
                setLoading(false);
            });
            await finalizeBooking(result!.id, data.paymentId!, "ADF-1254")
            history.push({
                pathname: "/receipt/rcp-456464",
                state:{
                    id:result!.id,
                    price:cost.toPrecision(2),
                    start:obj.reserveFrom,
                    end:obj.reserveTo,
                }
            })
        } catch (e:any){
            failToast(<Text value={e.message} class={"capitalize"}/>);
        }
    }

    /**
     * extend session time
     */
    const extend = async () => {
        if(!state.lastSessionId || !state.end) return;
        const newSession = await extendSessionTime(
            state.lastSessionId,
            state.end
        )
        if(newSession){
            await finalizeSession(newSession?.id, data.paymentId!, "RCP-1245");
        }
        setLoading(false);
        history.push("/receipt/rcp-456464");
    }

    /**
     * validate data on change to handle submit button availability
     */
    const dataValidation = ():{[k:string]:any} => {
        // check for vehicle availability
        if(!data.vehicleId) throw new Error("you should have one vehicle at least");
        // check for payment method availability
        if(!data.paymentId) throw new Error("you should have one payment method at least");

        let obj:{[k:string]:any};
        if(state.parkType === "street") {
            obj = {
                userId: localStorage.getItem("sub"),
                inStreetParkingId: state.id,
                justUId: state.lotId,
                reserveFrom: data.start ? moment(data.start).utc().format("YYYY-MM-DD HH:mm:ss") : undefined,
                reserveTo: data.end ? moment(data.end).utc().format("YYYY-MM-DD HH:mm:ss") : undefined,
                vehicleId: data.vehicleId,
                plateNumber: data.plateNumber
            }
            if(!validate(obj, sessionSchema)) {
                throw new Error("invalid inputs");
            }
        } else {
            obj = {
                userId: localStorage.getItem("sub") || "",
                parkingId: data.id,
                parkZoneId: data.zoneId,
                plateNumber: data.plateNumber,
                reserveFrom: data.start ? moment(data.start).utc().format("YYYY-MM-DD HH:mm:ss") : undefined,
                reserveTo: data.end ? moment(data.end).utc().format("YYYY-MM-DD HH:mm:ss") : undefined,
                vehicleId: data.vehicleId
            }
            if(!validate(obj, bookingSchema)) {
                throw new Error("invalid inputs");
            }
        }
        return obj;
    }

    return (
        <SimpleLayout
            header={
                <SimpleHeader
                    backControl
                    title={<Text value={"reservation detail"}/>}
                />
            }
        >
            <div className={"h-100 d-flex flex-column"}>
                <div className={"d-flex flex-column flex-grow-1 overflow-auto"}>
                    {
                        state.extend ? <>
                            <IconHeader
                                icon={<Clock/>}
                                text={"duration details"}
                            />
                            <div className={"d-flex justify-content-between"}>
                                <p><Text value={"reserve more time"} class={"capitalize"}/></p>
                                <p>{formatSecondsToTime(state.duration!*60)}</p>
                            </div>
                            <Divider/>
                        </> : <>
                            <p className={"mb-3"}>Select date and time</p>
                            <TimeSpanGetter
                                defaults={{start:data.start, end:data.end}}
                                onChange={(start, end)=>{
                                    setData(prevState => ({
                                        ...prevState,
                                        start,
                                        end
                                    }));
                                }}
                            />
                            <Divider/>
                        </>
                    }
                    <IconHeader
                        icon={<Information/>}
                        text={"Rules and limitation"}
                    />
                    <p>{spotDetails?.limitation}</p>
                    <Divider/>
                    {
                        !state.extend &&<>
                            <Vehicles
                                id={"vehicleId"}
                                onChange={(id, value, plate)=>{
                                    setData(prevState => ({
                                        ...prevState,
                                        vehicleId:value,
                                        plateNumber:plate
                                    }));
                                }}
                                selected={data.vehicleId}
                            />
                            <Divider/>
                        </>
                    }
                    <Payments
                        id={"paymentId"}
                        onChange={(id, value)=>{
                            setData(prevState => ({...prevState, paymentId:value}));
                        }}
                        selected={data.paymentId}
                    />
                    <Divider/>
                    <IconHeader
                        icon={<Receipt/>}
                        text={"Price details"}
                    />
                    {
                        spotDetails && <PriceDetails
                            tariffs={spotDetails.tariffs}
                            hours={moment(data.end).diff(data.start, "minutes")/60}
                            onChange={(value)=>setCost(value)}
                        />
                    }
                </div>
                <div className={"d-flex justify-content-center pt-3"}>
                    <CustomButton
                        text={`Pay €${cost.toPrecision(2)}`}
                        theme={"primary"}
                        onClick={register}
                        loading={loading}
                        loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                        disabled={submitDisable}
                    />
                </div>
            </div>
        </SimpleLayout>
    );
};

export default ReservationDetail;