import {httpGet} from "@webservices/index";
import {Message} from "@webservices/Message";
import { API_URL } from "config";

export interface Review {
    reviewDescription: string
    rate: number
    id: string
    addTimeStamp: string,
    userId: string
    userName?: string
    profilePic?: string
}

export const getParkingReviews = async (parkingId:string):Promise<Review[]> => {
    const reviews = await httpGet<Message<Review[]>>(
        `${API_URL}reviews/${parkingId}`
    );
    return reviews?.data || [];
}

export const getParkingReviewsWithDetails = async (parkingId:string):Promise<Review[]> => {
    const reviews = await httpGet<Message<Review[]>>(
        `${API_URL}reviews/withDetails/${parkingId}`
    );
    return reviews?.data || [];
}