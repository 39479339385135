import React, {FunctionComponent} from 'react';
import styles from "./parkingSummary.module.scss";
import Icon from "@components/common/icon";
import {Back, Calender, Clock, Euro, LocationPin, ParkSign} from "@components/common/svgIcons";
import Text from "@components/common/text";
import Review from "@components/common/review";
import Chips from "@components/common/chips";
import CustomButton from "@components/common/customButton";
import {useHistory} from "react-router-dom";

interface ParkingSummaryProps {
    id:string
    title:string
    price:number
    booked: number
    rate: number
    reviews: number
    features: string[]
    vehicleSize:string
    distance:number
    next:()=>void
    previous:()=>void
}

const ParkingSummary:FunctionComponent<ParkingSummaryProps> = (props) => {

    const history = useHistory();

    const goToDetails = () => {
        history.push({
            pathname: `/parking-details/${props.id}`,
            state:{
                id:props.id,
                parkingName:props.title
            }
        })
    }

    const book = () => {
        history.push({
            pathname: `/parking-spots/${props.id}`,
            state:{id:props.id}
        })
    }

    return (
        <div className={"d-flex flex-column align-items-center"}>
            <div className={styles.contents}>
                <div className={"d-flex justify-content-between align-items-center mb-3"}>
                    <div
                        className={`${styles.navBtn}`}
                        onClick={props.previous}
                    >
                        <Icon size={22} color={"primary"} icon={<Back/>}/>
                    </div>
                    <h2 className={"text-center"}>{props.title}</h2>
                    <div
                        className={`${styles.navBtn} ${styles.next}`}
                        onClick={props.next}
                    >
                        <Icon size={22} color={"primary"} icon={<Back/>}/>
                    </div>
                </div>
                <div className={"d-flex justify-content-between align-items-center mb-3"}>
                    <div className={"d-flex gap-2 align-items-center"}>
                        <Icon size={20} color={"green"} icon={<Euro/>} />
                        <p><span className={"bold"} style={{fontSize:16}}>{props.price}</span>/hr</p>
                    </div>
                    <div className={"d-flex gap-2 align-items-center"}>
                        <Icon size={20} color={"blue"} icon={<ParkSign/>} />
                        <p><Text value={"private"} class={"capitalize"}/></p>
                    </div>
                </div>
                <div className={"d-flex justify-content-between align-items-center mb-3"}>
                    <div className={"d-flex gap-2 align-items-center"}>
                        <Icon size={20} color={"blue"} icon={<Clock/>} />
                        <p><Text value={"reservable"} class={"capitalize"}/></p>
                    </div>
                    <div className={"d-flex gap-2 align-items-center"}>
                        <Icon size={20} color={"yellow"} icon={<LocationPin/>} />
                        <p>{props.distance} m</p>
                    </div>
                </div>
                <div className={"d-flex justify-content-between align-items-center mb-3"}>
                    <div className={"d-flex gap-2 align-items-center"}>
                        <Icon size={20} color={"blue"} icon={<Calender/>} />
                        <p><Text value={`${props.booked} Bookings`} class={"capitalize"}/></p>
                    </div>
                    <div className={"d-flex align-items-center gap-2"}>
                        <Review rate={props.rate}/>
                        <p>({props.reviews})</p>
                    </div>
                </div>
                <p className={"mb-2"}><Text value={"features"} class={"capitalize"}/></p>
                <div className={"w-100 d-flex justify-content-start gap-2"}>
                    {
                        props.features.map((feature, index)=>{
                            return <div className={"my-2"} key={index}>
                                <Chips title={feature}/>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className={"d-flex w-75 gap-2 mt-4"}>
                <CustomButton
                    text={<Text value={"book"} class={"capitalize"}/>}
                    theme={"primary"}
                    className={'flex-grow-1'}
                    onClick={book}
                />
                <CustomButton
                    text={<Text value={"details"} class={"capitalize"}/>}
                    theme={"white"}
                    onClick={goToDetails}
                />
            </div>
        </div>
    );
};

export default ParkingSummary;