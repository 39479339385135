import { API_URL } from "config";
import {httpDelete, httpGet, httpPatch, httpPost} from "../index";
import {Message} from "../Message";
import {ApiError} from "@types.d/ApiError";

export interface User {
    id: string
    firstName?: string
    lastName?: string
    gender?: "male"|"female"|"none-binary"
    userPhoneNumber?: string
    email?: string
    registrationDate: string
    photo: string
    dateOfBirth: string
    title: string
    automaticLogin: boolean
    userUID?: string
}

interface CreatUser {
    firstName?: string
    lastName?: string
    avatarId?: string
    email?: string
    gender?: "male"|"female"|"none-binary"
    userPhoneNumber?: string
    registrationDate?: string
    photo?: string
    dateOfBirth?: string
    automaticLogin?: boolean
    userUID?: string
    title?: string
}

export const getUserInfo = async (id?:string):Promise<User | undefined> => {
    const userId = id || localStorage.getItem('sub');
    const userInfo = await httpGet<Message<User>>(`${API_URL}users/${userId}`);
    if(userInfo) return userInfo.data;
}

export const getUserInfoByUid = async (userUID?:string, onError?:()=>void):Promise<User | undefined> => {
    const uid = userUID || localStorage.getItem('uid');
    const userInfo = await httpGet<Message<User>>(
        `${API_URL}users/uid/${uid}`,
        {},
        onError
    );
    if(userInfo) return userInfo.data;
}

export const registerUser = async (uid:string, email?:string, cell?:string):Promise<{id:string} | undefined> => {
    const userInfo = await httpPost<
        {userUID:string,userPhoneNumber?:string, email?:string}, Message<{id:string}>
    >(
        `${API_URL}users/registerByUserUID`,
        {
            userUID: uid,
            userPhoneNumber:cell,
            email
        }
    );
    if(userInfo) return userInfo.data;
}

export const updateUserInfo = async (user:CreatUser, onError?:(err:ApiError)=>void):Promise<User | undefined> => {
    const userInfo = await httpPatch<CreatUser, Message<User>>(
        `${API_URL}users/${localStorage.getItem('sub')}`,
        {...user , avatarId:"1"},
        {},
        onError
    );
    if(userInfo) return userInfo.data;
}

export const updateUserAvatar = async (avatar:string):Promise<void> => {
    await httpPatch<{ photo : string }, Message<{}>>(
        `${API_URL}users/updateUserProfilePic/${localStorage.getItem('sub')}`,
        {photo:avatar}
    );
}

export const deleteUser = async (userId?:string):Promise<void> => {
    await httpDelete(
        `${API_URL}users/${userId || localStorage.getItem('sub')}`,
    );
}