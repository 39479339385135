import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "@components/common/form/input/input.module.scss";
import Text from "@components/common/text";
import {trim} from "@utilities/stringUtils";

interface MultiPartInputProps {
    id:string
    label?:string
    count:number
    onChange:(id:string, value:string[])=>void
    default?:string[]
    allowedCharacter?:"number"|"latin"
    maxLength?:number
}

const MultiPartInput:FunctionComponent<MultiPartInputProps> = (props) => {

    const reset =  Array.from(Array(props.count).keys()).map(()=>"");
    const [values, setValues] = useState<string[]>(reset);

    useEffect(()=>{
        if(props.default && props.default[0]){
            setValues(props.default);
        } else {
            setValues(reset);
        }
    }, [props.default])

    const handleChange = (index:number, value:string) => {
        const elements = document.getElementsByClassName(props.id);
        setValues(prevState => {
            let l = [...prevState];
            l[index] = props.allowedCharacter ? trim(props.allowedCharacter, value, props.maxLength) :value;
            props.onChange(props.id, l);
            return l;
        })
        //jump to previous elm on empty value
        if(value === ""){
            index-1 >= 0 && (elements[index-1] as HTMLElement).focus();
        }
        // jump next
        if(index+1 < props.count && value!=="" && value.length >= (props.maxLength || 50)){
            (elements[index+1] as HTMLElement).focus();
        }
    }

    return (
        <div className={styles.container}>
            {
                props.label && <label>
                    <Text value={props.label} class={"capitalize"}/>
                </label>
            }
            <div className={"d-flex justify-content-between"}>
                {
                    values.map((value, index)=>{
                        return <input
                            className={props.id}
                            onChange={(e)=>handleChange(index, e.target.value)}
                            type={props.allowedCharacter || "text"}
                            maxLength={props.maxLength}
                            key={index}
                            style={{textAlign:"center", width:`${90/props.count}%`}}
                            value={value}
                        />
                    })
                }
            </div>
        </div>
    );
};

export default MultiPartInput;