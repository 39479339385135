import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./priceDetail.module.scss";
import Text from "@components/common/text";
import Divider from "@components/common/divider";
import { TAX } from "config";

interface PriceDetailsProps {
    tariffs: number
    hours?:number
    onChange:(cost:number)=>void
}

const PriceDetails:FunctionComponent<PriceDetailsProps> = (props) => {

    const [costs, setCosts] = useState<{
        net:number
        tax:number
        total:number
    }>({net:0, tax:0, total:0})

    useEffect(()=>{
        const net = (props.hours || 0) * props.tariffs;
        const tax =  net * (TAX || 0) / 100;
        setCosts({net, tax, total:net + tax})
    }, [props.hours])

    useEffect(()=>{
        props.onChange(costs.total);
    }, [costs])

    return (
        <ul className={styles.container}>
            <li>
                <p><Text value={"price"}/></p>
                <p>€{props.tariffs} / Hr</p>
            </li>
            <li>
                <p><Text value={"duration"}/></p>
                <p>{props.hours ? `${props.hours.toPrecision(1)} hrs` : "no time"}</p>
            </li>
            <li>
                <p><Text value={"tax"}/></p>
                <p>€{costs.tax.toPrecision(1)}</p>
            </li>
            <li>
                <Divider gap={0.25}/>
            </li>
            <li>
                <p><Text value={"Total price"}/></p>
                <p>€{costs.total.toPrecision(2)}</p>
            </li>
        </ul>
    );
};

export default PriceDetails;