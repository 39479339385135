import React from 'react';
import noInvitation from "@assets/images/no-invitation.png"
import Text from "@components/common/text";
import CustomButton from "@components/common/customButton";
import {useHistory} from "react-router-dom";

const History = () => {

    const history = useHistory();

    return (
        <div className={"d-flex flex-column h-100 justify-content-center align-items-center"}>
            <img src={noInvitation} alt={"no invitation"}/>
            <h2 className={"my-3"}><Text value={"no invitations found here"} class={"capitalize"}/></h2>
            <CustomButton
                text={<Text value={"invite friends"} class={"capitalize"}/>}
                theme={"white"}
                onClick={()=>history.push("/account/invite-friends?tab=invite")}
            />
        </div>
    );
};

export default History;