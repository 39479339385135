import {httpGet} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL} from "../../../config";


export const getParkingPictures = async (id:string):Promise<string[]> => {
    const reviews = await httpGet<Message<{pictures:string[]}>>(
        `${API_URL}parkingPictures/${id}`
    );
    return reviews?.data?.pictures || [];
}