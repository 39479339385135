import React, {useEffect, useState} from 'react';

interface Output {
    width:number
    isMobile:boolean
}

const useWidthChange = ():Output => {

    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    useEffect(() => {

        function handleChangeWidth() {
            setIsMobile(window.innerWidth <= 768)
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleChangeWidth);
        return () => {
            window.removeEventListener("resize", handleChangeWidth);
        };

    }, []);

    return {width, isMobile}

};

export default useWidthChange;