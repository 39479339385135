import React, {FunctionComponent} from 'react';
import Text from "@components/common/text";
import styles from "./switch.module.scss";

interface RadioProps {
    id?:string
    label?:string
    default?:any
    onChange:(id?:string, value?:any)=>void
    className?:string
}

const Switch:FunctionComponent<RadioProps> = (props) => {
    return (
        <div className={`${styles.container} ${props.className && props.className}`}>
            {
                props.label && <label className={styles.mainLabel}>
                    <Text value={props.label} class={"capitalize"}/>
                </label>
            }
            <div
                className={`${styles.items} ${props.default && styles.active}`}
                onClick={()=>props.onChange(props.id, !props.default)}
            >
                <span/>
            </div>
        </div>
    );
};

    export default Switch;