import React, {FunctionComponent} from 'react';
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";

interface ConfirmModalProps {
    title:string
    confirmText?:string
    onConfirm:()=>void
    onCancel:()=>void
    inProgress?:boolean
}

const ConfirmModal:FunctionComponent<ConfirmModalProps> = (props) => {
    return (
        <div>
            <p className={"text-center mb-3"}>{<Text value={props.title}/>}</p>
            <div className={"d-flex justify-content-center gap-2"}>
                <CustomButton
                    text={<Text value={props.confirmText ? props.confirmText : "yes, remove"} class={"capitalize"}/>}
                    theme={"red"}
                    onClick={props.onConfirm}
                    loadingIcon={<Icon size={18} color={"white"} icon={<Loading/>}/>}
                    loading={props.inProgress}
                />
                <CustomButton
                    text={<Text value={"cancel"} class={"capitalize"}/>}
                    theme={"white"}
                    onClick={props.onCancel}
                />
            </div>
        </div>
    );
};

export default ConfirmModal;