import CustomButton from '@components/common/customButton';
import Divider from '@components/common/divider';
import Icon from '@components/common/icon';
import {Calender, Loading} from '@components/common/svgIcons';
import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./reserved.module.scss";
import BottomSheet from '@components/common/bottomSheet';
import {BookedSession, bookedSessions} from "@webservices/parking/booking";
import moment from "moment/moment";
import noReserved from "@assets/images/no-reserved.png"
import Text from "@components/common/text";
import {useHistory} from "react-router-dom";
import ParkingDetails from "@components/bottomSheets/parkingDetails";
import {getParking, Parking} from "@webservices/parking/Parking";

interface ReservedProps {

}

const Reserved: FunctionComponent<ReservedProps> = (props) => {

    const [showParkingDetailsBT, setShowParkingDetailsBT] = useState<boolean>(false);
    const [bookings, setBookings] = useState<BookedSession[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [infoLoading, setInfoLoading] = useState<boolean>(false);
    const [selectId, setSelectId] = useState<string>();
    const [parkingInfo, setParkingInfo] = useState<Parking>()

    const history = useHistory();

    useEffect(()=>{
        (async ()=>{
            setLoading(true);
            setBookings(await bookedSessions(()=>{setLoading(false)}));
            setLoading(false);
        })()
    }, [])

    const showInfo = async (id:string) => {
        setSelectId(id);
        setInfoLoading(true);
        const parking = await getParking(id);
        setParkingInfo(parking);
        setShowParkingDetailsBT(true);
        setInfoLoading(false);
    }

    // @ts-ignore
    return (
        <>
            {
                !bookings[0] ? <div className={"w-100 h-100 d-flex flex-column justify-content-center gap-4 align-items-center"}>
                    <img src={noReserved} alt={"no-reserved"}/>
                    {
                        loading ? <Icon size={20} color={"primary"} icon={<Loading/>} />
                            : <p><Text value={"no parking reservation has been made yet."} class={"capitalize"}/></p>
                    }
                    <CustomButton
                        theme={"white"}
                        text={"reserve parking"}
                        className={"mt-4"}
                        onClick={()=>{history.push("/")}}
                    />
                </div>: <>
                    {
                        bookings.map((booking, index)=>{
                            return <div className={styles.card} key={index}>
                                <h2>{booking.parkingName}</h2>
                                <Divider />
                                <div className={styles.row}>
                                    <Icon size={15} color={'primary'} icon={<Calender />} />
                                    <p><span>date: </span>{moment(booking.sessionAddTimeStamp).format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>
                                <div className={styles.row}>
                                    <Icon size={15} color={'primary'} icon={<Calender />} />
                                    <p><span>time: </span>{`from ${moment(booking.reserveFrom).format("HH:mm A")} to ${moment(booking.reserveTo).format("HH:mm A")}`}</p>
                                </div>
                                <div className={styles.row}>
                                    <Icon size={15} color={'primary'} icon={<Calender />} />
                                    <p><span>price: </span>{`€${booking.parkingPricePerHour} per hours`}</p>
                                </div>
                                <div className={styles.row}>
                                    <Icon size={15} color={'primary'} icon={<Calender />} />
                                    <p><span>duration: </span>{`${moment(booking.reserveTo).diff(booking.reserveFrom, "hours")} Hrs`}</p>
                                </div>
                                <Divider />
                                <div className={"w-100 d-flex justify-content-center"}>
                                    <CustomButton
                                        text={"details"}
                                        theme={"white"}
                                        onClick={()=>showInfo(booking.parkingId)}
                                        loading={selectId === booking.parkingId ? infoLoading : false}
                                        loadingIcon={<Icon size={20} color={"black"} icon={<Loading/>}/>}
                                    />
                                </div>
                            </div>
                        })
                    }
                </>
            }
            {
                (showParkingDetailsBT && selectId) && <BottomSheet
                    onClose={() => {
                        setSelectId(undefined);
                        setShowParkingDetailsBT(false);
                    }}
                    fullWidth
                >
                    <ParkingDetails parking={parkingInfo} />
                </BottomSheet>
            }
        </>
    );
};

export default Reserved;
