import CustomButton from "@components/common/customButton"
import Icon from "@components/common/icon"
import {Clock, Loading} from "@components/common/svgIcons"
import Text from "@components/common/text"
import TimeBlock from "@components/common/timeBlock"
import React, {FunctionComponent, useState} from "react"
import moment from "moment";
import {useHistory} from "react-router-dom";

interface ExtendTimeProps {
    dropDownBT?: (value: boolean) => void
    onCancel:()=>void
    parkingId?:string|number|null
    sessionId?:string
    pricePerHour?:number
    endTime?:string
    sessionType?:"Parking"|"Street"
}

const ExtendTime: FunctionComponent<ExtendTimeProps> = (props) => {

    const history = useHistory();

    const [extendedTime, setExtendedTime] = useState<number>(0);

    return (
        <div className={"d-flex flex-column align-items-center"}>
            <div className={"w-100"}>
                <div className={"d-flex align-items-center gap-2 mb-3"}>
                    <Icon size={15} color={"primary"} icon={<Clock/>} />
                    <p className={"my-2"}><Text value={"select duration"} /></p>
                </div>
                <TimeBlock
                    blocks={[
                        { title: "30m", value: 30 },
                        { title: "1h", value: 60 },
                        { title: "2hrs", value: 120 },
                        { title: "3hrs", value: 180 },
                        { title: "4hrs", value: 240 },
                        { title: "5hrs", value: 300 },
                        { title: "6hrs", value: 360 },
                        { title: "7hrs", value: 420 },
                        { title: "8hrs", value: 480 },
                        { title: "9hrs", value: 540 },
                    ]}
                    onSelect={(value:number) => {setExtendedTime(value)}}
                    startTime={"now"}
                />
            </div>
            <div className={"d-flex justify-content-center gap-2 mt-3 w-75"}>
                <CustomButton
                    text={`Pay/€${extendedTime/60 * Number(props.pricePerHour)}`}
                    theme="primary"
                    onClick={()=>history.push({
                        pathname:"/reservation-detail",
                        state:{
                            id:props.parkingId,
                            parkType:props.sessionType === "Street" ? "street" : "private",
                            end:moment(props.endTime).add(
                                extendedTime,
                                "minutes"
                            ).utc().format("YYYY-MM-DD HH:mm:ss"),
                            duration:extendedTime,
                            extend:true,
                            lastSessionId:props.sessionId
                        }
                    })}
                    loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                    fullWidth
                />
                <CustomButton
                    text={"Cancel"}
                    onClick={props.onCancel}
                    theme={"white"}
                    fullWidth
                />
            </div>
        </div>
    )
}

export default ExtendTime