import {Gmail, Skype, Telegram, Whatsapp } from "@components/common/svgIcons";
import React, {FunctionComponent, useLayoutEffect, useState} from "react";
import styles from "./share.module.scss";
import BottomSheet from "@components/common/bottomSheet";

interface ShareProps {
    subject?:string
    data:string
    close:()=>void
}

const Share: FunctionComponent<ShareProps> = (props) => {

    const [showShareBT, setShowShareBT] = useState<boolean>(false);

    useLayoutEffect(()=>{
        (async () => {
            try {
                if (navigator.share) {
                    await navigator.share({
                        text: props.data,
                    });
                    props.close();
                } else {
                    setShowShareBT(true);
                }
            } catch (error) {}
        })()
    }, [])

    const mail = async () => {
        document.location.href = "mailto:?subject="
            + encodeURIComponent("message")
            + "&body=" + encodeURIComponent(props.data);
    }

    const sendTelegram = () => {
        const telegramBaseUrl = 'https://t.me/share/';
        const encodedText = encodeURIComponent(props.data);
        const telegramShareUrl = `${telegramBaseUrl}?text=${encodedText}`;
        window.open(telegramShareUrl, '_blank');
    }

    const sendWhatsApp = () => {
        const encodedText = encodeURIComponent(props.data);
        const whatsappShareLink = `https://wa.me/?text=${encodedText}`;
        window.open(whatsappShareLink, '_blank');
    }

    return (
        <>
            {
                showShareBT && <BottomSheet
                    onClose={() => setShowShareBT(false)}
                >
                    <div className={styles.wrapper}>
                        <div
                            className={styles.items}
                            onClick={mail}
                        >
                            <Gmail/>
                            <p>gmail</p>
                        </div>
                        <div
                            className={styles.items}
                            onClick={sendWhatsApp}
                        >
                            <Whatsapp/>
                            <p>whatsapp</p>
                        </div>
                        <div
                            className={styles.items}
                            onClick={sendTelegram}
                        >
                            <Telegram/>
                            <p>telegram</p>
                        </div>
                        <div className={styles.items}>
                            <Skype/>
                            <p>SMS</p>
                        </div>
                    </div>
                </BottomSheet>
            }
        </>
    )
}

export default Share