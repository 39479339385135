import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./promotions.module.scss";
import {getNearByPromotions, Promotion} from "@webservices/parking/promotion";
import noPromotions from "@assets/images/no-promotions.png";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";

interface PromotionsProps {
    parkingId: string
}

const Promotions: FunctionComponent<PromotionsProps> = (props) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [promotions, setPromotions] = useState<Promotion[]>([]);

    useEffect(()=>{
        (async ()=>{
            setLoading(true);
            const res = await getNearByPromotions(props.parkingId);
            setPromotions(res);
            setLoading(false);
        })()
    }, [props.parkingId])

    return (
        <>
            {
                loading || !promotions[0] ? <div className={"d-flex flex-column flex-grow-1 justify-content-center align-items-center"}>
                    <img src={noPromotions} alt={"noReview"}/>
                    <div className={"mt-4"}>
                        {
                            loading ? <Icon size={20} color={"primary"} icon={<Loading/>} />
                                : <p>There is no promotion for this parking.</p>
                        }
                    </div>
                </div> : <div className={"d-flex flex-column gap-3"}>
                    <p>You can use these store to get discount</p>
                    {
                        promotions.map((promotion, index) => {
                            return <a href={promotion.websiteURL} target={"_blank"}>
                                <div className={styles.wrapper}>
                                    <div className={styles.photo}>
                                        <img src={promotion.picture} alt={"promotion"}/>
                                    </div>
                                    <div className={styles.caption}>
                                        <div className={"d-flex gap-2 flex-column"}>
                                            <h2>{promotion.promoTitle}</h2>
                                            <p>{promotion.promoDetails}</p>
                                            <p>Code: {promotion.discountCode}</p>
                                        </div>
                                        <button>See Store</button>
                                    </div>
                                </div>
                            </a>
                        })
                    }
                </div>
            }
        </>
    );
};

export default Promotions;