import React, {useEffect, useState} from 'react';
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import MultiPartInput from "@components/common/form/input/multiPartInput";
import Timer from "@components/common/timer";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";
import {
    getAuth,
    PhoneAuthProvider,
    RecaptchaVerifier,
    signInWithCredential,
    signInWithPhoneNumber
} from "firebase/auth";
import numberImage from "@assets/images/otp2.png";
import BottomSheetLayout from "@components/layouts/contentLayouts/bottomSheetLayout";
import {failToast} from "@toast/index";
import {useHistory} from "react-router-dom";
import {handleFirebaseError} from "../../../services/firebaseErrorHandler";
import {onUserLoggedIn} from "../../../services/app/userServices";

const Verify = () => {

    const auth = getAuth();

    const history = useHistory();

    const [code, setCode] = useState<string[]>([]);
    const [cell, setCell] = useState<string>();
    const [verificationId, setVerificationId] = useState<string>();
    const [showResend, setShowResend] = useState<boolean>(false);
    const [verifyLoading, setVerifyLoading] = useState<boolean>(false);

    useEffect(()=>{
        const states = history.location.state as {[key:string]:any};
        if(!states?.id || !states?.cell) {
            history.push("/auth/login");
            return;
        }
        window.recaptchaVerifier = new RecaptchaVerifier(
            'sign-in-button',
            {
                'size': 'invisible',
            },
            auth
        );
        setVerificationId(states.id)
        setCell(states.cell)
    }, [])

    const verify = async () => {
        if(!code || code.length < 6) {
            failToast(<Text value={"please enter the code to verify your account."} class={"capitalize"}/>);
            setCode([]);
            return;
        }
        setVerifyLoading(true);
        try{
            const credential = await PhoneAuthProvider.credential(verificationId! ,code.join(""));
            const result = await signInWithCredential(auth, credential);
            const convertedResult = result as {[key:string]:any};
            await onUserLoggedIn({
                token: convertedResult.user.accessToken,
                refreshToken: convertedResult._tokenResponse.refreshToken,
                uid: convertedResult.user.uid,
                phoneNumber: cell
            });
        } catch (e) {
            setVerifyLoading(false);
            setCode([]);
            handleFirebaseError(e);
        }
    }

    const sendCode = async () => {
        try {
            setCode([]);
            const result = await signInWithPhoneNumber(auth, cell!, window.recaptchaVerifier);
            setVerificationId(result.verificationId);
        } catch (e) {
            history.push("/auth/login");
            handleFirebaseError(e);
        }
    }

    return (
        <BottomSheetLayout
            sideAlign={"end"}
            image={numberImage}
        >
            <div className={"d-flex flex-column align-items-center justify-content-between h-100"}>
                <h1 className={"w-100 text-left mb-3"}><Text value={"verify your account"} class={"capitalize"}/></h1>
                <p className={"mb-3"}><Text value={"enter the verification code that was sent to the"} class={"capitalize"}/>
                    <span className={"bold"}> {cell}</span>
                </p>
                <div className={"d-flex flex-column"}>
                    <MultiPartInput
                        id={"verification-code"}
                        count={6}
                        onChange={(id, value)=>setCode(value)}
                        allowedCharacter={"number"}
                        maxLength={1}
                        default={code}
                    />
                    <div className={"w-100 d-flex align-items-center justify-content-end"} style={{height:38}} >
                        {
                            !showResend ? <Timer seconds={120} onExpired={()=>setShowResend(true)}/> :
                                <CustomButton
                                    text={<Text value={"resend"} class={"capitalize"}/>}
                                    textHOverColor={"blue"}
                                    theme={"no-border"}
                                    onClick={()=>{
                                        setShowResend(false);
                                        sendCode();
                                    }}
                                    className={"p-0 text-decoration-underline"}
                                />
                        }
                    </div>
                </div>
                <CustomButton
                    id={"sign-in-button"}
                    theme={"primary"}
                    text={<Text value={"verify"} class={"capitalize"}/>}
                    onClick={verify}
                    loading={verifyLoading}
                    loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                />
            </div>
        </BottomSheetLayout>
    );
};

export default Verify;