import React, {FunctionComponent, useState} from 'react';
import styles from "./timeBlock.module.scss";

interface TimeBlockProps {
    blocks:{title:string, value:number}[]
    classname?:string
    onSelect:(value:number)=>void
    startTime:string
}

const TimeBlock:FunctionComponent<TimeBlockProps> = (props) => {

    const [activeBlock, setActiveBlock] = useState<number>();

    return (
        <div className={`${props.classname && props.classname}`}>
            <div className={styles.container}>
                <ul className={styles.blocks}>
                    {
                        props.blocks.map((block, index)=>{
                            return <li
                                className={activeBlock === index ? styles.active : ""}
                                key={index}
                                onClick={()=>{
                                    setActiveBlock(index);
                                    props.onSelect(props.blocks[index].value)
                                }}
                            >
                                {block.title}
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>
    );
};

export default TimeBlock;