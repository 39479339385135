if (process.env.REACT_APP_ENV === "local") {
    module.exports.API_URL = "https://us-central1-justu-977b2.cloudfunctions.net/api/1.0/";
    module.exports.HOME_URL = "http://localhost:4500/";
    module.exports.STATICS_URL = "";
    module.exports.TAX = 10;
}
if(process.env.REACT_APP_ENV === "dev") {
    module.exports.API_URL = "https://us-central1-justu-977b2.cloudfunctions.net/api/1.0/";
    module.exports.HOME_URL = "";
    module.exports.STATICS_URL = "";
    module.exports.TAX = 10;
}
if(process.env.REACT_APP_ENV === "stage") {
    module.exports.API_URL = "https://us-central1-stage-justu.cloudfunctions.net/api/1.0/";
    module.exports.HOME_URL = "";
    module.exports.STATICS_URL = "";
    module.exports.TAX = 10;
}
if(process.env.REACT_APP_ENV === "main") {
    module.exports.API_URL = "not-deployed-yet";
    module.exports.HOME_URL = "";
    module.exports.STATICS_URL = "";
    module.exports.TAX = 10;
}
