import React, {useEffect, useState} from 'react';
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import Text from "@components/common/text";
import TimeSpan from "@components/common/timeSpan";
import IconHeader from "@components/common/iconHeader";
import {Download, QrCode, Share as ShareIcon} from "@components/common/svgIcons";
import Divider from "@components/common/divider";
import CustomButton from "@components/common/customButton";
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import ReceiptDetails from "@components/common/receiptDetails";
import Icon from "@components/common/icon";
import NoImage from "@assets/images/noImage.svg"
import {useHistory, useLocation, useParams} from "react-router-dom";
import QRCode from "qrcode";
import Share from "@components/bottomSheets/share";
import {downloadFile} from "@utilities/fileUtils";

const Receipt = () => {

    let { id } = useParams<{id: string}>();
    const [qr, setQr] = useState<string>("");
    const history = useHistory();

    const { state } = useLocation<any>();

    const [showShareBT, setShowShareBT] = useState<boolean>(false);

    useEffect(()=>{
        (async () => {
            setQr(await QRCode.toDataURL(id, {margin:0}));
        })()
    },[])


    return (
        <SimpleLayout
            header={
                <SimpleHeader
                    back={"/"}
                    title={<Text value={"receipt"}/>}
                />
            }
        >
            <TimeSpan
                start={state?.start}
                end={state?.end}
            />
            <div className={"d-flex justify-content-center mt-3"}>
                <CustomButton
                    text={<Text value={"go to session"} class={"capitalize"}/>}
                    theme={"primary"}
                    onClick={()=>history.push("/session")}
                />
            </div>
            <Divider/>
            <IconHeader
                icon={<QrCode/>}
                text={"receipt details"}
                className={"bold"}
            />
            <ReceiptDetails
                id={state?.id}
                totalPrice={state?.price}
            />
            <Divider/>
            <div className={"d-flex gap-4 w-100 justify-content-center"}>
                <div className={"d-flex flex-column gap-3"}>
                    <CustomButton
                        text={"share"}
                        icon={<Icon size={15} color={"primary"} icon={<ShareIcon/>}/>}
                        onClick={()=>setShowShareBT(true)}
                        fullWidth
                        theme={"white"}
                    />
                    <CustomButton
                        text={"download"}
                        icon={<Icon size={15} color={"primary"} icon={<Download/>}/>}
                        fullWidth
                        theme={"white"}
                        onClick={()=>downloadFile(qr, `receipt-${id}.png`)}
                    />
                </div>
                <img style={{width:92, height:92}} src={qr || NoImage} alt={"qr"}/>
            </div>
            {
                showShareBT && <Share
                    close={() => setShowShareBT(false)}
                    data='121345465464'
                />
            }
        </SimpleLayout>
    );
};

export default Receipt;