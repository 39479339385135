import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./reviews.module.scss";
import Review from '@components/common/review';
import {getParkingReviewsWithDetails, Review as ReviewInterface} from '@webservices/parking/review';
import NoImage from "@assets/images/noImage.svg"
import moment from "moment";
import noReview from "@assets/images/no-review.png"
import {Loading} from "@components/common/svgIcons";
import Icon from "@components/common/icon";

interface ReviewsProps {
    parkingId: string
}

const Reviews: FunctionComponent<ReviewsProps> = (props) => {

    const [reviews, setReviews] = useState<ReviewInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(()=>{
        (async () => {
            setLoading(true);
            const res = await getParkingReviewsWithDetails(props.parkingId);
            setReviews(res);
            setLoading(false);
        })()
    }, [])

    return (
        <div>
            {
                loading || !reviews[0] ? <div className={"d-flex flex-column flex-grow-1 justify-content-center align-items-center"}>
                    <img src={noReview} alt={"noReview"}/>
                    <div className={"mt-4"}>
                        {
                            loading ? <Icon size={20} color={"primary"} icon={<Loading/>} />
                                : <p>There is no review for this parking.</p>
                        }
                    </div>
                </div> : <>
                    {
                    reviews.map((review, index)=>{
                        return <div key={index} className={styles.wrapper}>
                            <div className={styles.details}>
                                <img src={review.profilePic || NoImage} alt={"avatar"}/>
                                <div className={"d-flex w-100 flex-column"}>
                                    <div className={styles.name}>
                                        <p>{review.userName || "no name"}</p>
                                        <p>{moment(review.addTimeStamp).format("DD/MM/YYYY")}</p>
                                    </div>
                                    <Review rate={review.rate}/>
                                </div>
                            </div>
                            <p className={"mt-2"}>{review.reviewDescription}</p>
                        </div>
                    })
                }
                </>
            }
        </div>
    );
};

export default Reviews;