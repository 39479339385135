import React, {useEffect, useState} from 'react';
import {getAuth, createUserWithEmailAndPassword, confirmPasswordReset } from "firebase/auth";
import BottomSheetLayout from "@components/layouts/contentLayouts/bottomSheetLayout";
import CustomButton from "@components/common/customButton";
import Icon from "@components/common/icon";
import {Eye, EyeClosed, Loading} from "@components/common/svgIcons";
import passwordImage from "@assets/images/password.png";
import Input from "@components/common/form/input";
import Text from "@components/common/text";
import {useHistory} from "react-router-dom";
import {parseQueryString} from "@utilities/pathParser";
import {handleFirebaseError} from "../../../services/firebaseErrorHandler";
import {failToast} from "@toast/index";
import {onUserLoggedIn} from "../../../services/app/userServices";

const SetPassword = () => {

    const auth = getAuth();
    const history = useHistory();
    const query = parseQueryString(history.location.search);

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [rePassword, setRePassword] = useState<string>();
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRePassword, setShowRePassword] = useState<boolean>(false);

    useEffect(()=>{
        const states = history.location.state as {[key:string]:any};
        if(states?.email) setEmail(states.email)
    }, [])

    const setPasswordHandler = async () => {
        if(query.mode === "resetPassword") {
            await confirmPasswordReset(auth, query.oobCode, password!);
            return;
        }
        if(!email) {
            history.push("/auth/login");
            return;
        }
        if(!password || password !== rePassword){
            failToast(<Text value={"the passwords you entered are not the same."} class={"capitalize"}/>)
            return;
        }
        try {
            setRegisterLoading(true);
            const userCredential = await createUserWithEmailAndPassword(auth, email, password!);
            const convertedResult = userCredential as {[key:string]:any};
            await onUserLoggedIn({
                token: convertedResult.user.accessToken,
                refreshToken: convertedResult._tokenResponse.refreshToken,
                uid: convertedResult.user.uid,
                email
            });
            window.location.replace("/");
        } catch (error) {
            handleFirebaseError(error);
            setRegisterLoading(false);
        }
    }

    return (
        <BottomSheetLayout
            sideAlign={"end"}
            image={passwordImage}
        >
            <h1 className={"mb-3 text-capitalize"}><Text value={"set password"}/></h1>
            <Input
                label={"enter password"}
                placeHolder={"enter password"}
                type={showPassword ? "text" : "password"}
                onChange={(id, value)=>{setPassword(value)}}
                icon={{
                    icon:<Icon size={15} color={"primary"} icon={!showPassword ? <EyeClosed/> : <Eye/>}/>,
                    action:()=>{setShowPassword(prevState => !prevState)}
                }}
                className={"mb-3"}
            />
            <Input
                label={"repeat password"}
                placeHolder={"repeat password"}
                type={showRePassword ? "text" : "password"}
                onChange={(id, value)=>{setRePassword(value)}}
                icon={{
                    icon:<Icon size={15} color={"primary"} icon={!showRePassword ? <EyeClosed/> : <Eye/>}/>,
                    action:()=>{setShowRePassword(prevState => !prevState)}
                }}
            />
            <div className={"d-flex w-100 justify-content-center mt-4 mb-2"}>
                <CustomButton
                    theme={"primary"}
                    text={<Text value={"finnish"} class={"capitalize"}/>}
                    onClick={setPasswordHandler}
                    loading={registerLoading}
                    loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                />
            </div>
        </BottomSheetLayout>
    );
};

export default SetPassword;