import React, {FunctionComponent, useRef, useState} from 'react';
import styles from "./accordion.module.scss";
import Icon from "@components/common/icon";
import {Down} from "@components/common/svgIcons";

interface AccordionProps{
    children?: React.ReactNode
    height:string
    className?:string
    default?:"open"|"close"
    borderRadius?:number
    showArrow?:boolean
}

const Accordion:FunctionComponent<AccordionProps> = (props) => {

    const [open, setOpen] = useState<boolean>(props.default === "open");
    const childrenRef = useRef<HTMLDivElement>(null)

    return (
        <div className={"position-relative"}>
            <div
                className={`${styles.container} ${props.className}`}
                style={{
                    height:open ? childrenRef.current?.getBoundingClientRect().height : props.height,
                    borderRadius:props.borderRadius||"initial"
                }}
                onClick={()=>setOpen(prevState => !prevState)}
            >
                <div ref={childrenRef}>{props.children}</div>
            </div>
            {
                props.showArrow && <div
                    className={`${styles.handle} ${open && styles.openHandle}`}
                    onClick={()=>setOpen(prevState => !prevState)}
                >
                    <Icon size={20} color={"primary"} icon={<Down/>}/>
                </div>
            }
        </div>
    );
};

export default Accordion;