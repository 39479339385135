import React, {FunctionComponent, useContext} from 'react';
import Text from "@components/common/text";
import styles from "./input.module.scss";
import {LanguageContext} from "../../../../contexts/languageContext";

interface InputProps {
    id?:string
    default?:any
    label?:string
    placeHolder?:string
    onChange:(id?:string, value?:string)=>void
    onClick?:()=>void
    icon?:{
        icon:JSX.Element
        action:()=>void
    }
    className?:string
    rows?:number
}

const TextArea:FunctionComponent<InputProps> = (props) => {

    const {translate} = useContext(LanguageContext)

    return (
        <div
            className={`${styles.container} ${props.className && props.className}`}
            onClick={props.onClick}
            id={String(props.id)}
        >
            {
                props.label && <label>
                    <Text value={props.label} class={"capitalize"}/>
                </label>
            }
            <textarea
                value={props.default}
                onChange={(e)=>{props.onChange(props.id, e.target.value)}}
                placeholder={props.placeHolder && translate(props.placeHolder?.toLowerCase())}
                rows={props.rows || 2}
            />
            {
                props.icon && <div
                    className={styles.icon}
                    onClick={()=>{props.icon!.action()}}
                >
                    {props.icon.icon}
                </div>
            }
        </div>
    );
};

export default TextArea;