import Html5QrcodePlugin from '@components/common/qrScanner';
import React, {FunctionComponent} from 'react';

interface ScanQRProps {
    onConfirm:(code:string)=>void
    onCancel:(err?:string)=>void
}

const ScanQR: FunctionComponent<ScanQRProps> = (props) => {

    const handleError = (err:string) => {
        props.onCancel(err);
    }

    return (
        <div>
            <Html5QrcodePlugin
                onScan={(txt) => {
                    props.onConfirm(txt)
                }}
                onError={handleError}
            />
        </div>
    );
};

export default ScanQR;
