import React, {useEffect, useState} from 'react';
import Text from "@components/common/text";
import QRCode from 'qrcode';
import Input from "@components/common/form/input";
import CopyClipboard from "@components/common/copyClipboard";

const Invite = () => {

    const [QR, setQR] = useState<string>("");

    useEffect(()=>{
        generateQR().catch();
    }, [])

    const generateQR = async () => {
        try {
            setQR(await QRCode.toDataURL(localStorage.getItem("sub")||""));
        } catch (err) {
            console.error(err);
            setQR("")
        }
    }

    return (
        <div>
            <p className={"mb-2"}>
                <Text value={"You can earn coin by inviting your friends to join."}/>
            </p>
            <p className={"mb-5"}>
                <Text value={"To reduce the amount of carbon emissions, they can easily find a spot to park or report an empty space if they see one."}/>
            </p>
            <div className={"d-flex flex-column align-items-center"}>
                <p className={"bold"}><Text value={"scan QR code"}/></p>
                <img style={{width:"70%"}} src={QR} alt={"QR-Code"}/>
            </div>
            <div className={"d-flex w-100 justify-content-center align-items-center gap-2 mb-4"}>
                <hr className={"flex-grow-1"}/>
                <p><Text value={"Or invite them via link below "}/></p>
                <hr className={"flex-grow-1"}/>
            </div>
            <p className={"mb-3"}><Text value={"Copy link to invite"}/></p>
            <div className={"d-flex gap-3"}>
                <Input
                    onChange={()=>{}}
                    default={`https://www.justu.com/refer/${localStorage.getItem("sub")}`}
                    lock
                    className={"flex-grow-1"}
                />
                <CopyClipboard
                    text={`https://www.justu.com/refer/${localStorage.getItem("sub")}`}
                    showIcon
                    showText={<Text value={"copy"} class={"capitalize"}/>}
                />
            </div>
        </div>
    );
};

export default Invite;