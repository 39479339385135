import React from 'react';
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import Text from "@components/common/text";

const Help = () => {

    return (
        <SimpleLayout
            header={<SimpleHeader
                title={<Text value={"privacy policies"} class={"capitalize"}/>}
                backControl
            />}
        >
            <p>privacy policy comes here</p>
        </SimpleLayout>
    );
};

export default Help;