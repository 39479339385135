import React, {FunctionComponent, useRef} from 'react';
import Text from "@components/common/text";
import styles from "./input.module.scss";
import Icon from "@components/common/icon";
import {Add} from "@components/common/svgIcons";

interface InputProps {
    id?:string
    default?:any
    label?:string
    placeHolder?:string
    onChange:(id?:string, value?:string)=>void
    onClick?:()=>void
    icon?:{
        icon:JSX.Element
        action:()=>void
    }
    className?:string
    content?:JSX.Element
}

const Upload:FunctionComponent<InputProps> = (props) => {

    const input = useRef<HTMLInputElement>(null);

    const convertFileToBase64 = (file:File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if(reader.result) {
                props.onChange(props.id, String(reader.result));
            }

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleUpload = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files) return;
        const file = e.target.files[0];
        if (file) {
            convertFileToBase64(file);
        }
    }

    return (
        <div
            className={`${styles.container} ${props.className && props.className}`}
            onClick={props.onClick}
            id={String(props.id)}
        >
            {
                props.label && <label>
                    <Text value={props.label} class={"capitalize"}/>
                </label>
            }
            <input
                type={"file"}
                value={props.default}
                onChange={handleUpload}
                ref={input}
                hidden={true}
            />
            <div
                onClick={()=>{input.current && input.current.click()}}
                style={{cursor: "pointer"}}
            >
                {
                    props.content || <>
                        <button
                            className="bold"
                        >
                            <Icon size={20} color={"dark-gray"} icon={<Add/>}/>
                        </button>
                        {
                            props.icon && <div
                                className={styles.icon}
                                onClick={()=>{props.icon!.action()}}
                            >
                                {props.icon.icon}
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default Upload;