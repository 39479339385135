import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Input from "@components/common/form/input";
import BottomSheet from "@components/common/bottomSheet";
import SetTime from "@components/bottomSheets/setTime";
import Icon from "@components/common/icon";
import {Calender} from "@components/common/svgIcons";

interface TimeSpanGetterProps {
    dropDownBT?:(value:boolean)=>void
    onChange:(start?:string, end?:string)=>void
    defaults?: {start?:string, end?:string}
}

const TimeSpanGetter:FunctionComponent<TimeSpanGetterProps> = (props) => {

    const timeId = useRef<"start"|"end"|null>(null);
    const [showTimeBT, setShowTimeBT] = useState<boolean>(false);
    const [span, setSpan] = useState<{
        start?:string
        end?:string
    }>(props.defaults || {})

    useEffect(()=>{
        props.onChange(span.start, span.end);
    }, [span])

    return (
        <div className={"d-flex gap-2 w-100"}>
            <Input
                id={"start"}
                label={"From"}
                default={span.start}
                onChange={()=>{}}
                placeHolder={"Select date & time"}
                icon={{
                    icon: <Icon size={25} color={"dark-gray"} icon={<Calender/>}/>,
                    action:()=>{}
                }}
                onClick={()=>{
                    timeId.current = "start";
                    setShowTimeBT(true);
                    props.dropDownBT && props.dropDownBT(true);
                }}
                className={"flex-grow-1"}
            />
            <Input
                id={"end"}
                label={"To"}
                default={span.end}
                onChange={()=>{}}
                placeHolder={"Select date & time"}
                icon={{
                    icon: <Icon size={25} color={"dark-gray"} icon={<Calender/>}/>,
                    action:()=>{}
                }}
                onClick={()=>{
                    timeId.current = "end";
                    setShowTimeBT(true);
                    props.dropDownBT && props.dropDownBT(true);
                }}
                className={"flex-grow-1"}
            />
            {
                (timeId.current && showTimeBT) && <BottomSheet
                    onClose={()=>{
                        setShowTimeBT(false);
                        props.dropDownBT && props.dropDownBT(false);
                    }}
                >
                    <SetTime
                        id={timeId.current}
                        format={"YYYY-MM-DD HH:mm"}
                        default={props.defaults && props.defaults[timeId.current]}
                        minValue={timeId.current === "end" ? props.defaults?.start : undefined}
                        onCancel={()=>{
                            setShowTimeBT(false);
                            props.dropDownBT && props.dropDownBT(false);
                        }}
                        onSave={(id, value)=>{
                            setShowTimeBT(false);
                            props.dropDownBT && props.dropDownBT(false);
                            setSpan(prevState => {
                                return {...prevState, [id]:value}
                            })
                        }}
                        pickerType={"dateTime"}
                    />
                </BottomSheet>
            }
        </div>
    );
};

export default TimeSpanGetter;