import React from 'react';
import styles from "./favorites.module.scss";

const FavoritesOnLoad = () => {
    return (
        <div>
            <div
                className={`${styles.favorite} ${styles.onload} gradient`}
            >
                <div className={"d-flex flex-grow-1 flex-column"}>
                    <h2/>
                </div>
            </div>
        </div>
    );
};

export default FavoritesOnLoad;