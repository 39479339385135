import {httpGet, httpPatch, httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL} from "../../../config";

export interface Booking {
    id: string
    addTimeStamp: {
        _seconds: number
        _nanoseconds: number
    },
    userId: string
    parkingId: string
    parkZoneId: string
    plateNumber: string
    paymentId: string
    receiptId: string
    status: number
    vehicleId: string
}

export interface CreateBooking {
    userId: string
    parkingId: string
    parkZoneId: string
    plateNumber: string
    vehicleId: string
    reserveFrom: string
    reserveTo: string
}

export interface BookedSession {
    parkingId: string
    parkingCode: string
    parkingName: string
    reserveFrom: string
    reserveTo: string
    parkingPricePerHour: string
    sessionAddTimeStamp: string
}

export const bookParking = async (params:CreateBooking, onError?:()=>void):Promise<Booking | undefined> => {
    const booking = await httpPost<CreateBooking, Message<Booking>>(
        `${API_URL}bookings`,
        params,
        {},
        onError
    )
    return booking?.data
}

export const finalizeBooking = async (bookingId:string, paymentId: string, receiptId: string):Promise<void> => {
    await httpPatch<{paymentId: string, receiptId: string}, Message<any>>(
        `${API_URL}bookings/finalize/${bookingId}`,
        {paymentId, receiptId}
    )
}

export const getUserBookings = async ():Promise<Booking[]> => {
    const result = await httpGet<Message<Booking[]>>(`${API_URL}bookings/user/${localStorage.getItem("sub")}`);
    return result?.data || [];
}

export const bookedSessions = async (onError?:()=>void):Promise<BookedSession[]> => {
    const result = await httpGet<Message<BookedSession[]>>(
        `${API_URL}bookedSessions/${localStorage.getItem("sub")}`,
        {},
        onError
    );
    return result?.data || [];
}