import React, {FunctionComponent, useRef} from 'react';
import DatePicker from "@components/common/form/datePicker";
import Text from "@components/common/text";
import CustomButton from "@components/common/customButton";
import moment from "moment";

interface SetTimeProps {
    id:string
    format?:string
    default?:string
    minValue?:string
    onCancel:()=>void
    onSave:(id:string, value:string)=>void
    pickerType:"date"|"dateTime"|"time"
}

const SetTime:FunctionComponent<SetTimeProps> = (props) => {

    const time = useRef<string>(props.default || moment().format("YYYY-MM-DD HH:m:s"));

    return (
        <div className={"d-flex flex-column align-items-center h-100"}>
            <DatePicker
                value={time.current}
                onChange={(value)=>{time.current = String(value)}}
                format={props.format}
                pickerType={props.pickerType}
                minValue={props.minValue}
            />
            <div className={"d-flex w-75 mt-3 gap-2 justify-content-center"}>
                <CustomButton
                    theme={"primary"}
                    text={<Text value={"confirm"} class={"capitalize"}/>}
                    onClick={()=>{props.onSave(props.id, time.current)}}
                    fullWidth
                />
                <CustomButton
                    theme={"primary-outline"}
                    text={<Text value={"cancel"} class={"capitalize"}/>}
                    onClick={props.onCancel}
                    fullWidth
                />
            </div>
        </div>
    );
};

export default SetTime;