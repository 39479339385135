import React, {useEffect, useState} from 'react';
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import BottomSheet from "@components/common/bottomSheet";
import styles from "./manageVehicle.module.scss";
import Icon from "@components/common/icon";
import {Add, Trash} from "@components/common/svgIcons";
import AddVehicle from "@components/bottomSheets/addVehicle";
import {deleteVehicle, getVehicles, Vehicle} from "@webservices/account/vehicle";
import CustomButton from "@components/common/customButton";
import {successToast} from "@toast/index";
import Text from "@components/common/text";
import ConfirmModal from "@components/modals/confirmModal";
import ManageVehicleOnLoad from "@pages/account/manageVehicles/manageVehicleOnLoad";
import noVehicleImg from "@assets/images/no-vehicle.png"
import pointerArrow from "@assets/icons/pointer-arrow.svg"

const ManageVehicles = () => {

    const [showAddEditBT, setShowAddEditBt] = useState<boolean>(false);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [removeLoading, setRemoveLoading] = useState<boolean>(false);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [selected, setSelected] = useState<number>();

    useEffect(()=>{
        loadVehicles().catch();
    }, [])

    const loadVehicles = async () => {
        setLoading(true);
        const userVehicles = await getVehicles();
        userVehicles && setVehicles(userVehicles);
        setLoading(false);
    }

    const oneSelect = (index:number) => {
        setShowAddEditBt(true);
        setSelected(index);
    }

    const onDeleteVehicle = async () => {
        if(selected === undefined) return;
        setRemoveLoading(true);
        await deleteVehicle(vehicles[selected].id);
        successToast(<Text value={"vehicle removed successfully"} class={"capitalize"}/>);
        setSelected(undefined);
        loadVehicles().catch();
        setRemoveLoading(false);
    }

    const NoVehicle = ():JSX.Element => {
        return <div className={"w-100 h-100 d-flex flex-column align-items-center justify-content-center position-relative"}>
            <img src={noVehicleImg} alt={"image"} className={"w-50"}/>
            <p className={"mt-3 font-1"}><Text value={"no vehicle available here"} class={"capitalize"}/></p>
            <div className={styles.pointerArrow}>
                <p><Text value={"add from here"} class={"capitalize"}/></p>
                <img src={pointerArrow} alt={"pointer arrow"}/>
            </div>

        </div>
    }

    return (
        <SimpleLayout
            header={<SimpleHeader
                title={<Text value={"manage vehicles"} class={"capitalize"}/>}
                backControl
            />}
        >
            <div className={"d-flex flex-column gap-3 h-100"}>
                {
                    loading ? <ManageVehicleOnLoad/>
                        : vehicles[0] ? vehicles.map((v, index)=>{
                            return <div
                                className={styles.vehicleRow}
                                key={index}
                                onClick={()=>oneSelect(index)}
                            >
                                <div>
                                    <h2>{v.vehicleName}</h2>
                                    <p>{v.plateNumber}</p>
                                </div>
                                <CustomButton
                                    theme={"no-border"}
                                    icon={<Icon size={20} color={"red"} icon={<Trash/>}/>}
                                    onClick={()=>{
                                        setSelected(index);
                                        setShowRemoveConfirm(true);
                                    }}
                                    className={"p-0"}
                                />

                            </div>
                        }) : <NoVehicle/>
                }
            </div>
            <div
                className={styles.addBtn}
                onClick={()=>{
                    setShowAddEditBt(true);
                    setSelected(undefined);
                }}
            >
                <Icon size={25} color={"white"} icon={<Add/>}/>
            </div>
            {
                showAddEditBT && <BottomSheet
                    onClose={()=>setShowAddEditBt(false)}
                    fullWidth
                >
                    <AddVehicle
                        vehicle={selected !== undefined ? vehicles[selected] : undefined}
                        onClose={()=>{
                            setShowAddEditBt(false);
                            loadVehicles().catch();
                            setSelected(undefined);

                        }}
                    />
                </BottomSheet>
            }
            {
                showRemoveConfirm && <BottomSheet onClose={()=>setShowRemoveConfirm(false)}>
                    <ConfirmModal
                        title={"do you want to remove your vehicle?"}
                        onConfirm={async () => {
                            await onDeleteVehicle();
                            setShowRemoveConfirm(false);
                        }}
                        onCancel={()=>{
                            setShowRemoveConfirm(false);
                            setSelected(undefined)
                        }}
                        inProgress={removeLoading}
                    />
                </BottomSheet>
            }
        </SimpleLayout>
    );
};

export default ManageVehicles;