import React from 'react';
import styles from "./loadings.module.scss";

const RadioLoading = () => {
    return (
        <div className={"d-flex gap-2"}>
            <div className={styles.circle}/>
            <div className={`${styles.text} gradient`} style={{width:40}}/>
        </div>
    );
};

export default RadioLoading;