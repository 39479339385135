import React, { FunctionComponent } from 'react';
import styles from "./workingHours.module.scss";
import {Parking} from "@webservices/parking/Parking";
import Text from "@components/common/text";

interface WorkingHoursProps {
    days: Parking["openings"]
}

const WorkingHours: FunctionComponent<WorkingHoursProps> = (props) => {

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] as const;

    return (
        <div>
            <div className={styles.rows}>
                <p>day of a week</p>
                <p>hours</p>
            </div>
            {
                days.map((day, index) => {
                    return <div key={index} className={styles.rows}>
                        <p>{day}</p>
                        <div className={"d-flex flex-column flex-grow-1"}>
                            {
                                props.days[day].length > 0 ? props.days[day].map((hours, index)=>{
                                    return <p>{`${hours?.from||""} - ${hours?.to||""}`}</p>
                                }) : <p><Text value={"closed"} class={"capitalize"}/></p>
                            }
                        </div>
                    </div>
                })
            }
        </div>
    );
};

export default WorkingHours;
