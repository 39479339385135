import React, {FunctionComponent} from 'react';
import Icon from "@components/common/icon";
import Text from "@components/common/text";

interface IconHeaderProps {
    icon:JSX.Element
    text:string
    className?:string
}

const IconHeader:FunctionComponent<IconHeaderProps> = (props) => {
    return (
        <div className={`d-flex align-items-center gap-2 ${props.className ? props.className : ""}`}>
            <Icon size={15} color={"primary"} icon={props.icon}/>
            <p className={"bold my-2"}><Text value={props.text} class={"capitalize"}/></p>
        </div>
    );
};

export default IconHeader;