import React from 'react';
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import Text from "@components/common/text";
import Divider from "@components/common/divider";
import {useHistory} from "react-router-dom";

const Help = () => {

    const history = useHistory();

    return (
        <SimpleLayout
            header={<SimpleHeader
                title={<Text value={"help"} class={"capitalize"}/>}
                backControl
            />}
        >
            <div>
                <p className={"text-capitalize"}><Text value={"support"}/></p>
            </div>
            <Divider/>
            <div onClick={()=>history.push("/account/help/feedback")}>
                <p className={"text-capitalize"}><Text value={"feedback"}/></p>
            </div>
            <Divider/>
            <div onClick={()=>history.push("/account/help/terms-conditions")}>
                <p className={"text-capitalize"}><Text value={"terms and conditions"}/></p>
            </div>
            <Divider/>
            <div onClick={()=>history.push("/account/help/policies")}>
                <p className={"text-capitalize"}><Text value={"privacy policies"}/></p>
            </div>
        </SimpleLayout>
    );
};

export default Help;