import React, {useState} from 'react';
import BottomSheetLayout from "@components/layouts/contentLayouts/bottomSheetLayout";
import CustomButton from "@components/common/customButton";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import numberImage from "@assets/images/otp.png";
import Text from "@components/common/text";
import Input from "@components/common/form/input";
import Joi from "joi";
import {failToast, successToast} from "@toast/index";
import {handleFirebaseError} from "../../../services/firebaseErrorHandler";

const ResetPassword = () => {

    const auth = getAuth();

    const [email, setEmail] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const sendLink = async () => {
        try{
            setLoading(true);
            const emailValidator = Joi.string().email({tlds:{allow: false}}).required().validate(email);
            if(emailValidator.error !== undefined) {
                failToast(<Text value={"invalid email address"}/>);
                setLoading(false);
                return;
            }
            await sendPasswordResetEmail(auth, email!);
            setLoading(false);
            successToast(<Text value={"reset link was sent to yor email"}/>);
        } catch (error:any) {
            handleFirebaseError(error);
            setLoading(false);
        }
    }

    return (
        <BottomSheetLayout
            sideAlign={"end"}
            image={numberImage}
        >
            <div className={"w-100 d-flex flex-column"}>
                <h1 className={"mb-3 text-capitalize"}><Text value={"reset password"}/></h1>
                <Input
                    label={"enter your email to get a link"}
                    placeHolder={"please enter your email"}
                    onChange={(id, value)=>setEmail(value)}
                    className={"mb-3"}
                />
                <div className={"d-flex w-100 justify-content-center mt-4 mb-2"}>
                    <CustomButton
                        theme={"primary"}
                        text={<Text value={"send me a link"} class={"capitalize"}/>}
                        onClick={sendLink}
                        loading={loading}
                        loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                    />
                </div>
            </div>
        </BottomSheetLayout>
    );
};

export default ResetPassword;