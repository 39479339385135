import React from 'react';
import styles from "./onBoard.module.scss";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import slide1 from "@assets/images/slide1.png"
import slide2 from "@assets/images/slide2.png"
import slide3 from "@assets/images/slide3.png"
import slide4 from "@assets/images/slide4.png"
import {useHistory} from "react-router-dom";
import BottomSheetLayout from "@components/layouts/contentLayouts/bottomSheetLayout";
import HorizontalSlideShow from "@components/basic/horizontalSlideShow";

const OnBoard = () => {

    const slides = [
        {
            title:"find a parking",
            text:"on-board-s1",
            image:slide1
        },
        {
            title:"share spot with others",
            text:"on-board-s2",
            image:slide2
        },
        {
            title:"make your tree grows",
            text:"on-board-s3",
            image:slide3
        },
        {
            title:"report an empty place",
            text:"on-board-s4",
            image:slide4
        }
    ]

    const history = useHistory();

    const createSlide = (slide:any, index:number) => {
        return(
            <BottomSheetLayout
                image={slide.image}
                header={<></>}
            >
                <div className={styles.bullets}>
                    {
                        slides.map((slide, i) => {
                            return <span className={i === index ? styles.activeBullet : ""} key={i}/>
                        })
                    }
                </div>
                <div className={"d-flex flex-column justify-content-between mt-4 flex-grow-1"}>
                    <div className={"d-flex flex-column align-items-center"}>
                        <h2 className={"mb-3 text-capitalize"}>
                            <Text value={slide.title} class={"capitalize"}/>
                        </h2>
                        <p className={"text-center"}><Text value={slide.text}/></p>
                    </div>
                    {
                        index === slides.length-1 &&  <div className={"d-flex justify-content-center align-items-center flex-column gap-3 mt-5"}>
                            <CustomButton
                                theme={"primary"}
                                text={<Text value={"get started"} class={"capitalize"}/>}
                                onClick={()=>{
                                    history.push('/');
                                    localStorage.setItem("onBoard", "1")
                                }}
                            />
                            <p
                                className={"mt-4"}
                                style={{textDecoration:"underline"}}
                                onClick={()=>{
                                    history.push('/auth/login');
                                    localStorage.setItem("onBoard", "1")
                                }}
                            >
                                <Text value={"create an account"} class={"capitalize"}/>
                            </p>
                        </div>
                    }
                </div>
            </BottomSheetLayout>
        )
    }

    return (
        <HorizontalSlideShow
            slides={slides.map((slide, index)=>(createSlide(slide, index)))}
        />
    );
};

export default OnBoard;