import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import styles from "./homeControls.module.scss";
import Icon from "@components/common/icon";
import {
    Filter,
    GreenParking,
    Information,
    Loading,
    RedParking,
    Search,
    YellowParking
} from "@components/common/svgIcons";
import BottomSheet from "@components/common/bottomSheet";
import Text from "@components/common/text";
import InParking from '@components/bottomSheets/inParking';
import AddressSearch from "@components/common/map/googleMap/search";
import CustomButton from "@components/common/customButton";
import CurrentLocation from "@components/common/map/googleMap/controls/currentLocation";
import ParkingFilter from "@components/bottomSheets/parkingFilter";
import Chips from "@components/common/chips";
import useOutsideClick from "@hooks/useOutsideClick";
import {useHistory} from "react-router-dom";
import Modal from "@components/common/modal";

interface HomeControlsProps {
    foundSpotsCount?:number
    onLoad?:boolean
    inParkingCode?:number|string
    onFilterChanged:(value:{
        position?:{lat:number, lng:number}
        start?:string
        end?:string
        vehicleId?:string
        paymentId?:string
    })=>void
}

const HomeControls:FunctionComponent<HomeControlsProps> = (props) => {

    const [showSelectBS, setShowSelectBT] = useState<boolean>(false);
    const [showBookingBT, setShowBookingBT] = useState<boolean>(false);
    const [showFilterBT, setShowFilterBT] = useState<boolean>(false);
    const [bookingBTDown, setBookingBTDown] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [filters, setFilters] = useState<any[]>([]);
    const [searchOn, setSearchOn] = useState<boolean>(false);
    const history = useHistory();

    const search = useRef<HTMLDivElement>(null);

    useOutsideClick(search, ()=>{setSearchOn(false)});

    useEffect(()=>{
        props.inParkingCode !== undefined && showBookingBottomSheet();
    }, [props.inParkingCode])

    const showBookingBottomSheet = () => {
        if(localStorage.getItem("sub")) {
            setShowBookingBT(true);
        } else {
            history.push("/auth/login")
        }
    }

    return (
        <>
            <div className={styles.container}>
                <div
                    className={`${styles.searchIcon} ${styles.button} ${searchOn && styles.searchOn}`}
                    onClick={()=>{setSearchOn(true)}}
                >
                    {
                        !searchOn ? <Icon size={20} color={"primary"} icon={<Search/>}/>
                            : <div
                                className={styles.searchBar}
                                ref={search}
                            >
                                <div className={"w-100 d-flex gap-2 align-items-center"}>
                                    <AddressSearch
                                        goToLocation={true}
                                        onSelect={()=>{}}
                                    />
                                    <CustomButton
                                        icon={<Icon size={24} color={"primary"} icon={<Filter/>}/>}
                                        theme={"no-border"}
                                        className={"p-0"}
                                        onClick={()=>setShowFilterBT(true)}
                                    />
                                </div>
                                {
                                    filters[0] && <div>
                                        {
                                            filters.map(f=><Chips  title={f}/>)
                                        }
                                    </div>
                                }
                            </div>
                    }
                </div>
                {
                    <div className={`${styles.info} ${searchOn && styles.searchOnInfo}`}>
                        {
                            props.onLoad ? <Icon size={20} color={"mid-gray"} icon={<Loading/>}/>
                                : <>
                                    <p>{props.foundSpotsCount !== 0 ? `${props.foundSpotsCount} Active Parking` :  "No Parkign"}</p>
                                </>
                        }
                    </div>
                }
                <div className={styles.pos}>
                    <CurrentLocation type={"control"}/>
                </div>
                <div
                    className={`${styles.infoIcon} ${styles.button}`}
                    onClick={()=>setShowSelectBT(true)}
                >
                    <Icon size={20} color={"primary"} icon={<Information/>}/>
                </div>
            </div>
            {
                showSelectBS && <BottomSheet onClose={()=>{setShowSelectBT(false)}}>
                    <ul>
                        <li className={"d-flex py-2 align-items-center"}>
                            <Icon size={30} color={"none"} icon={<GreenParking/>}/>
                            <span className={"mx-2"} />
                            <p><Text value={"have space"} class={"capitalize"}/></p>
                        </li>
                        <li className={"d-flex py-2 align-items-center"}>
                            <Icon size={30} color={"none"} icon={<YellowParking/>}/>
                            <span className={"mx-2"} />
                            <p><Text value={"plenty space"} class={"capitalize"}/></p>
                        </li>
                        <li className={"d-flex py-2 align-items-center"}>
                            <Icon size={30} color={"none"} icon={<RedParking/>}/>
                            <span className={"mx-2"} />
                            <p><Text value={"full"} class={"capitalize"}/></p>
                        </li>
                    </ul>
                </BottomSheet>
            }
            {
                showBookingBT && <BottomSheet
                    onClose={() => setShowBookingBT(false)}
                    dropDown={bookingBTDown}
                >
                    <InParking
                        code={props.inParkingCode}
                        dropDownBT={setBookingBTDown}
                    />
                </BottomSheet>
            }
            {
                showFilterBT && <BottomSheet
                    onClose={() => setShowFilterBT(false)}
                >
                    <ParkingFilter onApply={()=>{}} onCancel={()=>{}}/>
                </BottomSheet>
            }
            {
                showModal && <Modal
                    onClose={() => setShowModal(false)}
                    align={"center"}
                >
                    <div className={"d-flex flex-column align-items-center p-3"}>
                        <Icon size={30} color={"black"} icon={<Loading/>}/>
                        <p className={"mt-4 mb-2 bold"}>Be patient</p>
                        <p>We're checking if the spot is available.</p>
                    </div>
                </Modal>
            }
        </>
    );
};

export default HomeControls;