import {httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL} from "../../../config";
import {GeoJson} from "@webservices/parking/inStreet";

export interface FindParkingOptions {
    locationCoordinates: {
        latitude: number
        longitude: number
    }
    fromDate?: string
    toDate?: string
    distanceH?: number
    distanceV?: number
    verticalGridCount?: number
    horizontalGridCount?: number
}

export interface ParkingPreview {
    parkingId: string
    bookingCount: number
    distance: number
    features: string[]
    parkingCoordinates:{
        latitude: number
        longitude: number
    }
    parkingName: string
    reviewInfo: {rateCount: number, rateMean: number}
    tariffs: {pricePerHour: number}
}

export interface InStreetParkingPreview {
    centerLatitude:number
    centerLongitude:number
    distance: number
    estimateNumberLots:number
    geoJson:GeoJson
    isFree:boolean
    isHalfAnHourFree:boolean
    opening:string
    parkingCode:number
    parkingLocationName:number
    parkingType:string
    pricePerHour:number
    rulesAndLimitaions:number
}

export interface ParkingCountPreview {
    id: string
    zoom: string
    city: string
    parkingType: string
    data: {
        latitude: number
        longitude: number
        count: number
    }[]
}

export const findParkingLots = async (options:FindParkingOptions):Promise<ParkingPreview[]> => {
    const result = await httpPost<FindParkingOptions, Message<ParkingPreview[]>>(
        `${API_URL}parkingLocator`,
        options,
        {},
        undefined,
        true,
        [{method:"POST", url:`${API_URL}parkingsGridCountBasedOnGrohash`}]
    )
    return result?.data || []
}

export const findInStreetParkingLots = async (options:FindParkingOptions):Promise<InStreetParkingPreview[]> => {
    const result = await httpPost<FindParkingOptions, Message<InStreetParkingPreview[]>>(
        `${API_URL}inStreetParkingLocator`,
        options,
        {},
        undefined,
        true,
        [{method:"POST", url:`${API_URL}parkingsGridCountBasedOnGrohash`}]
    )
    return result?.data || []
}

export const getParkingCount = async (zoom:1|2):Promise<ParkingCountPreview["data"]> => {
    const result = await httpPost<any, Message<ParkingCountPreview[]>>(
        `${API_URL}getParkingOveralls`,
        {
            city: "Helsinki",
            parkingType: "parkings",
            zoom: `zoom${zoom}x`
        },
        {},
        undefined,
        true,
        [
            {method:"POST", url:`${API_URL}inStreetParkingLocator`},
            {method:"POST", url:`${API_URL}parkingLocator`},
        ]
    )
    if(result?.data && result.data[0]){
        return result.data[0].data;
    } else {
        return [];
    }
}
