import {httpGet, httpPatch, httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL} from "../../../config";

export interface Session {
    id: string
    addTimeStamp: string
    userId: string
    sessionType: "Street"|"Parking"
    bookingId: string
    inStreetParkingId: string|null
    justUId: number
    parkingId: string|null
    parkZoonId: string|null
    reserveFrom: string
    reserveTo: string
    duration: number
    vehicleId:string
    plateNumber: string
    paymentId: string
    receiptId: string
    activeSession: boolean
    leavingNow: string|null,
    leaveIn10Min: string|null,
    sessionStatus: number,
    extendTimes: number
    extendFrom: string|null
}

export interface CreateInStreetSession {
    userId: string
    inStreetParkingId: string
    justUId: number
    reserveFrom: string
    reserveTo: string
    vehicleId: string
    plateNumber: string
}

export interface SessionHistory {
    sessionId: string
    sessionType: "Parking"|"Street"
    parkingId: string
    parkingCode: string
    parkingName: string
    parkingPricePerHour: number
    sessionAddTimeStamp: string
    extendFrom: string|null,
    extendTo: string|null
}

export const createInStreetSession = async (params:CreateInStreetSession, onError?:()=>void):Promise<Session|undefined> => {
    const session = await httpPost<CreateInStreetSession, Message<Session>>(
        `${API_URL}sessions/inStreet`,
        params,
        {},
        onError
    )
    return session?.data || undefined
}

export const getSessionById = async (id:string):Promise<Session|undefined> => {
    const session = await httpGet<Message<Session>>(`${API_URL}sessions/${id}`);
    return session?.data;
}

export const getSessionsByUserId = async (userId:string):Promise<Session[]> => {
    const session = await httpGet<Message<Session[]>>(`${API_URL}sessions/user/${userId}`);
    return session?.data || [];
}

export const getCurrentSession = async (userId?:string, onError?:()=>void):Promise<Session|undefined> => {
    const session = await httpGet<Message<Session[]>>(
        `${API_URL}sessions/current/${userId || localStorage.getItem("sub")}`,
        {},
        onError ? onError : undefined
    );
    if(session?.data) return session.data[0];
}

export const finalizeSession = async (id:string, paymentId: string, receiptId: string):Promise<void> => {
    await httpPatch<{paymentId: string, receiptId: string}, Message<any>>(
        `${API_URL}sessions/finalize/${id}`,
        {paymentId, receiptId}
    );
}

export const leavingNow = async (id:string):Promise<void> => {
    await httpPatch(
        `${API_URL}sessions/leavingNow/${id}`,
        {}
    );
}

export const cancelSession = async (id:string):Promise<void> => {
    await httpPatch(
        `${API_URL}sessions/cancel/${id}`,
        {}
    );
}

export const leavingIn10Minutes = async (id:string):Promise<Session|undefined> => {
    const session = await httpPatch<{}, Message<Session>>(
        `${API_URL}sessions/leavingIn10Minutes/${id}`,
        {}
    );
    return session?.data
}

export const extendSessionTime = async (id:string, reserveTo:string):Promise<Session|undefined> => {
    const session = await httpPatch<{reserveTo:string}, Message<Session>>(
        `${API_URL}sessions/extend/${id}`,
        {reserveTo}
    );
    return session?.data
}

export const sessionHistory = async (onError?:()=>void):Promise<SessionHistory[]> => {
    const result = await httpGet<Message<SessionHistory[]>>(
        `${API_URL}sessionsHistory/${localStorage.getItem("sub")}`,
        {},
        onError
    );
    return result?.data || [];
}