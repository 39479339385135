import React, {useEffect, useState} from 'react';
import BottomSheetLayout from "@components/layouts/contentLayouts/bottomSheetLayout";
import CustomButton from "@components/common/customButton";
import Icon from "@components/common/icon";
import {Eye, EyeClosed, Loading} from "@components/common/svgIcons";
import {
    getAuth,
    signInWithPhoneNumber,
    RecaptchaVerifier,
    fetchSignInMethodsForEmail, signInWithEmailAndPassword
} from "firebase/auth";
import numberImage from "@assets/images/otp.png";
import Text from "@components/common/text";
import Input from "@components/common/form/input";
import Divider from "@components/common/divider";
import AuthMethods from "@pages/auth/authMethods";
import Joi from "joi";
import {failToast} from "@toast/index";
import {handleFirebaseError} from "../../../services/firebaseErrorHandler";
import {Link, useHistory} from "react-router-dom";
import {onUserLoggedIn} from "../../../services/app/userServices";

const Login = () => {

    const auth = getAuth();
    const history = useHistory();

    const [input, setInput] = useState<string>();
    const [isEmail, setIsEmail] = useState<boolean>(false);
    const [newUser, setNewUser] = useState<boolean>(false);
    const [inUse, setInUse] = useState<boolean>(false);
    const [password, setPassword] = useState<string>();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loginLoading, setLoginLoading] = useState<boolean>(false);

    useEffect(()=>{
        window.recaptchaVerifier = new RecaptchaVerifier(
            'sign-in-button',
            {
                'size': 'invisible',
            },
            auth
        );
    }, [])

    const sendCode = async () => {
        setLoginLoading(true);
        try {
            const result = await signInWithPhoneNumber(auth, `+${Number(input!)}`, window.recaptchaVerifier);
            history.push("/auth/verify", {id:result.verificationId, cell:`+${Number(input!)}`});
        } catch (e) {
            setLoginLoading(false);
            handleFirebaseError(e);
        }
    }

    const handleChange = async (id?:string , value?:string) => {
        setInput(value);
        if(!value) {
            setIsEmail(false);
            return;
        }
        const email = Joi.string().email({tlds:{allow: false}}).required().validate(value);
        if(email.error === undefined){
            const result = await fetchSignInMethodsForEmail(auth, value);
            setIsEmail(result.includes("password"));
            setInUse(result.includes("google.com"))
            setNewUser(!result[0]);
        } else {
            setIsEmail(false);
            setInUse(false)
            setNewUser(false);
        }
    }

    const emailLogin = async () => {
        try{
            setLoginLoading(true);
            const userCredential = await signInWithEmailAndPassword(auth, input!, password!);
            const convertedResult = userCredential as {[key:string]:any};
            await onUserLoggedIn({
                token: convertedResult.user.accessToken,
                refreshToken: convertedResult._tokenResponse.refreshToken,
                uid: convertedResult.user.uid,
                email: input
            });
            window.location.replace("/");
        } catch (error:any) {
            handleFirebaseError(error);
            setLoginLoading(false);
        }
    }

    const handleClick = async () => {
        if(!input || input === ""){
            failToast(<Text value={"you must enter valid phone number or email address"} class={"capitalize"}/>);
            return;
        }
        if(inUse){
            failToast(<Text value={"email address already in use!"} class={"capitalize"}/>);
            return;
        }
        if(newUser){
            history.push('/auth/set-password', {email:input})
            return;
        }
        if(isEmail){
            if(!password){
                failToast(<Text value={"enter your password"} class={"capitalize"}/>);
                return;
            }
            await emailLogin();
            return;
        }
        const cellNumber = Joi.number().required().validate(input);
        if(cellNumber.error === undefined) {
            await sendCode();
        } else {
            if(isNaN(Number(input))) {
                failToast(<Text value={"wrong email address"} class={"capitalize"}/>);
                return;
            }
            failToast(<Text value={"wrong phone number"} class={"capitalize"}/>);
        }
    }

    return (
        <BottomSheetLayout
            sideAlign={"end"}
            image={numberImage}
        >
            <div className={"w-100 d-flex flex-column"}>
                <h1 className={"mb-3 text-capitalize"}><Text value={"register / login"}/></h1>
                <Input
                    label={"Enter phone number or email"}
                    placeHolder={"phone number or email"}
                    onChange={handleChange}
                    className={"mb-3"}
                    hint={"enter country code for phone number"}
                    allowedCharacter={"latin"}
                />
                {
                    isEmail && <>
                        <Input
                            label={"enter your password"}
                            placeHolder={"enter password"}
                            onChange={(id, value)=>setPassword(value)}
                            type={showPassword ? "text" : "password"}
                            icon={{
                                icon:<Icon size={15} color={"primary"} icon={!showPassword ? <EyeClosed/> : <Eye/>}/>,
                                action:()=>{setShowPassword(prevState => !prevState)}
                            }}
                        />
                        <div className={"w-100 d-flex justify-content-end"} >
                            <CustomButton
                                className={"p-0"}
                                theme={"no-border"}
                                text={<Text value={"forgot password?"} class={"capitalize"}/>}
                                onClick={()=>history.push('/auth/reset-password')}
                            />
                        </div>
                    </>
                }
                <div className={"d-flex w-100 justify-content-center mt-4 mb-2"}>
                    <CustomButton
                        id={"sign-in-button"}
                        theme={"primary"}
                        text={<Text value={"continue"} class={"capitalize"}/>}
                        onClick={handleClick}
                        loading={loginLoading}
                        loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                    />
                </div>
                <Divider/>
                <AuthMethods email gmail />
            </div>
            <p className={"text-center mt-4"}><Text value={"terms-conditions"} class={"capitalize"}/>
                <Link to={"/terms-conditions"}>
                    <a className={"bold text-capitalize"}>
                        <Text value={"terms and conditions"}/>
                    </a>
                </Link>
            </p>
        </BottomSheetLayout>
    );
};

export default Login;