import React from 'react';
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import Text from "@components/common/text";

const TermConditions = () => {
    return (
        <SimpleLayout
            header={<SimpleHeader
                title={<Text value={"terms and conditions"} class={"capitalize"} />}
                backControl
            />}
        >
            <p>terms and conditions come here</p>
        </SimpleLayout>
    );
};

export default TermConditions;