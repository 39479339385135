import {Loader} from '@googlemaps/js-api-loader';

const loader = new Loader({
    apiKey: "AIzaSyA04vx8Q549eoYjFShP8QUfWlckLFy133g",
    version:"beta",
    libraries:["marker", "places"],
    mapIds: ["2e1352a07ca83478"]
});

export const mapLoader = ():Promise<typeof google|null> => {
    return new Promise ((resolve)=>{
        loader.load()
            .then((google) => {
                resolve(google);
            })
            .catch();
    })
}