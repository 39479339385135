import React, {FunctionComponent} from 'react';
import styles from "./divider.module.scss";

interface DividerProps {
    direction?:"vertical"|"horizontal"
    gap?:number
}

const Divider:FunctionComponent<DividerProps> = (props) => {
    return (
        <div
            className={props.direction === "vertical" ? styles.vertical : styles.horizontal }
            style={{margin: `${props.gap || 1}rem 0`}}
        />
    );
};

export default Divider;