import {httpGet} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL} from "../../../config";

export interface Parking {
    id: string
    parkingCode: string
    parkingName: string
    parkingAddress: string
    parkingPostcode: string
    parkingLatitude: number
    parkingLongitude: number
    features: string[]
    parkingSpaces: number
    parkingOverview: string
    pictures: string
    parkingType: string
    parkingPinCode: string
    parkingOpeningDate: string
    parkingImportantNotice: string
    rolesAndLimitations: string
    isFreeParking: boolean
    tariffs:{
        pricePerHour: number
        isFreeFirstHalfanHour: boolean
        upToAnHour: number
        upToTwoHours: number
        upToThreeHours: number
        upToFourHours: number
        upTo8Hours: number
        upTo24Hours: number
        upToWeek: number
    }
    openings: {
        Monday:	TimeSpan[]
        Tuesday	: TimeSpan[]
        Wednesday: TimeSpan[]
        Thursday: TimeSpan[]
        Friday:TimeSpan[]
        Saturday: TimeSpan[]
        Sunday: TimeSpan[]
        BlockDays: TimeSpan[]
    }
}

interface TimeSpan {
    from: number
    to: number
}

export const getParking = async (id:string):Promise<Parking|undefined> => {
    const result = await httpGet<Message<Parking>>(
        `${API_URL}parkings/${id}`
    )
    if(result) return result.data
}