import "./parkingLocation.scss";
import React, {FunctionComponent} from "react";
import Icon from "@components/common/icon";
import {Euro} from "@components/common/svgIcons";
import ReactDOMServer from "react-dom/server";

interface ParkingLocationMarkerProps {
    type: number,
    price: number
}

const ParkingLocationMarkerComponent:FunctionComponent<ParkingLocationMarkerProps> = (props) => {
    return(
        <div className={"parkingLocationMarker marker-green"}>
            <Icon size={18} color={"white"} icon={<Euro/>}/>
            <p>{props.price} /hr</p>
            <svg className="triangle-down" height="12" width="12">
                <polygon points="12,0 6,12 0,0"/>
            </svg>
        </div>
    )
}

export const ParkingLocationMarker = (parkInfo: ParkingLocationMarkerProps): HTMLDivElement => {
    const tag = document.createElement('div');
    tag.className = 'price-tag';
    tag.innerHTML = ReactDOMServer.renderToString(<ParkingLocationMarkerComponent{...parkInfo}/>);
    return tag;
};
