export const linearRegression = (x:number[], y:number[]):any => {
    let lr = {a:0, b:0};
    let n = y.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    for (let i = 0; i < y.length; i++) {
        sum_x += x[i];
        sum_y += y[i];
        sum_xy += (x[i]*y[i]);
        sum_xx += (x[i]*x[i]);
        sum_yy += (y[i]*y[i]);
    }

    lr.a = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
    lr.b = (sum_y - lr.a * sum_x)/n;

    return lr;
}

export const getPoint = (a:number, b:number, x:number):number => {
    return (a * x) + b
}