import React, {FunctionComponent, useRef, useState} from 'react';
import styles from "./vc.module.scss";

interface VerticalCarouselProps {
    elementHeight:number
    array:(string|number)[]
    changeSlide:(dir:-1|1)=>void
}

const VerticalCarousel:FunctionComponent<VerticalCarouselProps> = (props) => {

    const sliderRef = useRef<HTMLUListElement>(null);
    const startYPos = -3 * props.elementHeight;

    const [yPos, setYPos] = useState<number>(startYPos);
    const [startClickedY, setStartClickedY] = useState<number>();


    const HandleTouchStart = (e:React.TouchEvent<HTMLDivElement>) => {
        setStartClickedY(e.touches[0].clientY);
    }

    const HandleTouchEnd = (e:React.TouchEvent<HTMLDivElement>) => {
        setYPos(-120);
    }

    const HandleTouchMove = (e:React.TouchEvent<HTMLDivElement>) => {
        if(!startClickedY) return;
        const movementY = startClickedY - e.touches[0].clientY;
        const top = yPos - movementY
        if(-120-top >= 40){
            props.changeSlide(1);
            setYPos(-120);
        } else if(-120-top <= -40) {
            props.changeSlide(-1);
            setYPos(-120);
        } else {
            setYPos(top);
        }
        setStartClickedY(e.touches[0].clientY);
    }

    return (
        <div
            className={styles.container}
            onTouchStart={HandleTouchStart}
            onTouchMove={HandleTouchMove}
            onTouchEnd={HandleTouchEnd}
        >
            <ul ref={sliderRef} style={{top:yPos}}>
                {
                    props.array.map((i, index) => {
                        return <li
                            key={index}
                            className={index === 4 ? styles.active : ""}
                        >
                            <p>{String(i).padStart(2, '0')}</p>
                        </li>
                    })
                }
            </ul>
        </div>
    );

};

export default VerticalCarousel;