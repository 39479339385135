import React, {FunctionComponent, useState} from 'react';
import styles from './tabBar.module.scss';

interface TabBarProps {
    tabs:{title:string|JSX.Element, content:JSX.Element}[]
    contentsNoPadding?:boolean
}

const TabBar:FunctionComponent<TabBarProps> = (props) => {

    const [activeIndex, setActiveIndex] = useState<number>(0);

    return (
        <div className={"d-flex flex-column h-100"}>
            <ul className={styles.titles}>
                {
                    props.tabs.map((tab, index)=>{
                        return <li
                            key={index}
                            className={index === activeIndex ? styles.active : ""}
                            onClick={()=>setActiveIndex(index)}
                        >
                            {tab.title}
                        </li>
                    })
                }
            </ul>
            <div style={{flexGrow: 1, overflowY:"auto", padding:props.contentsNoPadding ? "1.5rem 0" : "1.5rem"}}>
                {props.tabs[activeIndex].content}
            </div>
        </div>
    );
};

export default TabBar;