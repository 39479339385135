import SimpleLayout from '@components/layouts/contentLayouts/simpleLayout';
import SimpleHeader from '@components/layouts/layoutElements/simpleHeader';
import React, { useEffect, useState } from 'react';
import TimeSpan from '@components/common/timeSpan';
import CustomButton from '@components/common/customButton';
import styles from "./parkingDetails.module.scss";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import ParkingDetails from '@components/bottomSheets/parkingDetails';
import {getParking, Parking} from "@webservices/parking/Parking";
import Icon from "@components/common/icon";
import {Heart, HeartOutline, Loading} from "@components/common/svgIcons";
import {
    addFavoritesParking,
    deleteFavoritesParking,
    FavoriteParking,
    getFavoritesParking
} from "@webservices/account/favoriteParking";
import BottomSheet from "@components/common/bottomSheet";
import ConfirmModal from "@components/modals/confirmModal";
import {successToast} from "@toast/index";
import Text from "@components/common/text";

const ParkingDetailsPage = () => {

    let { id } = useParams<{id: string}>();
    const history = useHistory();
    const [parking, setParking] = useState<Parking>();
    const [favoriteId, setFavoriteId] = useState<string|undefined>();
    const [removeLoading, setRemoveLoading] = useState<boolean>(false);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);

    const { state } = useLocation<{
        id:string
        parkingName:string
        start:string
        end:string
        vehicleId:string
        paymentId:string
    }>();

    const getLocalFavorites = ():FavoriteParking[] => {
        let favorites = sessionStorage.getItem("fav-parking");
        if(favorites) {
            return JSON.parse(favorites);
        }
        return [];
    }

    useEffect(()=>{
        (async () => {
            const foundParking = await getParking(id);
            if(foundParking) setParking(foundParking);
        })();
        (async () => {
            if(localStorage.getItem("sub")){
                const favorite = getLocalFavorites().find(f=>f.parkingId === state.id);
                if(favorite){
                    setFavoriteId(favorite.id);
                } else {
                    const res = await getFavoritesParking();
                    if(!res || !res[0]) return;
                    const founded = res.find((f:FavoriteParking)=>f.parkingId === state.id);
                    if(founded) setFavoriteId(founded.id);
                    sessionStorage.setItem("fav-parking", JSON.stringify(res))
                }
            }
        })();
    }, [id])

    const addToFavorites = async () => {
        const res = await addFavoritesParking(state.id);
        if(res) {
            setFavoriteId(res.id);
            successToast(<Text value={"the parking has been added successfully to your favorites."} class={"capitalize"}/>);
            const favParks = getLocalFavorites();
            sessionStorage.setItem("fav-parking", JSON.stringify([...favParks, res]));
        }
    }

    const removeFromFavorites = async () => {
        setRemoveLoading(true);
        favoriteId && await deleteFavoritesParking(favoriteId);
        setRemoveLoading(false);
        const favParks = getLocalFavorites();
        const founded = favParks.findIndex((fp:FavoriteParking)=>fp.parkingId === state.id);
        if(founded !== -1) favParks.splice(founded, 1);
        sessionStorage.setItem("fav-parking", JSON.stringify(favParks));
        setFavoriteId(undefined);
        setShowRemoveConfirm(false);
    }

    return (
        <SimpleLayout
            header={
                <SimpleHeader
                    backControl
                    title={state?.parkingName}
                    extra={<CustomButton
                        theme={"no-border"}
                        icon={<Icon color={"red"} size={25} icon={!favoriteId ? <HeartOutline/> : <Heart/>}/>}
                        className={"p-0"}
                        onClick={!favoriteId ? addToFavorites : ()=>setShowRemoveConfirm(true)}
                    />}
                />
            }
            noPadding
        >
            <div className={styles.contents}>
                {
                    (state?.start && state?.end) && <div className={'px-4 pt-4'}>
                        <TimeSpan
                            start={state.start}
                            end={state.end}
                        />
                    </div>
                }
                <div className={"flex-grow-1"} style={{height:1}}>
                    {parking ? <ParkingDetails parking={parking}/> :
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <Icon size={25} color={"primary"} icon={<Loading/>}/>
                        </div>}
                </div>
                <div className={styles.actions}>
                    <CustomButton
                        theme={"primary"}
                        text={"book parking"}
                        onClick={()=>history.push({
                            pathname: `/parking-spots/${parking?.id}`,
                            state
                        })}
                    />
                    <CustomButton
                        text={"direction"}
                        theme={"white"}
                        onClick={()=>{history.push({
                            pathname:"/direction",
                            search:`?id=${parking?.id}&target=${parking?.parkingLatitude},${parking?.parkingLongitude}`
                        })}}
                    />
                </div>
            </div>
            {
                showRemoveConfirm && <BottomSheet onClose={()=>setShowRemoveConfirm(false)}>
                    <ConfirmModal
                        title={"do you want to remove this parking from your favorites?"}
                        onConfirm={removeFromFavorites}
                        onCancel={()=>{
                            setShowRemoveConfirm(false);
                        }}
                        inProgress={removeLoading}
                    />
                </BottomSheet>
            }
        </SimpleLayout>
    );
};

export default ParkingDetailsPage;
