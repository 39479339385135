import React, {FunctionComponent, useState} from 'react';
import {GlobalContext} from "../contexts/globalContext";
import {Coordinate} from "@types.d/Coordinate";
import {InStreetParkingPreview, ParkingPreview} from "@webservices/parking/findParking";

const GlobalProvider:FunctionComponent<any> = (props) => {

    const [lastSearch, setLastSearch] = useState<{
        bounds:Coordinate[],
        offStreets:InStreetParkingPreview[],
        onStreets:ParkingPreview[]
    }>()

    return (
        <GlobalContext.Provider value={{
            lastSearch,
            setLastSearch
        }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;