import {httpDelete, httpGet, httpPatch, httpPost} from "../index";
import {API_URL} from "../../../config";
import {Message} from "@webservices/Message";

export interface Payment {
    id:string
    logo:string
    cardNumber: string
    expireDate: string
}

interface CreateOrUpdatePayment {
    cardNumber: string
    expireDate: string
}

export const getPayments = async (userId?:string):Promise<Payment[] | undefined> => {
    const payments = await httpGet<Message<Payment[]>>(`${API_URL}paymentDetails/${userId || localStorage.getItem("sub")}`);
    if(payments) return payments.data;
}

export const createPayment = async (payment:CreateOrUpdatePayment):Promise<Payment | undefined> => {
    const result = await httpPost<CreateOrUpdatePayment, Message<Payment>>(
        `${API_URL}paymentDetails/${localStorage.getItem("sub")}`,
        payment
    );
    if(result) return result.data;
}

export const updatePayment = async (id:string, payment:CreateOrUpdatePayment):Promise<Payment | undefined> => {
    const result = await httpPatch<CreateOrUpdatePayment, Message<Payment>>(
        `${API_URL}paymentDetails/${localStorage.getItem("sub")}/${id}`,
        payment
    );
    if(result) return result.data;
}

export const deletePayment = async (id:string):Promise<void> => {
    await httpDelete<Payment>(`${API_URL}paymentDetails/${localStorage.getItem("sub")}/${id}`,);
}


