import GoogleMapProvider from '@components/common/map/googleMap/provider';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import GoogleMap from "@components/common/map/googleMap";
import HomeControls from "@pages/home/controls";
import { ParkingLocationMarker } from '@components/common/map/markers/parkingLocation';
import {
    findInStreetParkingLots,
    findParkingLots,
    FindParkingOptions,
    InStreetParkingPreview, ParkingCountPreview, ParkingPreview
} from "@webservices/parking/findParking";
import {Marker} from "@types.d/Marker";
import {Polygon as PolygonType} from "@types.d/Polygon";
import MapMarker from "@components/common/map/googleMap/marker";
import Polygon from "@components/common/map/googleMap/polygon";
import {getDistance, isInBound} from "@utilities/coordinateTools";
import {Coordinate} from "@types.d/Coordinate";
import {LeafMarker} from "@components/common/map/markers/leafMarker";
import BottomSheet from "@components/common/bottomSheet";
import ParkingSummary from "@components/bottomSheets/parkingSummary";
import InParking from "@components/bottomSheets/inParking";
import {useHistory} from "react-router-dom";

const Home = () => {

    const [zoomExtents, setZoomExtents] = useState<boolean>(false);
    const [applyFilters, setApplyFilters] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedInStreet, setSelectedInStreet] = useState<number|string>();
    const [selectedOffStreet, setSelectedOffStreet] = useState<number>();
    const [center, setCenter] = useState<Coordinate>({ lat: 60.17019718741407, lng: 24.937733788981934 });
    const filters = useRef<{
        position?:{lat:number, lng:number}
        start?:string
        end?:string
        vehicleId?:string
        paymentId?:string
    }|null>(null)


    const [foundItems, setFoundItems] = useState<{
        inStreets:InStreetParkingPreview[]
        parkings:ParkingPreview[]
    }>()
    const [markers, setMarkers] = useState<Marker[]>([]);
    const [polygons, setPolygons] = useState<PolygonType[]>([]);
    const cachedItems = useRef<{
        bounds?:google.maps.LatLngBounds
        inStreets:InStreetParkingPreview[]
        parkings:ParkingPreview[]
        overallZoom1:ParkingCountPreview["data"]
        overallZoom2:ParkingCountPreview["data"]
    }>({inStreets:[], parkings:[], overallZoom1:[], overallZoom2:[]});

    useEffect(()=>{
        if(foundItems?.parkings && foundItems.parkings[0]) setParkingMarkers();
        if(foundItems?.inStreets && foundItems.inStreets[0]) setInStreetsParking();
    }, [foundItems])

    const handleBoundChange = async (map: google.maps.Map) => {
        setZoomExtents(false);

        const bound = map.getBounds()!;

        if(isRecInBound( bound, cachedItems.current?.bounds)) {
            findInExists(bound);
            return;
        }

        const center = map.getCenter()!;
        const zoom = map.getZoom()!;

        if(zoom <= 11) return;

        //show overall count
        if(zoom <= 15 && zoom > 13) {
            setMarkers([]);
            setPolygons([]);
            // uncomment below line to show overall parking count
            // if(!cachedItems.current.overallZoom2[0]){
            //     cachedItems.current.overallZoom2 = await getParkingCount(2);
            // }
            // setParkingCountMarkers(cachedItems.current.overallZoom2,2);
            return;
        } else if(zoom <= 13 && zoom > 11) {
            setMarkers([]);
            setPolygons([]);
            // uncomment below line to show overall parking count
            // if(!cachedItems.current.overallZoom1[0]){
            //     cachedItems.current.overallZoom1 = await getParkingCount(1);
            // }
            // setParkingCountMarkers(cachedItems.current.overallZoom1,1);
            return;
        }

        await find(
            {
                locationCoordinates: {latitude: center.lat(), longitude: center.lng()},
            },
            zoom,
            bound
        );
    }

    const findInExists = (bound:google.maps.LatLngBounds) => {
        if(!cachedItems.current) return;
        const parkings = cachedItems.current.parkings.filter(p=> isInBound(
            p.parkingCoordinates.latitude,
            p.parkingCoordinates.longitude,
            bound
        ));
        const inStreets = cachedItems.current.inStreets.filter(p=> isInBound(
            p.centerLatitude,
            p.centerLongitude,
            bound
        ));
        setFoundItems({
            inStreets:inStreets,
            parkings:parkings
        })
    }

    const find = async (params:FindParkingOptions, zoom:number, bounds:google.maps.LatLngBounds) => {
        setLoading(true);
        const distances = getDistances(bounds);
        const [
            parkingLots,
            inStreetLots
        ] = await Promise.all([
            zoom > 15 ? findParkingLots({...params, ...distances}) : [],
            zoom > 15 ? findInStreetParkingLots({...params, ...distances}) : []
        ]);
        cachedItems.current = {
            ...cachedItems.current,
            bounds: bounds,
            inStreets:inStreetLots,
            parkings:parkingLots
        };
        setFoundItems({
            inStreets:inStreetLots,
            parkings:parkingLots
        })
        setLoading(false);
    }

    const getDistances = (bounds:google.maps.LatLngBounds):{distanceV:number, distanceH:number} => {
        return {
            distanceV:getDistance(
                {lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng()},
                {lat: bounds.getSouthWest().lat(), lng: bounds.getNorthEast().lng()},
            ) / 2,
            distanceH:getDistance(
                {lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng()},
                {lat: bounds.getSouthWest().lat(), lng: bounds.getNorthEast().lng()},
            )
        }
    }

    const isRecInBound = (bound:google.maps.LatLngBounds, lastBound?:google.maps.LatLngBounds):boolean => {
        if(!lastBound) return false;
        return lastBound.getSouthWest().lat() <= bound.getSouthWest().lat() &&
            lastBound.getSouthWest().lng() <= bound.getSouthWest().lng() &&
            lastBound.getNorthEast().lat() >= bound.getNorthEast().lat() &&
            lastBound.getNorthEast().lng() >= bound.getNorthEast().lng();
    }

    const setInStreetsParking = () => {
        setPolygons(foundItems!.inStreets.map(lot=> {
            return {
                id:lot.parkingCode,
                coords: lot.geoJson.geometry.coordinates[0].mainCoordinates.map(c=>({lat:c.lat, lng:c.lng})),
                strokeColor: "#7900D8",
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: "#C48AE2",
                fillOpacity: 1.0,
                onClick:(id)=>{
                    setSelectedInStreet(id);
                }
            }
        }))
    }

    const setParkingMarkers = () => {
        setMarkers(foundItems!.parkings.map((lot, index) => {
            return {
                id:lot.parkingId,
                position:{
                    lat:lot.parkingCoordinates.latitude,
                    lng:lot.parkingCoordinates.longitude
                },
                info:{
                    title:lot.parkingName,
                    price:lot.tariffs.pricePerHour,
                    booked: lot.bookingCount,
                    rate:lot.reviewInfo.rateMean,
                    reviews: lot.reviewInfo.rateCount,
                    features: lot.features,
                    vehicleSize:undefined,
                    distance:lot.distance
                },
                onClick: (id: string | number) => {setSelectedOffStreet(index)},
                advanceMarker: [ParkingLocationMarker, ParkingLocationMarker, ParkingLocationMarker]
            }
        }))
    }

    const setParkingCountMarkers = (counts:ParkingCountPreview["data"], zoom:1|2) => {
        const mr:Marker[] = [];
        for(let i = 0; i<counts.length; i++){
            const c = counts[i];
            if(c.count){
                mr.push({
                    id:`grid-${i}`,
                    position:{
                        lat:c.latitude,
                        lng:c.longitude
                    },
                    info:{
                        count:c.count,
                        zoom:(zoom <= 15 && zoom > 13) ? 2 : 1
                    },
                    onClick: () => {},
                    advanceMarker: [LeafMarker, LeafMarker, LeafMarker]
                })
            }
        }
        setMarkers(mr)
    }

    const getInfo = useMemo(()=>{
        if(selectedOffStreet === undefined || !foundItems) return <></>;
        const lot = foundItems.parkings[selectedOffStreet!];
        const info = {
            id:lot.parkingId,
            title:lot.parkingName,
            price:lot.tariffs.pricePerHour,
            booked: lot.bookingCount,
            rate:lot.reviewInfo.rateMean,
            reviews: lot.reviewInfo.rateCount,
            features: lot.features,
            vehicleSize:"",
            distance:lot.distance
        }
        return (
            <ParkingSummary
                {...info}
                next={()=>{setSelectedOffStreet(prevState => {
                    if(foundItems!.parkings.length > prevState!+1) {
                        return prevState!+1
                    } else {
                        return prevState;
                    }
                })}}
                previous={()=>{setSelectedOffStreet(prevState => {
                    if(prevState!-1 >= 0) {
                        return prevState!-1
                    } else {
                        return prevState;
                    }
                })}}
            />
        )
    }, [selectedOffStreet])

    return (
        <GoogleMapProvider>
            <>
                <div className={"d-flex flex-column h-100 position-relative"}>
                    <GoogleMap
                        zoom={16}
                        center={center}
                        style={{ flex: 1 }}
                        onIdle={handleBoundChange}
                        goToCurrentLocation
                        tiltBreakpoints={[
                            {minZoom:0, maxZoom:17, tilt:0},
                            {minZoom:17, maxZoom:40, tilt:65},
                        ]}
                    />
                    <HomeControls
                        onFilterChanged={f=>{
                            filters.current = f;
                            setApplyFilters(true);
                        }}
                        onLoad={loading}
                        foundSpotsCount={markers.length}
                    />
                </div>
                <MapMarker
                    markers={markers}
                    markersBreakPoints={[
                        { zoomMin: 0, zoomMax: 18 },
                        { zoomMin: 19, zoomMax: 20 },
                        { zoomMin: 20, zoomMax: 22 },
                    ]}
                    zoomExtents={zoomExtents}
                    clearOnRefresh
                />
                <Polygon
                    polygons={polygons}
                    clearOnUnLoad
                />
                {
                    selectedOffStreet !== undefined && <BottomSheet onClose={()=>setSelectedOffStreet(undefined)}>
                        { getInfo }
                    </BottomSheet>
                }
                {
                    selectedInStreet && <BottomSheet
                        onClose={() => setSelectedInStreet(undefined)}
                    >
                        <InParking
                            code={selectedInStreet}
                        />
                    </BottomSheet>
                }
            </>
        </GoogleMapProvider>
    );
};

export default Home;