import React, {useEffect, useMemo, useState} from 'react';
import styles from './current.module.scss';
import TimeProgress from "@components/common/timeProgress";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import BottomSheet from '@components/common/bottomSheet';
import ExtendTime from '@components/bottomSheets/extendTime';
import SendFriend from '@components/bottomSheets/sendFriend';
import Share from '@components/bottomSheets/share';
import ParkingDetails from "@components/bottomSheets/parkingDetails";
import {cancelSession, getCurrentSession, leavingIn10Minutes, leavingNow, Session} from "@webservices/session";
import noCurrentSession from "@assets/images/no-current-session.png";
import moment from "moment/moment";
import {getParking, Parking} from "@webservices/parking/Parking";
import {getStreetParkingById, InStreetParking} from "@webservices/parking/inStreet";
import {useHistory} from "react-router-dom";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";
import ConfirmModal from "@components/modals/confirmModal";

const CurrentSession = () => {

    const [showExtendTimeBT, setShowExtendTimeBT] = useState<boolean>(false);
    const [showSendFriendBT, setShowSendFriendBT] = useState<boolean>(false);
    const [showParkingDetailsBT, setShowParkingDetailsBT] = useState<boolean>(false);
    const [showShareBT, setShowShareBT] = useState<boolean>(false);
    const [showLeaveNowBT, setShowLeaveNowBT] = useState<boolean>(false);
    const [showLeave10MinBT, setShowLeave10MinBT] = useState<boolean>(false);
    const [showCancelBT, setShowCancelBT] = useState<boolean>(false);

    const [sessionLoading, setSessionLoading] = useState<boolean>(false);
    const [leaveLoading, setLeaveLoading] = useState<boolean>(false);

    const [session, setSession] = useState<Session>();
    const [parkingInfo, setParkingInfo] = useState<Parking>();
    const [inStreetInfo, setInStreetInfo] = useState<InStreetParking>();

    const history = useHistory();

    useEffect(()=>{
        loadSession().catch();
    }, [])

    const progressBar = useMemo(()=> {
        let sessionRemainTime;
        let sessionTotalTime;
        if(session?.leaveIn10Min){
            sessionTotalTime = moment(session.leaveIn10Min).diff(
                session.reserveFrom,
                "seconds"
            );
            sessionRemainTime = moment(session.leaveIn10Min).diff(
                moment().format("YYYY-MM-DD HH:mm:ss"),
                "seconds"
            );
        } else {
            sessionTotalTime = moment(session?.reserveTo).diff(
                session?.reserveFrom,
                "seconds"
            );
            sessionRemainTime = moment(session?.reserveTo).diff(
                moment().format("YYYY-MM-DD HH:mm:ss"),
                "seconds"
            );
        }
        return <TimeProgress
            onExpired={()=>loadSession()}
            seconds={sessionRemainTime}
            total={sessionTotalTime}
        />
    }, [session])

    const loadSession = async () => {
        setSessionLoading(true);
        const currentSession = await getCurrentSession(undefined, ()=>{setSessionLoading(false)});
        if(!currentSession) {
            setSessionLoading(false);
            setSession(undefined);
            return;
        }
        if(currentSession.sessionType === "Parking" && currentSession.parkingId){
            const parking = await getParking(currentSession.parkingId);
            setParkingInfo(parking);
        } else {
            const inStreet = await getStreetParkingById(currentSession.inStreetParkingId!);
            setInStreetInfo(inStreet);
        }
        setSession(currentSession);
        setSessionLoading(false);
    }

    const leaveNow = async () => {
        setLeaveLoading(true);
        await leavingNow(session?.id!);
        setLeaveLoading(false);
        setShowLeaveNowBT(false);
        setSession(undefined);
    }

    const leave10Min = async () => {
        if(!session) return;
        setLeaveLoading(true);
        const sessionInfo = await leavingIn10Minutes(session?.id!);
        setLeaveLoading(false);
        setShowLeave10MinBT(false);
        setSession(sessionInfo);
    }

    const cancel = async () => {
        if(!session) return;
        setLeaveLoading(true);
        await cancelSession(session.id);
        setLeaveLoading(false);
        setShowCancelBT(false);
        await loadSession();
    }

    const NoCurrentSession = ():JSX.Element => {
        return(
            <div className={"d-flex h-100 w-100 justify-content-center align-items-center flex-column"}>
                <img src={noCurrentSession} alt={"no current session"}/>
                {
                    sessionLoading ? <Icon size={20} color={"primary"} icon={<Loading/>} /> :
                        <p><Text value={"No session available here"} class={"capitalize"}/></p>
                }
            </div>
        )
    }

    return (
        <>
            {
                session ? <>
                    <div className={styles.timer}>
                        <div className={"w-75"}>
                            { progressBar }
                        </div>
                        {
                            parkingInfo && <CustomButton
                                text={<Text value={"I’m leaving now"} class={"capitalize"}/>}
                                theme={"primary"}
                                fullWidth
                                onClick={()=>setShowCancelBT(true)}
                            />
                        }
                        {
                            inStreetInfo && <>
                                <CustomButton
                                    text={<Text value={"I’m leaving now"} />}
                                    theme={"primary"}
                                    fullWidth
                                    className={"mb-3"}
                                    onClick={()=>setShowLeaveNowBT(true)}
                                />
                                <CustomButton
                                    text={<Text value={"I’m leaving in 10 minutes"} />}
                                    theme={"white"}
                                    fullWidth
                                    onClick={()=>setShowLeave10MinBT(true)}
                                />
                            </>
                        }
                        <CustomButton
                            text={<Text value={"Need more time ?"} />}
                            theme={"no-border"}
                            fullWidth
                            onClick={()=>setShowExtendTimeBT(true)}
                        />
                    </div>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <p><Text value={"direction to parking"} /></p>
                        <CustomButton
                            text={<Text value={"go"} />}
                            theme={"white"}
                            className={"mb-2"}
                            style={{padding:"0 15px", width:69}}
                            onClick={()=>{history.push({
                                pathname:"/direction",
                                search:`?id=${parkingInfo?.id || inStreetInfo?.id}&target=${parkingInfo?.parkingLatitude||inStreetInfo?.centerLatitude},${parkingInfo?.parkingLongitude||inStreetInfo?.centerLongitude}`
                            })}}
                        />
                    </div>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <p><Text value={"send location to a friend"} /></p>
                        <CustomButton
                            text={<Text value={"send"} />}
                            theme={"white"}
                            className={"mb-2"}
                            onClick={()=>{setShowShareBT(true)}}
                            style={{padding:"0 15px", width:69}}
                        />
                    </div>
                    {
                        parkingInfo && <>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <p><Text value={"send entry car code to a friend"} /></p>
                                <CustomButton
                                    text={<Text value={"send"} />}
                                    theme={"white"}
                                    className={"mb-2"}
                                    onClick={()=>{setShowSendFriendBT(true)}}
                                    style={{padding:"0 15px", width:69}}
                                />
                            </div>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <p><Text value={"parking information"} /></p>
                                <CustomButton
                                    text={<Text value={"details"} />}
                                    theme={"white"}
                                    onClick={()=>{setShowParkingDetailsBT(true)}}
                                    style={{padding:"0 15px", width:69}}
                                />
                            </div>
                        </>
                    }
                    <p className={"mt-3"}><Text value={"need help ?"} /></p>
                </> : <NoCurrentSession/>
            }
            {
                showLeaveNowBT && <BottomSheet
                    onClose={()=>setShowLeaveNowBT(false)}
                >
                    <ConfirmModal
                        title={"are you sure?"}
                        confirmText={"yes, sure"}
                        onConfirm={leaveNow}
                        onCancel={()=>setShowLeaveNowBT(false)}
                        inProgress={leaveLoading}
                    />
                </BottomSheet>
            }
            {
                showCancelBT && <BottomSheet
                    onClose={()=>setShowCancelBT(false)}
                >
                    <ConfirmModal
                        title={"are you sure?"}
                        confirmText={"yes, sure"}
                        onConfirm={cancel}
                        onCancel={()=>setShowCancelBT(false)}
                        inProgress={leaveLoading}
                    />
                </BottomSheet>
            }
            {
                showLeave10MinBT && <BottomSheet
                    onClose={()=>setShowLeave10MinBT(false)}
                >
                    <ConfirmModal
                        title={"are you sure?"}
                        confirmText={"yes, sure"}
                        onConfirm={leave10Min}
                        onCancel={()=>setShowLeave10MinBT(false)}
                        inProgress={leaveLoading}
                    />
                </BottomSheet>
            }
            {
                showExtendTimeBT && <BottomSheet
                    onClose={() => { setShowExtendTimeBT(false) }}
                >
                    <ExtendTime
                        onCancel={()=>setShowExtendTimeBT(false)}
                        parkingId={session?.inStreetParkingId || session?.parkingId}
                        sessionId={session?.id}
                        pricePerHour={inStreetInfo?.pricePerHour || parkingInfo?.tariffs.pricePerHour}
                        endTime={session?.reserveTo}
                        sessionType={session?.sessionType}
                    />
                </BottomSheet>
            }
            {
                showSendFriendBT && <BottomSheet
                    onClose={() => { setShowSendFriendBT(false) }}
                >
                    <SendFriend />
                </BottomSheet>
            }
            {
                showParkingDetailsBT && <BottomSheet
                    onClose={() => { setShowParkingDetailsBT(false) }}
                    fullWidth
                >
                    <ParkingDetails
                        parking={parkingInfo}
                    />
                </BottomSheet>
            }
            {
                showShareBT && <Share
                    close={() => setShowShareBT(false)}
                    data={`geo:${inStreetInfo?.centerLatitude || parkingInfo?.parkingLongitude},${inStreetInfo?.centerLongitude || parkingInfo?.parkingLongitude}`}
                />
            }
        </>
    );
};

export default CurrentSession;