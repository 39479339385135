import React, {useEffect, useState} from 'react';
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import BottomSheet from "@components/common/bottomSheet";
import styles from "./personalInfo.module.scss";
import Icon from "@components/common/icon";
import {Add, Calender, Loading, Trash} from "@components/common/svgIcons";
import Input from "@components/common/form/input";
import Radio from "@components/common/form/radio";
import Divider from "@components/common/divider";
import Text from "@components/common/text";
import SetTime from "@components/bottomSheets/setTime";
import {deleteUser, getUserInfo, updateUserAvatar, updateUserInfo, User} from "@webservices/account/user";
import noImage from "@assets/images/noAvatar.svg";
import Upload from "@components/common/form/upload";
import CustomButton from "@components/common/customButton";
import {useHistory} from "react-router-dom";
import ConfirmModal from "@components/modals/confirmModal";
import {signOut} from "../../../services/app/userServices";
import {failToast, successToast} from "@toast/index";
import {ApiError} from "@types.d/ApiError";
import ResetPassword from "@components/bottomSheets/resetPassword";
import {validate} from "@utilities/validator";
import {createUserSchema} from "@schemas/user";
import {convertEmptyToUndefined} from "@utilities/stringUtils";

const PersonalInfo = () => {

    const history = useHistory();

    const [showDatePickerBT, setShowDatePickerBt] = useState<boolean>(false);
    const [showResetPasswordBT, setShowResetPasswordBT] = useState<boolean>(false);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
    const [showRemovePhotoConfirm, setShowRemovePhotoConfirm] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<User>();
    const [updateLoading, setUpdateLoading] = useState<boolean>(false);
    const [removeLoading, setRemoveLoading] = useState<boolean>(false);

    useEffect(()=>{
        (async () => {
            const info = await getUserInfo();
            if(info){
                setUserInfo(info);
            }
        })()
    }, [])

    const handleSaveClick = async () => {
        if(!userInfo) return;
        const obj = {
            firstName: convertEmptyToUndefined(userInfo.firstName),
            lastName: convertEmptyToUndefined(userInfo.lastName),
            gender: userInfo.gender,
            userPhoneNumber: convertEmptyToUndefined(userInfo.userPhoneNumber),
            dateOfBirth: convertEmptyToUndefined(userInfo.dateOfBirth),
            email: convertEmptyToUndefined(userInfo.email)
        }
        if(!validate(obj, createUserSchema)){
            failToast(<Text value={"invalid parameters"} class={"capitalize"}/>);
            return;
        }
        setUpdateLoading(true);
        await updateUserInfo(obj, (err:ApiError)=>{
            failToast(err.customMessage || "unKnown Error");
            setUpdateLoading(false);
        });
        successToast(<Text value={"profile info updated successfully."} class={"capitalize"}/>);
        setUpdateLoading(false);
    }

    const onDeleteUser = async () => {
        setRemoveLoading(true);
        await deleteUser();
        await signOut();
    }

    const handleChange = (id?:string, value?:string) => {
        if(!id) return;
        setUserInfo(prevState => ({...prevState!, [id]:value}))
    }

    const handleUploadAvatar = async (id?:string, value?:string) => {
        if(!value || !id) return;
        setUserInfo(prevState => ({...prevState!, [id]:value}))
        await updateUserAvatar(value);
    }

    const handleDeleteAvatar = async () => {
        setRemoveLoading(true);
        setUserInfo(prevState => ({...prevState!, photo:""}))
        await updateUserAvatar("");
        setRemoveLoading(false);
    }

    return (
        <SimpleLayout
            header={<SimpleHeader
                title={<Text value={"personal info"} class={"capitalize"}/>}
                backControl
            />}
            noPadding
        >
            <div className={styles.contents}>
                <div className={styles.form}>
                    <div className={"d-flex align-items-center gap-2 mb-3"}>
                        <div className={styles.avatar}>
                            <img
                                src={userInfo?.photo || noImage}
                                alt={"avatar"}
                            />
                        </div>
                        {
                            !userInfo?.photo ? <Upload
                                id={"photo"}
                                onChange={handleUploadAvatar}
                                content={
                                    <div className={"d-flex align-items-center gap-2 w-auto"}>
                                        <Icon size={20} color={"black"} icon={<Add/>}/>
                                        <p><Text value={"add photo"} class={"capitalize"}/></p>
                                    </div>
                                }
                            /> : <div
                                className={"d-flex align-items-center gap-2"}
                                onClick={()=>setShowRemovePhotoConfirm(true)}
                            >
                                <Icon size={14} color={"red"} icon={<Trash/>}/>
                                <p className={"red"}><Text value={"delete photo"} class={"capitalize"}/></p>
                            </div>
                        }
                    </div>
                    <Input
                        id={"firstName"}
                        label={"name"}
                        placeHolder={"enter your name"}
                        onChange={handleChange}
                        className={"mb-3"}
                        default={userInfo?.firstName}
                    />
                    <Input
                        id={"lastName"}
                        label={"last name"}
                        placeHolder={"enter your last name"}
                        onChange={handleChange}
                        className={"mb-3"}
                        default={userInfo?.lastName}
                    />
                    <Input
                        id={"dateOfBirth"}
                        label={"birthday"}
                        placeHolder={"2000-01-01"}
                        allowedCharacter={"date"}
                        onChange={handleChange}
                        className={"mb-3"}
                        icon={{
                            icon:<Icon size={20} color={"primary"} icon={<Calender/>}/>,
                            action:()=>setShowDatePickerBt(true)
                        }}
                        default={userInfo?.dateOfBirth}
                    />
                    <Input
                        id={"userPhoneNumber"}
                        label={"phone number"}
                        placeHolder={"enter your phone number"}
                        allowedCharacter={"number"}
                        onChange={handleChange}
                        className={"mb-3"}
                        default={userInfo?.userPhoneNumber}
                    />
                    <Input
                        id={"email"}
                        label={"email"}
                        placeHolder={"enter your email address"}
                        onChange={handleChange}
                        className={"mb-3"}
                        default={userInfo?.email}
                    />
                    <Radio
                        id={"gender"}
                        label={"gender"}
                        onChange={handleChange}
                        options={[
                            {title:"female", value:"female"},
                            {title:"male", value:"male"},
                            {title:"rather not to say", value:"none-binary"},
                        ]}
                        default={userInfo?.gender}
                    />
                    <Divider/>
                    <div onClick={()=>setShowResetPasswordBT(true)}>
                        <p className={"text-capitalize"}><Text value={"reset password"}/></p>
                    </div>
                    <Divider/>
                    <div onClick={()=>history.push("/account/invite-friends")}>
                        <p className={"text-capitalize"}><Text value={"invite friends"}/></p>
                    </div>
                    <Divider/>
                    <div onClick={()=>setShowRemoveConfirm(true)}>
                        <p className={"text-capitalize"}><Text value={"delete account"}/></p>
                    </div>
                </div>
                <div className={styles.actions}>
                    <CustomButton
                        theme={"primary"}
                        text={"save changes"}
                        onClick={handleSaveClick}
                        loading={updateLoading}
                        loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                    />
                    <CustomButton
                        theme={"white"}
                        text={"cancel"}
                        onClick={()=>{}}
                    />
                </div>
            </div>
            {
                showDatePickerBT && <BottomSheet
                    onClose={()=>setShowDatePickerBt(false)}
                >
                    <SetTime
                        id={""}
                        format={"YYYY-MM-DD"}
                        default={userInfo?.dateOfBirth}
                        onCancel={()=>setShowDatePickerBt(false)}
                        onSave={(id:string, value:string)=>{
                            handleChange("dateOfBirth", value);
                            setShowDatePickerBt(false);
                        }}
                        pickerType={"date"}
                    />
                </BottomSheet>
            }
            {
                showResetPasswordBT && <BottomSheet onClose={()=>setShowResetPasswordBT(false)}>
                    <ResetPassword/>
                </BottomSheet>
            }
            {
                showRemoveConfirm && <BottomSheet onClose={()=>setShowRemoveConfirm(false)}>
                    <ConfirmModal
                        title={"delete-account-txt"}
                        onConfirm={async () => {
                            await onDeleteUser();
                            setShowRemoveConfirm(false);
                        }}
                        onCancel={()=>{
                            setShowRemoveConfirm(false);
                        }}
                        inProgress={removeLoading}
                    />
                </BottomSheet>
            }
            {
                showRemovePhotoConfirm && <BottomSheet onClose={()=>setShowRemovePhotoConfirm(false)}>
                    <ConfirmModal
                        title={"do you want to remove your profile photo?"}
                        onConfirm={async () => {
                            await handleDeleteAvatar();
                            setShowRemovePhotoConfirm(false);
                        }}
                        onCancel={()=>{
                            setShowRemovePhotoConfirm(false);
                        }}
                        inProgress={removeLoading}
                    />
                </BottomSheet>
            }
        </SimpleLayout>
    );
};

export default PersonalInfo;