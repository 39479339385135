import Joi from "joi";

export const bookingSchema = Joi.object({
    userId: Joi.string().required(),
    parkingId: Joi.string().required(),
    parkZoneId: Joi.string().required(),
    plateNumber: Joi.string().required(),
    reserveFrom: Joi.string().required(),
    reserveTo: Joi.string().required(),
    vehicleId: Joi.string().required(),
})