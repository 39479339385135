import React, {FunctionComponent} from 'react';
import styles from "./receipt.module.scss";
import Text from "@components/common/text";

interface ReceiptDetailsProps {
    id:string
    totalPrice:number
}

const ReceiptDetails:FunctionComponent<ReceiptDetailsProps> = (props) => {
    return (
        <ul className={styles.container}>
            <li>
                <p><Text value={"payment name"}/></p>
                <p>PayPal</p>
            </li>
            {/*<li>*/}
            {/*    <p><Text value={"Vehicle"}/></p>*/}
            {/*    <p>BMW</p>*/}
            {/*</li>*/}
            <li>
                <p><Text value={"Payment status"}/></p>
                <p>Successful</p>
            </li>
            <li>
                <p><Text value={"Total price"}/></p>
                <p>{`€${props.totalPrice}`}</p>
            </li>
            <li>
                <p><Text value={"Transaction number"}/></p>
                <p>123456</p>
            </li>
            <li>
                <p><Text value={"date"}/></p>
                <p>3/12/2023</p>
            </li>
            <li>
                <p><Text value={"time"}/></p>
                <p>4:30 PM</p>
            </li>
            <li>
                <p><Text value={"booking id"}/></p>
                <p>{props.id}</p>
            </li>
        </ul>
    );
};

export default ReceiptDetails;