import React from 'react';
import TabBar from "@components/common/tabBar";
import Text from "@components/common/text";
import CurrentSession from "@pages/session/current";
import Reserved from './reserved';
import History from './history';

const Session = () => {
    return (
        <TabBar tabs={[
            {
                title:<Text value={"current"}/> ,
                content:<CurrentSession/>
            },
            {
                title:<Text value={"reserved"}/> ,
                content:<Reserved/>
            },
            {
                title:<Text value={"history"}/> ,
                content:<History/>
            },
        ]}/>
    );
};

export default Session;