import {httpDelete, httpGet, httpPatch, httpPost} from "../index";
import {Message} from "../Message";
import {API_URL} from "../../../config";
import {ApiError} from "@types.d/ApiError";

export interface Vehicle {
    id:string
    addTimeStamp:string
    vehicleName:string
    make:string
    color:string
    plateNumber:string
    policyNumber:string
    vecType: "SUV"|"Station Wagon"|"Crossover"|"Hatchback"|"Minivan"|"Convertible"|"Sedan"|"Coupe"|"Pickup truck"|"Sport car"|"Luxury car"|"Truck"
    vecCategory:string
    isDefaultVehicle:boolean
}

interface CreateOrUpdateVehicle {
    vehicleName:string
    make:string
    color:string
    plateNumber:string
    policyNumber:string
    vecType: "SUV"|"Station Wagon"|"Crossover"|"Hatchback"|"Minivan"|"Convertible"|"Sedan"|"Coupe"|"Pickup truck"|"Sport car"|"Luxury car"|"Truck"
    vecCategory:string
    isDefaultVehicle:boolean
}

export const getVehicle = async (id:string):Promise<Vehicle | undefined> => {
    const vehicle = await httpGet<Message<Vehicle>>(`${API_URL}vehicles/${id}`);
    if(vehicle) return vehicle.data;
}

export const getVehicles = async (userId?:string):Promise<Vehicle[] | undefined> => {
    const vehicles = await httpGet<Message<Vehicle[]>>(
        `${API_URL}vehicles/${userId || localStorage.getItem("sub")}`
    );
    if(vehicles) return vehicles.data;
}

export const createVehicle = async (vehicle:CreateOrUpdateVehicle, onError?:(err:ApiError)=>void):Promise<Vehicle | undefined> => {
    const vehicleInfo = await httpPost<CreateOrUpdateVehicle, Message<Vehicle>>(
        `${API_URL}vehicles/${localStorage.getItem("sub")}`,
        vehicle,
        {},
        onError
    );
    if(vehicleInfo) return vehicleInfo.data;
}

export const updateVehicle = async (id:string, vehicle:CreateOrUpdateVehicle, onError?:(err:ApiError)=>void):Promise<Vehicle | undefined> => {
    const vehicleInfo = await httpPatch<CreateOrUpdateVehicle, Message<Vehicle>>(
        `${API_URL}vehicles/${localStorage.getItem("sub")}/${id}`,
        vehicle,
        {},
        onError
    );
    if(vehicleInfo) return vehicleInfo.data;
}

export const deleteVehicle = async (id:string):Promise<void> => {
    await httpDelete(`${API_URL}vehicles/${localStorage.getItem("sub")}/${id}`);
}