import {httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL} from "../../../config";

export interface Feedback {
    id:string
    userId:string
    userExperience :"happy"|"sad"|"neutral"
    description:string
    photos:string[]
}

interface CreateFeedBack {
    userId:string
    userExperience :"happy"|"sad"|"neutral"
    description:string
    photos:string[]
}

export const createFeedBack = async (feedBack:CreateFeedBack):Promise<Feedback|undefined> => {
    const result = await httpPost<CreateFeedBack, Message<Feedback>>(
        `${API_URL}feedbacks`,
        feedBack
    );
    if(result) return result.data;
}