import React, {FunctionComponent, useLayoutEffect, useRef, useState} from 'react';
import ReactDOM from "react-dom";
import styles from "./bottomSheets.module.scss";

interface BottomSheetProps{
    children?: React.ReactNode
    onClose:(value:boolean)=>void
    dropDown?:boolean
    fullWidth?:boolean
    select?:boolean
}

const BottomSheet:FunctionComponent<BottomSheetProps> = (props) => {

    const [closed, setClosed] = useState<boolean>(false);
    const [zIndex, setZIndex] = useState<number>(0);
    const [height, setHeight] = useState<string>();

    const parentRef = useRef<HTMLDivElement|null>(null);
    const sheetRef = useRef<HTMLDivElement|null>(null);
    const clickStart = useRef<number|null>(null);

    useLayoutEffect(()=>{
        const parent = document.getElementById('bottomSheets');
        if(!parent) return;
        setZIndex(parent.children.length);
        document.body.style.overscrollBehavior = 'contain';
        const sheetHeight = sheetRef.current!.getBoundingClientRect().height;
        const parentHeight = window.innerHeight;
        if(sheetHeight/parentHeight > 0.9) {
            setHeight("90%");
        } else {
            setHeight(`${sheetHeight/parentHeight*100}%`)
        }
        return ()=>{
            document.body.style.overscrollBehavior = 'initial';
        }
    }, [])

    const closeSheet = () => {
        setClosed(true);
        setTimeout(()=>props.onClose(false), 300);
    }

    const outClickCloseSheet = (e:React.MouseEvent<HTMLDivElement>) => {
        if(e.target === parentRef.current ) closeSheet();
    }

    const handleCloseSheet = (e:React.TouchEvent<HTMLDivElement>) => {
        if(e.touches[0].clientY - Number(clickStart.current) > 0) closeSheet();
    }

    return ReactDOM.createPortal(
        <div
            className={`
                ${styles.container} 
                ${zIndex > 1 && styles.transparentContainer} 
                ${closed &&  styles.containerFadeOut}
            `}
            style={{zIndex}}
            ref={parentRef}
            onClick={outClickCloseSheet}
        >
            <div
                ref={sheetRef}
                className={`
                    ${styles.sheet}
                    ${closed &&  styles.onClose}
                    ${props.fullWidth && "p-0"}
                    ${props.dropDown && styles.onClose}
                `}
                style={{height:height ? height : "initial"}}
            >
                <div
                    className={styles.handle}
                    onTouchStart={(e)=>clickStart.current = e.touches[0].clientY}
                    onTouchMove={handleCloseSheet}
                >
                    <span/>
                </div>
                <div style={{height:"calc(100% - 35px)"}}>
                    {props.children}
                </div>
            </div>
        </div>,
        document.getElementById(!props.select ? 'bottomSheets' : 'selectSheet')!
    );
};

export default BottomSheet;