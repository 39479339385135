import axios, { AxiosError } from "axios";
import {failToast} from "../toast";

export const handleError = async (
    error: any,
    onError?: (error: any) => void
): Promise<void> => {
    if(onError){
        onError({...error, customMessage:checkErrorStatus(error)});
        return;
    }
    if (axios.isAxiosError(error)) {
        failToast(checkErrorStatus(error))
    } else {
        console.log(error);
    }
}

export const checkErrorStatus = (err: AxiosError<any, any>):string => {
    switch (err.response?.status) {
        case 404:
            return "404 Not Found!"

        case 421:
            return "Invalid Request!"

        case 422:
            return "The parameters passed are invalid!"

        case undefined:
            return "Network Error!"

        default:
            return `Unknown Error : ${err.message}`
    }
}