import React, {FunctionComponent, useEffect, useState} from 'react';
import IconHeader from "@components/common/iconHeader";
import {Add, Card} from "@components/common/svgIcons";
import Radio from "@components/common/form/radio";
import {getPayments, Payment} from "@webservices/account/payment";
import BottomSheet from "@components/common/bottomSheet";
import AddPayment from "@components/bottomSheets/addPayment";
import RadioLoading from "@components/app/loadings/radioLoading";
import Icon from "@components/common/icon";
import CustomButton from "@components/common/customButton";

interface PaymentsProps {
    id:string
    dropDownBT?:(value:boolean)=>void
    onChange:(id?:string, value?:any)=>void
    selected?:string
}

const Payments:FunctionComponent<PaymentsProps> = (props) => {

    const [payments, setPayments] = useState<Payment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAddPaymentBT, setShowAddPaymentBT] = useState<boolean>(false);

    useEffect(()=>{
        loadPayments().catch();
    }, [])

    const loadPayments = async () => {
        setLoading(true);
        const res = await getPayments();
        if(!res || !res[0]) {
            setLoading(false);
            return;
        }
        props.onChange("paymentId", props.selected || res[0].id)
        setPayments(res);
        setLoading(false);
    }

    return (
        <>
            <div className={"d-flex align-items-center justify-content-between"}>
                <IconHeader
                    icon={<Card/>}
                    text={"payments"}
                />
                <CustomButton
                    theme={"no-border"}
                    icon={<Icon color={"primary"} size={20} icon={<Add/>}/>}
                    onClick={()=>{
                        setShowAddPaymentBT(true);
                        props.dropDownBT && props.dropDownBT(true);
                    }}
                    className={"p-0"}
                />
            </div>
            {
                loading ? <div className={"mt-2"}>
                    <RadioLoading/>
                </div> : <>
                    {
                        payments[0] && <Radio
                            id={props.id}
                            options={payments.map(p=>{
                                return {title: `****${p.cardNumber.slice(-8)}`, value:p.id}
                            })}
                            onChange={props.onChange}
                            default={props.selected || payments[0].id}
                            align={"column"}
                        />
                    }
                </>
            }
            {
                showAddPaymentBT && <BottomSheet
                    onClose={()=>{
                        setShowAddPaymentBT(false);
                        props.dropDownBT && props.dropDownBT(false);
                    }}
                    fullWidth
                >
                    <AddPayment onClose={()=>{
                        loadPayments().catch();
                        setShowAddPaymentBT(false);
                        props.dropDownBT && props.dropDownBT(false);
                    }}/>
                </BottomSheet>
            }
        </>
    );
};

export default Payments;