import GoogleMapProvider from '@components/common/map/googleMap/provider';
import React from 'react';
import GoogleMap from "@components/common/map/googleMap";
import { useHistory } from 'react-router-dom';
import CustomButton from "@components/common/customButton";
import Direction from "@components/common/map/googleMap/direction";
import MapMarker from "@components/common/map/googleMap/marker";
import currentIcon from "@assets/icons/current-location.png"
import parking from "@assets/icons/green-parking.png"


const DirectionPage = () => {

    const history = useHistory();
    const query = new URLSearchParams(history.location.search);
    const start = { lat: 60.1697406252362, lng: 24.93830837138441 };
    const [targetLat, targetLng] = query.get("target")!.split(",");

    return (
        <GoogleMapProvider>
            <>
                <div className={"d-flex flex-column h-100 position-relative"}>
                    <GoogleMap
                        zoom={14}
                        center={{ lat: 60.1739807230623, lng: 24.919052810694218 }}
                        style={{ flex: 1 }}
                    />
                    <Direction
                        start={start}
                        end={{lat:Number(targetLat), lng:Number(targetLng)}}
                    />
                    <MapMarker
                        markers={[
                            {
                                id:"start",
                                position:start,
                                icon:currentIcon
                            },
                            {
                                id:"end",
                                position:{lat:Number(targetLat), lng:Number(targetLng)},
                                icon:parking
                            }
                        ]}
                        clearOnRefresh
                    />
                    {
                        query.get("type") !== "2" && <div className={"d-flex gap-2 justify-content-center p-2"}>
                            <CustomButton
                                theme={"primary"}
                                text={"book parking"}
                                onClick={()=>history.push({
                                    pathname: "/reservation-detail",
                                    state:{parkId:124578, time:2}
                                })}
                            />
                            <CustomButton
                                theme={"white"}
                                text={"close map"}
                                onClick={()=>history.goBack()}
                            />
                        </div>
                    }
                </div>
            </>
        </GoogleMapProvider>
    );
};

export default DirectionPage;