import {httpGet} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL} from "../../../config";

export interface GeoJson {
    geometry: {
        coordinates: {
            mainCoordinates: {
                lng: number
                lat: number
                _: number
            }[]
        }[]
        type: string
    },
    id: number
    type: string
    properties: {
        lisatieto: any|null
        gml_id: string
        paikat_des: number
        voimassaol: string
        kausi: any|null
        paikat_ala: number
        tyyppi: any|null
        kesto: any|null
        luokka: number
        pysayttami: any|null
        datanomist: string
        paikan_ase: string
        paivitetty: string
        asukaspysa: string
        pinta_ala: number
        luokka_nim: string
    }
}
export interface InStreetParking {
    id: string
    city: string
    createAt: string
    areaId: number
    parkingCode: number
    geoJson: GeoJson,
    isFree: boolean
    parkingLocationName: number
    parkingProviderId: string
    disable: boolean
    estimateNumberLots: number
    opening: string
    pricePerHour: number
    isHalfAnHourFree: boolean
    needsReport: boolean
    parkingType: string
    rulesAndLimitaions: string
    centerLatitude: number
    centerLongitude: number
    lots: {
        justUId: string
        isFree: boolean
        parkSpotCoordinates: []
        parkSpotDimension: number
        type: []
    }[]
}

export const getStreetParkingByCode = async (code:number|string, onError?:()=>void):Promise<InStreetParking|undefined> => {
    const spots = await httpGet<Message<InStreetParking>>(
        `${API_URL}inStreetParkings/parkingCode/${code}`,
        {},
        onError
    );
    return spots?.data;
}

export const getStreetParkingById = async (id:string):Promise<InStreetParking|undefined> => {
    const spots = await httpGet<Message<InStreetParking>>(
        `${API_URL}inStreetParkings/${id}`
    );
    return spots?.data;
}