import React, {useEffect, useState} from 'react';
import styles from './spots.module.scss';
import GoogleMap from "@components/common/map/googleMap";
import GoogleMapProvider from "@components/common/map/googleMap/provider";
import Polygon from "@components/common/map/googleMap/polygon";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import {useHistory, useParams} from "react-router-dom";
import {getFloors} from "@webservices/parking/floor";
import {Polygon as PolygonType} from "@types.d/Polygon";
import BottomSheetLayout from "@components/layouts/contentLayouts/bottomSheetLayout";
import CustomButton from "@components/common/customButton";

const ParkingSpots = () => {

    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const [floors, setFloors] = useState<{
        index:number
        name:string
    }[]>([])

    const [zones, setZones] = useState<{
        id:string
        name:string
    }[]>([])

    const [polygons, setPolygons] = useState<PolygonType[]>([]);
    const [selectedZone, setSelectedZone] = useState<string>();

    useEffect(()=>{
        (async ()=>{
            const result = await  getFloors(id);
            const polys:PolygonType[] = [];
            const fl:{ index:number, name:string }[] = [];
            const zn:{ id:string, name:string }[] = [];
            result.map((floor, index)=>{
                fl.push({index, name: floor.floorName})
            })
            setFloors(fl);
            result[0].mainZones.map(mz=>{
                mz.parkZones.map(pz=>{
                    zn.push({id:pz.parkZoneId, name:pz.parkZoneName})
                    polys.push({
                        id:pz.parkZoneId,
                        label:pz.parkZoneName,
                        coords: pz.parkZoneCoordinates,
                        strokeColor: "#FFFFFF",
                        strokeOpacity: 0.8,
                        strokeWeight: 5,
                        fillColor: "#FFFFFF",
                        fillOpacity: 0.8,
                        onClick:()=>setSelectedZone(pz.parkZoneId)
                    })
                    pz.parkSpot.map(ps=>{
                        polys.push({
                            id:"",
                            coords: ps.parkSpotCoordinates,
                            strokeColor: "#73D13D",
                            strokeOpacity: 1,
                            strokeWeight: 1,
                            fillColor: "#73D13D",
                            fillOpacity: 0.6,
                            onClick:()=>setSelectedZone(pz.parkZoneId)
                        })
                    })
                })
            })
            setZones(zn);
            setPolygons(polys);
        })()
    }, [])

    const onConfirm = () => {
        history.push({
            pathname: "/reservation-detail",
            state: {
                id,
                parkType:"private",
                zoneId:selectedZone || zones[0]?.id
            }
        })
    }

    return (
        <GoogleMapProvider>
            <BottomSheetLayout
                header={
                    <SimpleHeader
                        title={"parking spaces"}
                        backControl
                    />
                }
                minHeight={"initial"}
                paddingTop={"small"}
                white
                overlap
                jsx={
                    <div className={"d-flex flex-column w-100 h-100 position-relative"}>
                        {
                            floors[1] && <div className={"px-4 py-3 d-flex justify-content-between"}>
                                {
                                    floors.map((f, index)=>{
                                        return <p key={index}>{f.name}</p>
                                    })
                                }
                            </div>
                        }
                        <GoogleMap
                            zoom={8}
                            center={{ lat: 39.004262473276306, lng: -112.2705426192734 }}
                            style={{ flex: 1 }}
                        />
                        <Polygon
                            polygons={polygons}
                            zoomExtents
                        />
                    </div>
                }
            >
                <>
                    <p>Which zone do you want to go to?</p>
                    <div className={styles.buttons}>
                        {
                            zones.map((zone, index)=>{
                                return <div
                                    key={index}
                                    onClick={()=>setSelectedZone(zone.id)}
                                    className={`${zone.id === selectedZone && styles.active}`}
                                >
                                    <p>{zone.name}</p>
                                </div>
                            })
                        }
                    </div>
                    <div className={"w-100 d-flex justify-content-center mt-3"}>
                        <CustomButton
                            text={selectedZone ? "continue" : "skip"}
                            theme={selectedZone ? "primary" : "white"}
                            onClick={onConfirm}
                        />
                    </div>
                </>
            </BottomSheetLayout>
        </GoogleMapProvider>
    );
};

export default ParkingSpots;