import React, {FunctionComponent} from 'react';

interface SimpleLayoutProps {
    header?:JSX.Element
    children?: React.ReactNode
    noPadding?:boolean
}

const SimpleLayout:FunctionComponent<SimpleLayoutProps> = (props) => {
    return (
        <div className={"d-flex flex-column h-100 position-relative"}>
            {
                props.header && <div>{props.header}</div>
            }
            <div className={`${props.noPadding ? "p-0" : "p-4"} flex-grow-1`} style={{overflowY:"auto"}}>
                {props.children}
            </div>
        </div>
    );
};

export default SimpleLayout;