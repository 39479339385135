import React, {useEffect, useState} from 'react';
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import styles from "./favorites.module.scss";
import Icon from "@components/common/icon";
import {Heart} from "@components/common/svgIcons";
import {
    deleteFavoritesParking,
    FavoriteParking,
    getFavoritesParkingWithDetails
} from "@webservices/account/favoriteParking";
import FavoritesOnLoad from "@pages/account/favorites/favoritesOnLoad";
import noFavoriteImg from "@assets/images/no-favorite.png";
import Text from "@components/common/text";
import BottomSheet from "@components/common/bottomSheet";
import ConfirmModal from "@components/modals/confirmModal";
import {successToast} from "@toast/index";

const Favorites = () => {

    const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
    const [favorites, setFavorites] = useState<FavoriteParking[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selected, setSelected] = useState<number>();
    const [removeLoading, setRemoveLoading] = useState<boolean>(false);

    useEffect(()=>{
        loadFavorites().catch();
    }, [])

    const loadFavorites = async () => {
        setLoading(true);
        const result = await getFavoritesParkingWithDetails();
        if(result) setFavorites(result);
        setLoading(false);
    }

    const NoFavorite = ():JSX.Element => {
        return <div className={"w-100 h-100 d-flex flex-column align-items-center justify-content-center position-relative"}>
            <img src={noFavoriteImg} alt={"image"} className={"w-50"}/>
            <p className={"font-1"}><Text value={"no favorites parking available here"} class={"capitalize"}/></p>
        </div>
    }

    const onDeleteFavorite = async () => {
        if(selected === undefined) return;
        setRemoveLoading(true);
        await deleteFavoritesParking(favorites[selected].id);
        successToast(<Text value={"favorite parking removed successfully"}/>);
        setSelected(undefined);
        loadFavorites().catch();
        setRemoveLoading(false);
    }

    return (
        <SimpleLayout
            header={<SimpleHeader
                title={<Text value={"favorites parking"} class={"capitalize"}/>}
                backControl
            />}
        >
            <div className={"d-flex flex-column gap-3 h-100"}>
                {
                    loading ? <FavoritesOnLoad/> : favorites[0] ? favorites.map((f, index)=>{
                        return <div
                            className={styles.favorite}
                            key={index}
                        >
                            <div>
                                <h2>{f.parkingName}</h2>
                            </div>
                            <div
                                onClick={()=>{
                                    setSelected(index);
                                    setShowRemoveConfirm(true);
                                }}
                            >
                                <Icon size={25} color={"red"} icon={<Heart/>}/>
                            </div>
                        </div>
                    }) : <NoFavorite/>
                }
            </div>
            {
                showRemoveConfirm && <BottomSheet onClose={()=>setShowRemoveConfirm(false)}>
                    <ConfirmModal
                        title={"do you want to remove your favorite parking?"}
                        onConfirm={async () => {
                            await onDeleteFavorite();
                            setShowRemoveConfirm(false);
                        }}
                        onCancel={()=>{
                            setShowRemoveConfirm(false);
                            setSelected(undefined)
                        }}
                        inProgress={removeLoading}
                    />
                </BottomSheet>
            }
        </SimpleLayout>
    );
};

export default Favorites;