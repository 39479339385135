import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './timeProgress.module.scss';
import {arc} from "@utilities/draw/arc";
import Timer from "@components/common/timer";
import {formatSecondsToTime} from "@utilities/dateUtils";

interface TimeProgressProps {
    seconds:number
    total:number
    onExpired?:()=>void
}

const TimeProgress:FunctionComponent<TimeProgressProps> = (props) => {

    const [remain, setRemain] = useState<number>(props.seconds);
    const [angle, setAngle] = useState<number>(359);

    useEffect(()=>{
        if(remain <= 0) {
            setAngle(0);
            return;
        }
        setAngle((remain * 359) / props.total);
    },[remain])

    return (
        <div className={styles.container}>
            <div className={styles.caption}>
                <Timer
                    seconds={props.seconds}
                    onChanged={seconds=>setRemain(seconds)}
                    onExpired={props.onExpired}
                    showZeroHour
                    className={"bold font-1"}
                />
                <p>{`Total: ${formatSecondsToTime(props.total)}`}</p>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                className={styles.svg}
            >
                <circle className={styles.grey} cx="50" cy="50" r="40" fill="none" />
                <path className={styles.purple} d={arc(50,50,41.5, 38.5, 0, angle)}/>
            </svg>
        </div>
    );
};

export default TimeProgress;