import Joi from "joi";

export const sessionSchema = Joi.object({
    userId: Joi.string().required(),
    inStreetParkingId: Joi.string().required(),
    justUId: Joi.string().required(),
    reserveFrom: Joi.string().required(),
    reserveTo: Joi.string().required(),
    vehicleId: Joi.string().required(),
    plateNumber: Joi.string().required(),
})