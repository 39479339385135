import { toast } from 'react-toastify';

export const successToast = (text:string|JSX.Element) => {
    toast.success(() => <div className={"app-toast"}>{text}</div>);
}

export const failToast =  (text:string|JSX.Element) => {
    toast.error(() => <div className={"app-toast"}>{text}</div>, {
        style:{background:"#FFEBEB"}
    });
}