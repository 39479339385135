import React, {useEffect, useState} from 'react';
import styles from "./account.module.scss";
import Accordion from "@components/common/accordion";
import Divider from "@components/common/divider";
import tree from "@assets/images/tree.png";
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import Text from "@components/common/text";
import Icon from "@components/common/icon";
import {
    Car,
    Account as AccountIcon,
    Logout,
    Information,
    Setting,
    Card,
    HeartOutline, Peer
} from "@components/common/svgIcons";
import {useHistory} from "react-router-dom";
import {getUserInfo, User} from "@webservices/account/user";
import noImage from "@assets/images/noAvatar.svg";
import {signOut} from "../../services/app/userServices";
import BottomSheet from "@components/common/bottomSheet";
import ConfirmModal from "@components/modals/confirmModal";

const Account = () => {
    const history = useHistory();

    const [userInfo, setUserInfo] = useState<User>();
    const [showLogoutConfirm, setShowLogoutConfirm] = useState<boolean>(false);
    const [signOutLoading, setSignOutLoading] = useState<boolean>(false);

    useEffect(()=>{
        (async () => {
            const info = await getUserInfo();
            if(info){
                setUserInfo(info);
            }
        })()
    }, [])

    return (
        <SimpleLayout>
            <Accordion
                height={"125px"}
                borderRadius={15}
                showArrow
            >
                <div className={styles.statics}>
                    <div className={"d-flex"}>
                        <div className={styles.staticsBox}>
                            <img
                                src={userInfo?.photo || noImage}
                                className={"mb-2"}
                                alt={"avatar"}
                            />
                            {
                                (!userInfo?.firstName && !userInfo?.lastName) ?
                                    <p className={"text-capitalize"}><Text value={"no name"}/></p> :
                                    <p>{`${userInfo?.firstName || ""} ${userInfo?.lastName || ""}`}</p>
                            }
                            <span>{userInfo?.userPhoneNumber || "+000000"}</span>
                        </div>
                        <div className={styles.staticsBox}>
                            <img
                                src={tree}
                                alt={"tree"}
                                className={"mb-2"}
                            />
                            <p>10</p>
                            <span><Text value={"trees you planted"} class={"capitalize"}/></span>
                        </div>
                    </div>
                    <Divider/>
                    <div className={"d-flex gap-3 justify-content-center mb-3"}>
                        <div className={styles.staticsDetail}>
                            <h2>25%</h2>
                            <p><Text value={"saved carbon"} class={"capitalize"}/></p>
                        </div>
                        <div className={styles.staticsDetail}>
                            <h2>23$</h2>
                            <p><Text value={"saved cost"}  class={"capitalize"}/></p>
                        </div>
                        <div className={styles.staticsDetail}>
                            <h2>20</h2>
                            <p><Text value={"shared parking"}  class={"capitalize"}/></p>
                        </div>
                    </div>
                    <div className={"d-flex gap-3 justify-content-center"}>
                        <div className={styles.staticsDetail}>
                            <h2>10</h2>
                            <p><Text value={"introduced parking"} class={"capitalize"}/></p>
                        </div>
                        <div className={styles.staticsDetail}>
                            <h2>3</h2>
                            <p><Text value={"accurate reports"} class={"capitalize"}/></p>
                        </div>
                        <div className={styles.staticsDetail}>
                            <h2>5</h2>
                            <p><Text value={"successful referral"} class={"capitalize"}/></p>
                        </div>
                    </div>
                </div>
            </Accordion>
            <div className={"d-flex flex-wrap gap-3"} style={{marginTop:50}}>
                <div
                    className={styles.service}
                    onClick={()=>history.push("/account/manage-vehicles")}
                >
                    <Icon size={22} color={"primary"} icon={<Car/>}/>
                    <p><Text value={"manage vehicles"} class={"capitalize"}/></p>
                </div>
                <div
                    className={styles.service}
                    onClick={()=>{}}
                >
                    <Icon size={22} color={"primary"} icon={<Peer/>}/>
                    <p><Text value={"peer profile"} class={"capitalize"}/></p>
                </div>
                <div
                    className={styles.service}
                    onClick={()=>history.push("/account/personal-info")}
                >
                    <Icon size={22} color={"primary"} icon={<AccountIcon/>}/>
                    <p><Text value={"personal info"} class={"capitalize"}/></p>
                </div>
                <div
                    className={styles.service}
                    onClick={()=>history.push("/account/favorites")}
                >
                    <Icon size={22} color={"primary"} icon={<HeartOutline/>}/>
                    <p><Text value={"favorites parking"} class={"capitalize"}/></p>
                </div>
                <div
                    className={styles.service}
                    onClick={()=>history.push("/account/manage-payments")}
                >
                    <Icon size={22} color={"primary"} icon={<Card/>}/>
                    <p><Text value={"payment methods"} class={"capitalize"}/></p>
                </div>
                <div
                    className={styles.service}
                    onClick={()=>history.push("/account/settings")}
                >
                    <Icon size={22} color={"primary"} icon={<Setting/>}/>
                    <p><Text value={"settings"} class={"capitalize"}/></p>
                </div>
                <div
                    className={styles.service}
                    onClick={()=>history.push("/account/help")}
                >
                    <Icon size={22} color={"primary"} icon={<Information/>}/>
                    <p><Text value={"help"} class={"capitalize"}/></p>
                </div>
                <div
                    className={styles.service}
                    onClick={()=>{setShowLogoutConfirm(true)}}
                >
                    <Icon size={22} color={"primary"} icon={<Logout/>}/>
                    <p><Text value={"logout"} class={"capitalize"}/></p>
                </div>
            </div>
            {
                showLogoutConfirm && <BottomSheet onClose={()=>setShowLogoutConfirm(false)}>
                    <ConfirmModal
                        title={"Are you sure you want to log out of your account?"}
                        confirmText={"Yes, logout"}
                        onConfirm={async () => {
                            setSignOutLoading(true);
                            await signOut();
                            setSignOutLoading(false);
                            setShowLogoutConfirm(false);
                        }}
                        onCancel={()=>{
                            setShowLogoutConfirm(false);
                        }}
                        inProgress={signOutLoading}
                    />
                </BottomSheet>
            }
        </SimpleLayout>
    );
};

export default Account;