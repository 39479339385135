import React, {FunctionComponent, ReactElement} from 'react';
import Icon from "../icon";
import {Copy} from "../svgIcons";
import {copyToClipboard} from "@utilities/copyToClipboard";

interface CopyClipboardProps {
    text: string;
    showText?: string|ReactElement
    showIcon?: boolean
    className?: string
}

const CopyClipboard:FunctionComponent<CopyClipboardProps> = (props) => {
    return (
        <div
            className={`d-flex align-items-center gap-2 ${props.className}`}
            onClick={(e)=>{
                e.stopPropagation();
                copyToClipboard(props.text)
            }}
            style={{cursor: 'pointer'}}
        >
            {props.showIcon && <Icon size={18} color={"black"} icon={<Copy/>}/>}
            <p>{props.showText || props.text}</p>
        </div>
    );
};

export default CopyClipboard;