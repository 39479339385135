import React, {useState} from 'react';
import SimpleHeader from "@components/layouts/layoutElements/simpleHeader";
import SimpleLayout from "@components/layouts/contentLayouts/simpleLayout";
import Text from "@components/common/text";
import TextArea from "@components/common/form/textArea";
import Upload from "@components/common/form/upload";
import Icon from "@components/common/icon";
import {HappyFace, Loading, PokerFace, SadFace} from "@components/common/svgIcons";
import CustomButton from "@components/common/customButton";
import HorizontalSlider from "@components/basic/horizontalSlider";
import {createFeedBack} from "@webservices/account/feedback";
import {successToast} from "@toast/index";

const FeedBack = () => {

    const [feedback, setFeedBack] = useState<{[k:string]:any}>({
        photos:[]
    });
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (id?:string, value?:any) => {
        if(!id) return;
        setFeedBack(prevState => ({...prevState, [id]:value}))
    }

    const handleUpload = (id?:string, value?:string) => {
        if(!value) return;
        setFeedBack(prevState => {
            const photos = [...prevState.photos];
            photos.push(value);
            return {...prevState, photos};
        })
    }

    const handleSubmit = async () => {
        const userId= localStorage.getItem("sub");
        if(!userId) return;
        setLoading(true);
        await createFeedBack({
            description: feedback.description,
            photos: feedback.photos,
            userExperience: feedback.userExperience,
            userId
        });
        successToast(<Text value={"feedback has been sent successfully"} class={"capitalize"}/>)
        setLoading(false);
    }

    return (
        <div>
            <SimpleLayout
                header={<SimpleHeader
                    title={"feedback"}
                    backControl
                />}
            >
                <h1 className={"mb-3"}><Text value={"send us your feedback"} class={"capitalize"}/></h1>
                <p className={"mb-3"}>
                    <Text value={"feed-back-txt"} class={"capitalize"}/>
                </p>
                <div className={"d-flex justify-content-center gap-4 my-3"}>
                    <div onClick={()=>handleChange("userExperience", "happy")}>
                        <Icon size={48} color={feedback.userExperience === "happy" ? "primary" : "black"} icon={<HappyFace/>}/>
                    </div>
                    <div onClick={()=>handleChange("userExperience", "neutral")}>
                        <Icon size={48} color={feedback.userExperience === "neutral" ? "yellow" : "black"} icon={<PokerFace/>}/>
                    </div>
                    <div onClick={()=>handleChange("userExperience", "sad")}>
                        <Icon size={48} color={feedback.userExperience === "sad" ? "red" : "black"} icon={<SadFace/>}/>
                    </div>
                </div>
                <TextArea
                    id={"description"}
                    rows={10}
                    placeHolder={"please describe your experience"}
                    onChange={handleChange}
                    className={"mb-3"}
                />
                <p className={"mb-3"}><Text value={"screenshots can be added here if necessary"}/></p>
                <div className={"d-flex align-items-end gap-3"}>
                    <Upload
                        onChange={handleUpload}
                    />
                    <HorizontalSlider
                        slides={
                            feedback.photos.map((p: string, index: number) => {
                                return <img
                                    style={{height:70, width:70, objectFit:"cover"}}
                                    key={index}
                                    src={p}
                                    alt={""}
                                />
                            })
                        }
                        slideWidth={70}
                        slideHeight={70}
                        style={{height:74}}
                    />
                </div>
                <div className={"d-flex justify-content-center mt-5"}>
                    <CustomButton
                        text={<Text value={"submit"}/>}
                        theme={"primary"}
                        onClick={handleSubmit}
                        loading={loading}
                        loadingIcon={<Icon size={20} color={"white"} icon={<Loading/>}/>}
                    />
                </div>
            </SimpleLayout>
        </div>
    );
};

export default FeedBack;