import React, {FunctionComponent} from 'react';
import styles from './Icon.module.scss';
import cmsColors from "@types.d/CmsColors";

export interface IconProps {
    size:number;
    color:cmsColors | "none";
    icon:JSX.Element;
    className?: string;
}

const Icon:FunctionComponent<IconProps> = ({size,color, icon, className}) => {

    return (
        <div
            className={`d-flex justify-content-center align-items-center cms-fill-${color} ${styles.container} ${className}`}
            style={{width:size, height:size}}
        >
            {icon}
        </div>
    );
};

export default Icon;