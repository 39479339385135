import CustomButton from '@components/common/customButton';
import Divider from '@components/common/divider';
import Icon from '@components/common/icon';
import {Calender, Euro, Loading} from '@components/common/svgIcons';
import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./history.module.scss";
import {sessionHistory, SessionHistory} from "@webservices/session";
import moment from "moment";
import noHistory from "@assets/images/no-history.png";
import Text from "@components/common/text";

interface HistoryProps {

}

const History: FunctionComponent<HistoryProps> = () => {

    const [histories, setHistories] = useState<SessionHistory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(()=>{
        (async () => {
            const results = await sessionHistory(()=>{
                setLoading(false);
            });
            if(results) setHistories(results);
        })()
    }, [])

    return (
        <>
        {
            !histories[0] ? <div className={"w-100 h-100 d-flex flex-column justify-content-center gap-4 align-items-center"}>
                <img src={noHistory} alt={"no-reserved"}/>
                {
                    loading ? <Icon size={20} color={"primary"} icon={<Loading/>} />
                        : <p><Text value={"no parking reservation has been made yet."} class={"capitalize"}/></p>
                }
            </div>: <div className={"d-flex flex-column gap-4"}>
                {
                    histories.map((history, index)=>{
                        return <div className={styles.card} key={index}>
                            <h2 className={"mb-3"}>{history.parkingName}</h2>
                            <div className={styles.row}>
                                <Icon size={20} color={'primary'} icon={<Calender />} />
                                <p><span>date: </span>{moment(history.sessionAddTimeStamp).utc().format("YYYY-MM-DD HH:mm:ss")}</p>
                            </div>
                            <div className={styles.row}>
                                <Icon size={20} color={'primary'} icon={<Euro />} />
                                <p><span>price: </span>{`€${history.parkingPricePerHour} per hours`}</p>
                            </div>
                            <Divider />
                            <div className={"w-100 d-flex justify-content-center"}>
                                <CustomButton
                                    text={"details"}
                                    theme={"white"}
                                    onClick={()=>{}}
                                />
                            </div>
                        </div>
                    })
                }
            </div>
        }
        </>
    );
};

export default History;
