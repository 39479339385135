import {getUserInfoByUid, registerUser, User} from "../rest/account/user";
import {getAuth} from "firebase/auth";

interface Credential {
    uid:string
    token:string
    refreshToken?:string
    phoneNumber?:string
    email?:string
}

export const onUserLoggedIn = async(credential:Credential) => {
    let user:User|{id:string}|undefined;
    user = await getUserInfoByUid(
        credential.uid,
        async ()=>{
            user = await registerUser(credential.uid, credential.email, credential.phoneNumber);
            if(user){
                setUser(credential, user.id);
            }
        }
    );
    if(user) {
        setUser(credential, user.id);
    }
}

const setUser = (credential:Credential, id:string) => {
    localStorage.setItem("token", credential.token);
    credential.refreshToken && localStorage.setItem("rt", credential.refreshToken);
    localStorage.setItem("uid", credential.uid);
    localStorage.setItem("sub", id);
    window.location.replace("/");
}

export const signOut = async () => {
    const auth = getAuth();
    localStorage.removeItem("token");
    localStorage.removeItem("rt");
    localStorage.removeItem("uid");
    localStorage.removeItem("sub");
    await auth.signOut();
    window.location.replace("/");
}