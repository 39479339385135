import React from 'react';
import CustomButton from "@components/common/customButton";
import {useHistory} from "react-router-dom";

const Peer = () => {

    const history = useHistory();

    return (
        <div className={"p-4 h-100 d-flex flex-column justify-content-center align-items-center"}>
            <p className={"mb-3 text-center"}>This feature is coming soon</p>
            <CustomButton
                text={"Back to Home"}
                theme={"primary"}
                onClick={()=>history.push("/")}
            />
        </div>
    );
};

export default Peer;