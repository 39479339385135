import { Html5Qrcode } from 'html5-qrcode';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Styles from './qrScanner.module.scss';

interface Html5QrcodePluginProps {
    onScan:(value:string)=>void
    onError:(err:string)=>void
}

const Html5QrcodePlugin: FunctionComponent<Html5QrcodePluginProps> = (props) => {

    const html5QrCode = useRef<Html5Qrcode | null>(null);

    const [loading, setLoading] = useState<boolean>(true);

    const addQrScanner = async () => {
        const cameras = await Html5Qrcode.getCameras();
        if (!cameras[0]) return;
        html5QrCode.current && html5QrCode.current.start(
            { facingMode: "environment" },
            {
                fps: 10,
                qrbox: { width: 250, height: 250 },
                aspectRatio: 1
            },
            (decodedText) => {
                props.onScan(decodedText);
            },
            () => { }
        ).then(()=>{setLoading(false)})
            .catch(() => { });
    }

    useEffect(() => {
        if(!html5QrCode.current){
            html5QrCode.current = new Html5Qrcode("reader");
            addQrScanner().catch(e=>{
                props.onError(e.message);
            });
        }
        return () => {
            if(html5QrCode.current && html5QrCode.current?.getState() === 2 ) {
                html5QrCode.current?.stop()
            }
        }
    }, []);

    return (
        <div>
            <p className='text-center mb-2'>place the QRCode inside a frame</p>
            {
                loading && <div className={Styles.qrWrapper}></div>
            }
            <div className='' id={"reader"} />
        </div>

    );
};

export default Html5QrcodePlugin;