import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import styles from "./navigationLayout.module.scss"
import Icon from "@components/common/icon";
import {
    Account,
    AccountSolid,
    Home,
    HomeSolid,
    Peer,
    PeerSolid,
    Session,
    SessionSolid
} from "@components/common/svgIcons";
import Text from "@components/common/text";
import {useHistory} from "react-router-dom";

interface NavigationLayoutProps {
    children?: React.ReactNode
}

const NavigationLayout:FunctionComponent<NavigationLayoutProps> = (props) => {

    const history = useHistory();

    const navbar = [
        {
            label: <Text value={"home"} class={"capitalize"}/>,
            mainIcon: <Home/>,
            hoverIcon: <HomeSolid/>,
            path:"/"
        },
        {
            label: <Text value={"peer"} class={"capitalize"}/>,
            mainIcon: <Peer/>,
            hoverIcon: <PeerSolid/>,
            path:"/peer"
        },
        {
            label: <Text value={"session"} class={"capitalize"}/>,
            mainIcon: <Session/>,
            hoverIcon: <SessionSolid/>,
            path:"/session"
        },
        {
            label: <Text value={"account"} class={"capitalize"}/>,
            mainIcon: <Account/>,
            hoverIcon: <AccountSolid/>,
            path:"/account"
        }
    ]

    const [path, setPath] = useState<string>();

    useEffect(()=>{
        history.listen(location => setPath(location.pathname))
    }, [])

    const Navbar = useMemo(()=>{
        return navbar.map((nav, index)=>{
            return <div
                className={`${styles.button} ${history.location.pathname === nav.path && styles.active}`}
                onClick={()=>{history.push(nav.path)}}
                key={index}
            >
                {
                    history.location.pathname === nav.path ?
                        <Icon size={20} color={"primary"} icon={nav.hoverIcon}/> :
                        <Icon size={20} color={"dark-gray"} icon={nav.mainIcon}/>
                }
                <p className={"mt-2"}>{nav.label}</p>
            </div>
        })
    }, [path])

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {props.children}
            </div>
            <div className={styles.nav}>
                {Navbar}
            </div>
        </div>
    );
};

export default NavigationLayout;