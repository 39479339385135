import React, {useContext, useEffect, useState} from 'react';
import Text from "@components/common/text";
import CustomButton from "@components/common/customButton";
import styles from"./language.module.scss";
import {LanguageContext} from "../../contexts/languageContext";
import {useHistory} from "react-router-dom";
import {languagesList} from "../../constant/languages";

const LanguageSelection = () => {

    const { onLanguageChange } = useContext(LanguageContext);
    const history = useHistory();

    const [selected, setSelected] = useState<number>(0);

    useEffect(()=>{
        const lang = localStorage.getItem("lang");
        if(lang){
            const index = languagesList.findIndex(l=>l.value === lang);
            if(index !== -1) setSelected(index);
        }
    }, [])

    return (
        <div className={"p-5 h-100 d-flex flex-column justify-content-between align-items-center"}>
            <div className={"w-100"}>
                <p className={"mb-3"}><Text value={"choose language"} class={"capitalize"}/></p>
                <div className={"d-flex flex-column gap-3"}>
                    {
                        languagesList.map((lng, index)=>{
                            return <div
                                className={`${styles.languageCard} ${index === selected && styles.active}`}
                                key={index}
                                onClick={()=>{
                                    setSelected(index);
                                    onLanguageChange(lng.value);
                                }}
                            >
                                <p><Text value={lng.title} class={"capitalize"}/></p>
                                <img src={lng.img} alt={lng.title}/>
                            </div>
                        })
                    }
                </div>
            </div>
            <CustomButton
                theme={"primary"}
                text={<Text value={"select"} class={"capitalize"}/>}
                className={"px-4"}
                onClick={()=>{history.push("/on-board")}}
            />
        </div>

    );
};

export default LanguageSelection;