import locIcon from "@assets/icons/location.svg";

export const goToCurrentLocation = (map:google.maps.Map, googleObject:typeof google) => {
    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        new googleObject.maps.Marker({
            map: map,
            position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            },
            icon:{
                url: locIcon,
                scaledSize: new google.maps.Size(30, 30)
            }
        });
        map.setCenter(pos)
    }, (err)=>{
        switch (err.code){
            case 1:
                navigator.permissions.query({ name: "geolocation" })
                    .then((result) => {console.log(result)})
                break;
        }
    })
}

export const getLocationPermissionStatus = async ():Promise<PermissionStatus> => {
    return await navigator.permissions.query({ name: "geolocation" })
}