import React, {FunctionComponent, useRef} from 'react';
import Radio from "@components/common/form/radio";
import Divider from "@components/common/divider";
import CheckBox from "@components/common/form/checkBox";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";

interface ParkingFilterProps {
    onApply:(value:string[])=>void
    onCancel:()=>void
}
const ParkingFilter:FunctionComponent<ParkingFilterProps> = (props) => {

    const filterValues = {
        parkingType: [
            {title:"indoor", value:1},
            {title:"outdoor", value:2}
        ],
        parkingFacilities:[
            {title:"private", value:1},
            {title:"public", value:2},
            {title:"mall", value:3},
            {title:"reservable", value:4},
            {title:"free", value:5},
            {title:"paid", value:6},
            {title:"green parking", value:7},
            {title:"high rate", value:5},
        ],
        evStation:[
            {title:"all", value:1},
            {title:"level 1", value:2},
            {title:"level 2", value:3},
            {title:"level 3", value:4},
        ],
        promotions:[
            {title:"promotions 1", value:1},
            {title:"promotions 2", value:2}
        ],
        nearTransportations:[
            {title:"bus", value:1},
            {title:"taxi", value:2},
            {title:"subway", value:3},
            {title:"train", value:4}
        ]
    };

    const activeFilters = useRef<{
        parkingType?:number
        parkingFacilities?:number[]
        evStation?:number
        promotions?:number
        nearTransportations?:number
    }>({})

    const handleChange = (id?:string, value?:any) => {
        if(!id) return;
        activeFilters.current =  {...activeFilters.current, [id]:value}
    }

    const apply = () => {

    }

    return (
        <div>
            <Radio
                id={"parkingType"}
                label={"parking type"}
                options={filterValues.parkingType}
                onChange={handleChange}
            />
            <Divider/>
            <CheckBox
                id={"parkingFacilities"}
                label={"parking facilities"}
                options={filterValues.parkingFacilities}
                onChange={handleChange}
            />
            <Divider/>
            <Radio
                id={"evStation"}
                label={"ev station"}
                options={filterValues.evStation}
                onChange={handleChange}
            />
            <Divider/>
            <Radio
                id={"promotions"}
                label={"promotions"}
                options={filterValues.promotions}
                onChange={handleChange}
            />
            <Divider/>
            <CheckBox
                id={"nearTransportations"}
                label={"near transportations"}
                options={filterValues.nearTransportations}
                onChange={handleChange}
            />
            <div className={"d-flex gap-2 justify-content-center mt-4"}>
                <CustomButton
                    theme={"primary"}
                    text={<Text value={"apply"}/>}
                    onClick={apply}
                />
                <CustomButton
                    theme={"primary-outline"}
                    text={<Text value={"cancel"}/>}
                    onClick={()=>props.onCancel()}
                />
            </div>
        </div>
    );
};

export default ParkingFilter;