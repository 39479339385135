import React, {FunctionComponent, useRef} from 'react';
import ReactDOM from "react-dom";
import styles from "./modals.module.scss";
import useOutsideClick from "@hooks/useOutsideClick";

interface ModalProps {
    children?: React.ReactNode
    align?:"top"|"bottom"|"navBottom"|"center"
    onClose:()=>void
    closeOnOutSideClick?:boolean
}

const Modal:FunctionComponent<ModalProps> = (props) => {

    const modalRef = useRef<HTMLDivElement|null>(null);

    if(props.closeOnOutSideClick) useOutsideClick(modalRef, props.onClose);

    return ReactDOM.createPortal(
        <div className={`${styles.container} ${props.align && styles[props.align]}`}>
            <div className={styles.contents} ref={modalRef}>
                {props.children}
            </div>
        </div>,
        document.getElementById('portals')!
    );
};

export default Modal;