import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./inParking.module.scss";
import Input from "@components/common/form/input";
import CustomButton from "@components/common/customButton";
import Icon from "@components/common/icon";
import {Clock, QrCode} from "@components/common/svgIcons";
import Text from "@components/common/text";
import TimeBlock from "@components/common/timeBlock";
import BottomSheet from "@components/common/bottomSheet";
import Divider from "@components/common/divider";
import ScanQR from '../scanQR';
import IconHeader from "@components/common/iconHeader";
import moment from "moment";
import {useHistory} from "react-router-dom";
import Joi from "joi";
import {validate} from "@utilities/validator";
import {failToast} from "@toast/index";
import Modal from "@components/common/modal";
import Alert from "@components/modals/alert";

interface InParkingProps {
    dropDownBT?:(value:boolean)=>void
    code?:number|string
}

const InParking:FunctionComponent<InParkingProps> = (props) => {

    const history = useHistory();
    const [showScanQrBT, setShowScanQrBT] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [data, setData] = useState<{
        id?:string
        end?:string
        vehicleId?:string
        paymentId?:string
    }>({});

    useEffect(()=>{
        props.code !== undefined && setData(prevState => ({
            ...prevState, id:String(props.code)
        }))
    }, [props.code])

    const handleChange = (id?:string, value?:any) => {
        if(!id || !value ) return;
        setData(prevState => ({...prevState, [id]:value}));
    }

    const book = () => {
        const obj = {
            id:data.id,
            start:moment().format("YYYY-MM-DD HH:mm"),
            end:moment().add(Number(data.end), "minutes").format("YYYY-MM-DD HH:mm")
        }
        if(!validate(obj, Joi.object({
            id:Joi.string().required(),
            start:Joi.string().required(),
            end:Joi.string().required()
        }))) {
            failToast(<Text value={"all fields must be filled."} class={"capitalize"}/> )
            return;
        }
        history.push({
            pathname: `/street-spots/${data.id}`,
            state:obj
        })
    }

    return (
        <>
            <div className={"d-flex flex-column"}>
                <div className={styles.contents}>
                    <div className={"w-100 d-flex"}>
                        <Input
                            id={"id"}
                            onChange={handleChange}
                            placeHolder={"Enter code or location ID"}
                            className={"flex-grow-1"}
                            default={data.id}
                        />
                        <span className={"mx-1"}/>
                        <CustomButton
                            theme={"no-border"}
                            icon={<Icon size={24} color={"primary"} icon={<QrCode/>}/>}
                            cornerStyle={"Large"}
                            className={"p-0"}
                            onClick={()=>{
                                setShowScanQrBT(true);
                                props.dropDownBT && props.dropDownBT(true);
                            }}
                        />
                    </div>
                    <Divider/>
                    <div>
                        <IconHeader
                            icon={<Clock/>}
                            text={"how much time do you want to stay?"}
                            className={"mb-2"}
                        />
                        <TimeBlock
                            blocks={[
                                {title:"30m" , value:30},
                                {title:"1h" , value:60},
                                {title:"2hrs" , value:120},
                                {title:"3hrs" , value:180},
                                {title:"4hrs" , value:240},
                                {title:"5hrs" , value:300},
                                {title:"6hrs" , value:360},
                                {title:"7hrs" , value:420},
                                {title:"8hrs" , value:480},
                                {title:"9hrs" , value:540},
                            ]}
                            onSelect={(value)=>{handleChange("end", String(value))}}
                            startTime={"now"}
                        />
                    </div>
                </div>
                <div className={"d-flex justify-content-center mt-4"}>
                    <CustomButton
                        theme={"primary"}
                        text={<Text value={"next"} class={"capitalize"}/>}
                        onClick={book}
                        className={"px-5"}
                    />
                </div>
            </div>
            {
                showScanQrBT && <BottomSheet
                    onClose={()=>{
                        setShowScanQrBT(false);
                        props.dropDownBT && props.dropDownBT(false);
                    }}
                >
                    <ScanQR
                        onConfirm={(txt)=>{
                            setData(prevState => ({...prevState, id:txt}))
                            setShowScanQrBT(false);
                            props.dropDownBT && props.dropDownBT(false);
                        }}
                        onCancel={()=>{
                            setShowScanQrBT(false);
                            setShowPermissionAlert(true);
                        }}
                    />
                </BottomSheet>
            }
            {
                showPermissionAlert && <Modal
                    onClose={()=>setShowPermissionAlert(false)}
                    align={"center"}
                >
                    <Alert
                        title={"camera permission is denied. please turn on it and try again."}
                        onConfirm={()=>setShowPermissionAlert(false)}
                    />
                </Modal>
            }
        </>
    );
};

export default InParking;