import React, {FunctionComponent, useContext, useEffect, useRef} from 'react';
import {MapContext} from "@components/common/map/googleMap/provider/mapContext";
import {Polygon as PolygonType} from "@types.d/Polygon";

interface PolygonProps {
    polygons?: PolygonType[]
    clearAll?:boolean
    clearOnUnLoad?:boolean
    zoomExtents?:boolean
    onSelectOptions?:google.maps.PolygonOptions|null
    selected?:string|number
}

const Polygon:FunctionComponent<PolygonProps> = (props) => {

    const { map, googleObject } = useContext(MapContext);
    const polygons = useRef<{id:number|string, polygon:google.maps.Polygon}[]>([]);

    useEffect(()=>{
        drawPolygon();
        return () => {
            if(props.clearOnUnLoad) clearAll();
        }
    }, [map, props.polygons])

    useEffect(() => {
        if(!props.onSelectOptions || props.selected === undefined) return;
        polygons.current.map(poly=>{
            if(props.selected === poly.id) {
                poly.polygon.setOptions(props.onSelectOptions!);
            } else {
                const p = props.polygons?.find(p=>p.id === poly.id);
                if(p){
                    poly.polygon.setOptions({
                        strokeColor: p.strokeColor,
                        strokeOpacity: p.strokeOpacity,
                        strokeWeight: p.strokeWeight,
                        fillColor: p.fillColor,
                        fillOpacity: p.fillOpacity,
                    })
                }
            }
        })
    }, [props.selected])

    const clearAll = () => {
        polygons.current.map(p=>p.polygon.setMap(null));
    }

    const drawPolygon = () => {
        if (!map || !props.polygons || !googleObject) return;
        const bounds = new googleObject.maps.LatLngBounds();
        props.polygons.map(polygon => {
            const poly = new googleObject.maps.Polygon({
                map,
                paths: polygon.coords,
                strokeColor: polygon.strokeColor,
                strokeOpacity: polygon.strokeOpacity,
                strokeWeight: polygon.strokeWeight,
                fillColor: polygon.fillColor,
                fillOpacity: polygon.fillOpacity,
            });
            if(polygon.label) {
                const polyBound = new google.maps.LatLngBounds();
                polygon.coords.map(c => polyBound.extend(c));
                addLabel(polyBound.getCenter(), polygon.label, polygon.angle);
            }
            if(props.zoomExtents) {
                polygon.coords.map(point=>{
                    bounds.extend( new googleObject.maps.LatLng(point));
                })
            }
            polygons.current.push({id:polygon.id, polygon:poly});
            polygon.onClick && poly.addListener("click", () => {
                if(props.onSelectOptions){
                    polygons.current.map(poly=>{
                        const p = props.polygons?.find(p=>p.id === polygon.id);
                        if(p){
                            poly.polygon.setOptions({
                                strokeColor: p.strokeColor,
                                strokeOpacity: p.strokeOpacity,
                                strokeWeight: p.strokeWeight,
                                fillColor: p.fillColor,
                                fillOpacity: p.fillOpacity,
                            })
                        }
                    })
                    poly.setOptions(props.onSelectOptions)
                }
                polygon.onClick!(polygon.id);
            });
        });
        if(props.zoomExtents) {
            map.fitBounds(bounds)
        }
    }

    const addLabel = (position:null|google.maps.LatLng|google.maps.LatLngLiteral, label:string, angle?:number) => {
        if (!map || !props.polygons || !googleObject) return;
        const tag = document.createElement('div');
        tag.innerHTML = `<p class="asdasdasdad" style="white-space:nowrap;position: absolute; transform: translate(-50%, -50%) rotate(${angle||0}deg)" >
            ${label}
            </p>`;
        new google.maps.marker.AdvancedMarkerElement({
            position: position,
            content:tag,
            map: map
        });
    }

    return (<></>);
};

export default Polygon;