import React, {FunctionComponent, useContext, useEffect} from 'react';
import {MapContext} from "@components/common/map/googleMap/provider/mapContext";
import {failToast} from "@toast/index";

interface DirectionProps {
    start:{lat:number, lng:number}
    end:{lat:number, lng:number}
}

const Direction:FunctionComponent<DirectionProps> = (props) => {

    const { map } = useContext(MapContext);

    useEffect(()=>{
        if(map === null) return;
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
            draggable: true,
            suppressMarkers: true,
            map
        });
        directionsRenderer.addListener("directions_changed", () => {
            const directions = directionsRenderer.getDirections();

            if (directions) {
                computeTotalDistance(directions);
            }
        });
        displayRoute(
            new google.maps.LatLng(props.start),
            new google.maps.LatLng(props.end),
            directionsService,
            directionsRenderer
        );
    }, [map])

    const displayRoute = (
        origin: google.maps.LatLng,
        destination: google.maps.LatLng,
        service: google.maps.DirectionsService,
        display: google.maps.DirectionsRenderer
    ) => {
        service
            .route({
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
                avoidTolls: true,
            })
            .then((result: google.maps.DirectionsResult) => {
                display.setDirections(result);
            })
            .catch((e) => {
                console.log(e)
                failToast("Could not display directions due to: " + e);
            });
    }

    const computeTotalDistance = (result: google.maps.DirectionsResult) => {
        let total = 0;
        const myRoute = result.routes[0];

        if (!myRoute) {
            return;
        }

        for (let i = 0; i < myRoute.legs.length; i++) {
            total += myRoute.legs[i]!.distance!.value;
        }

        total = total / 1000;
        console.log(total + " km");
    }

    return (<></>);
};

export default Direction;