import React, {FunctionComponent} from 'react';
import Text from "@components/common/text";
import styles from "./timeSpan.module.scss";
import moment from "moment/moment";

interface TimeSpanProps {
    start:string
    end:string
    parkingName?:string
}

const TimeSpan:FunctionComponent<TimeSpanProps> = (props) => {
    return (
        <div className={styles.container}>
            {props.parkingName && <h3 className={"text-center mb-4"}><span className={"bold opacity-50"}><Text value={"parking"}/></span>: {props.parkingName}</h3>}
            <div className={"d-flex w-100 justify-content-between align-items-center"}>
                <div className={"d-flex flex-column align-items-center"}>
                    <p className={"opacity-50"}><Text value={"Arrive at"}/></p>
                    <p className={"bold mt-3"}>
                        <Text
                            value={moment(props.start).format("dddd HH:mm")}
                        />
                    </p>
                </div>
                <span className={styles.hours} style={{fontSize:"0.8rem"}}>{moment(props.end).diff(props.start, "hours")}Hrs</span>
                <div className={"d-flex flex-column align-items-center"}>
                    <p className={"opacity-50"}><Text value={"leave at"}/>
                    </p>
                    <p className={"bold mt-3"}>
                        <Text
                            value={moment(props.end).format("dddd HH:mm")}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TimeSpan;